import './SearchRecords.scss';
import React, { useContext, useEffect } from 'react';
import {
  Button,
  Link,
  ColumnLayout,
  Form,
  FormField,
  Header,
  Input,
  Autosuggest,
  SpaceBetween,
  Pagination,
} from '@amzn/awsui-components-react';
import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';
import { useHistory } from 'react-router-dom';
import ServiceCollection from 'src/services/ServiceCollection';
import Table from '@amzn/awsui-components-react/polaris/table';
import Box from '@amzn/awsui-components-react/polaris/box';
import Select from '@amzn/awsui-components-react/polaris/select';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Constants from 'src/utils/constants';
import { formatDate } from 'src/utils/DateUtils';
import { getCompanyCodesFromALE } from 'src/utils/ALEUtils';
import { GlobalAppContext } from 'src/components/App';
import { createApprovalLinkForStage } from 'src/utils/commonComponentUtils';
import { useDownloadRegistrationRecords } from './hooks/useDownloadRegistrationRecords';
import CONSTANTS from 'src/utils/constants';
import { getUserRole } from './utils';
import FileUploadModal from 'src/shared/FileUploadModal';
import {
  createLabelValueList,
  generateBulkUploadIdentifier,
  generateS3Key,
} from '../create-registration-records/utils';

interface SelectedOption {
  label: string;
  value: string;
}

export interface SearchRecordsProps {
  services: ServiceCollection;
  userAlias: string;
  isAccountingUser: boolean;
  isDirectTaxUser: boolean;
  isIndirectTaxUser: boolean;
  isSuperUser: boolean;
}

export default function SearchRecords(props: SearchRecordsProps) {
  const { services, userAlias, isAccountingUser, isSuperUser, isDirectTaxUser, isIndirectTaxUser } = props;
  const s3KeyToRegisterRecord = generateS3Key(userAlias);
  const bulkUploadIdentifier = generateBulkUploadIdentifier(userAlias);
  const [registrationNumber, setRegistrationNumber] = React.useState('');
  const [providerCompanyCode, setProviderCompanyCode] = React.useState('');
  const [recipientCompanyCode, setRecipientCompanyCode] = React.useState('');
  const [approvalWorkflowStage, setApprovalWorkflowStage] = React.useState({ label: 'None', value: 'None' });
  const [currentPageIndex, setCurrentPageIndex] = React.useState(0);
  const [isCompleteDataFetched, setIsCompleteDataFetch] = React.useState(false);
  const [pagesCount, setPagesCount] = React.useState(1);
  const [getRegistrationRecordsMap] = React.useState(new Map<number, RegistrationRecord[]>());
  const [nextToken, setNextToken] = React.useState('');
  const [loadingRegistrationRecords, setLoadingRegistrationRecords] = React.useState(false);
  const [searchDone, setSearchDone] = React.useState(false);
  const [loadingCompanyCodes, setLoadingCompanyCodes] = React.useState(false);
  const [fetchCompanyCodesErrorMessage, setFetchCompanyCodesErrorMessage] = React.useState('');
  const [visibleBulkRegistrationModal, setVisibleBulkRegistrationModal] = React.useState(false);
  const history = useHistory();

  const { downloadingRecords, downloadDraftRegistrationRecordsErrorMessage, getRegistrationRecords } =
    useDownloadRegistrationRecords(services);

  useEffect(() => {
    if (downloadDraftRegistrationRecordsErrorMessage) {
      services.messageService.showErrorAutoDismissBanner(downloadDraftRegistrationRecordsErrorMessage);
    }
  }, [downloadDraftRegistrationRecordsErrorMessage]);

  /**
   *
   * @param s3Key
   * @param userAlias
   */
  const startBulkRegistrationRequest = async (s3Key: string, userAlias: string, bulkUploadIdentifier: string) => {
    const payload = {
      action: CONSTANTS.WEBSOCKET_ROUTES.PROCESS_BULK_REGISTRATION,
      data: {
        s3Key: s3Key,
        bulkUploadIdentifier: bulkUploadIdentifier,
        uploadUser: userAlias,
        userRole: getUserRole(isIndirectTaxUser, isDirectTaxUser, isAccountingUser, isSuperUser),
      },
    };
    services.icrsWebsocketService.processBulkRegistrations(payload);
  };

  const emptyNextToken = '';

  const { companyCodes, setCompanyCodes } = useContext(GlobalAppContext);

  const stages = Constants.APPROVAL_WORKFLOW_STAGES;

  const { items, collectionProps } = useCollection(
    getRegistrationRecordsMap.get(currentPageIndex) ? getRegistrationRecordsMap.get(currentPageIndex)! : [],
    {
      sorting: {},
    },
  );

  const baseColumns = [
    Constants.GET_REGISTRATION_RECORDS_COLUMNS.REGISTRATION_NUMBER,
    Constants.GET_REGISTRATION_RECORDS_COLUMNS.APPROVAL_WORKFLOW_STAGE,
    Constants.GET_REGISTRATION_RECORDS_COLUMNS.ATP_CALCULATION_FLAG,
    Constants.GET_REGISTRATION_RECORDS_COLUMNS.TAX_PRODUCT_CATEGORY_NAME,
    Constants.GET_REGISTRATION_RECORDS_COLUMNS.TAX_PRODUCT_NAME,
    Constants.GET_REGISTRATION_RECORDS_COLUMNS.PAYMENT_TERMS,
    Constants.GET_REGISTRATION_RECORDS_COLUMNS.LAST_UPDATED_DATE_TIME,
  ];

  const visibleColumns = isAccountingUser
    ? baseColumns
    : [...baseColumns.slice(0, 2), Constants.GET_REGISTRATION_RECORDS_COLUMNS.APPROVAL_LINK, ...baseColumns.slice(2)];

  function getCompanyCodes() {
    // If company codes are already loaded from React context, just return.
    if (companyCodes.length != 0) {
      return;
    }
    getCompanyCodesFromALE(
      services.aleDataService,
      setCompanyCodes,
      setLoadingCompanyCodes,
      setFetchCompanyCodesErrorMessage,
    );
  }

  async function onSearchButtonClick() {
    getRegistrationRecordsMap.clear();
    setCurrentPageIndex(0);
    setPagesCount(1);
    setNextToken(emptyNextToken);
    setIsCompleteDataFetch(false);

    if (registrationNumber.length !== 0) {
      await getRegistrationRecord(registrationNumber);
      return;
    }
    if (approvalWorkflowStage.value !== 'None') {
      await getRecordsByApprovalWorkflowStage(approvalWorkflowStage.value, emptyNextToken);
      return;
    }
    await getRecordsByCompanyCodes(emptyNextToken);
  }

  async function getRegistrationRecord(registrationNumber: string) {
    let encodedRegistrationNumber = registrationNumber.replace('.', '*');
    history.push({
      pathname: `/show/${encodeURIComponent(encodedRegistrationNumber)}`,
    });
  }

  async function getRecordsByApprovalWorkflowStage(approvalWorkflowStage: string, nextToken: string) {
    setLoadingRegistrationRecords(true);
    const { registrationRecords, nextPageToken, error } =
      await services.registrationDataService.getRecordsByApprovalWorkflowStage(approvalWorkflowStage, nextToken);
    setLoadingRegistrationRecords(false);

    handleSearchResponse(registrationRecords, nextPageToken, error);
  }

  async function getRecordsByCompanyCodes(nextToken: string) {
    setLoadingRegistrationRecords(true);
    const { registrationRecords, nextPageToken, error } =
      await services.registrationDataService.getRecordsByCompanyCodes(
        providerCompanyCode,
        recipientCompanyCode,
        nextToken,
      );
    setLoadingRegistrationRecords(false);

    handleSearchResponse(registrationRecords, nextPageToken, error);
  }

  function handleSearchResponse(
    registrationRecords: RegistrationRecord[] | undefined,
    nextPageToken: string | undefined,
    error: string | undefined,
  ) {
    if (error) {
      services.messageService.showErrorAutoDismissBanner(error);
      return;
    }

    if (registrationRecords && registrationRecords.length > 0) {
      const newPageIndex = getRegistrationRecordsMap.size + 1;
      getRegistrationRecordsMap.set(newPageIndex, registrationRecords);
      setCurrentPageIndex(newPageIndex);
      setPagesCount(newPageIndex); // Update total page count
    }

    if (nextPageToken) {
      setNextToken(nextPageToken);
    } else {
      setNextToken(emptyNextToken);
      setIsCompleteDataFetch(true);
    }
    setSearchDone(true);
  }

  function onPageChange(detail: { currentPageIndex: number }) {
    const newPageIndex = detail.currentPageIndex;
    // Check if the page is already fetched
    if (getRegistrationRecordsMap.has(newPageIndex)) {
      setCurrentPageIndex(newPageIndex);
    } else if (nextToken && !isCompleteDataFetched) {
      // Fetch next page if more data is available
      if (approvalWorkflowStage.value !== 'None') {
        getRecordsByApprovalWorkflowStage(approvalWorkflowStage.value, nextToken);
      } else {
        getRecordsByCompanyCodes(nextToken);
      }
    }
  }

  return (
    <div>
      <FileUploadModal
        services={services}
        visibleFileModal={visibleBulkRegistrationModal}
        onClose={() => setVisibleBulkRegistrationModal(false)}
        modalHeaderName="Bulk Upload"
        uploadButtonName="Upload"
        formDescription="Download bulk upload file template"
        formLabel="Bulk Upload file"
        s3Bucket={CONSTANTS.ENVIRONMENT_VARIABLES.ICRS_UPLOADS_BUCKET}
        s3Key={s3KeyToRegisterRecord}
        onSuccessRedirect={() => {
          startBulkRegistrationRequest(s3KeyToRegisterRecord, userAlias, bulkUploadIdentifier);
          history.push('/create/track', { bulkUploadIdentifier: bulkUploadIdentifier });
        }}
        acceptFileType=".xlsx "
        fileUploadConstraintText="Please upload file only in .xlsx format"
        contentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <Form
        actions={
          <Button
            data-testid="search-records-submit-button"
            variant="primary"
            loading={loadingRegistrationRecords}
            onClick={onSearchButtonClick}
            disabled={
              registrationNumber.trim().length == 0 &&
              providerCompanyCode.trim().length == 0 &&
              recipientCompanyCode.trim().length == 0 &&
              approvalWorkflowStage.value == 'None'
            }
          >
            Search
          </Button>
        }
        header={
          <Header
            variant="h1"
            actions={
              <SpaceBetween size="xs" direction="horizontal">
                {(isSuperUser === true || isIndirectTaxUser === true) && (
                  <Button
                    variant="primary"
                    onClick={() => setVisibleBulkRegistrationModal(true)}
                    data-testid="bulk-registration-button"
                    data-class="bulk-registration-button"
                  >
                    Submit Bulk Update Registration
                  </Button>
                )}
              </SpaceBetween>
            }
          >
            Search Registration Records
          </Header>
        }
      >
        <ColumnLayout variant="text-grid" columns={4}>
          <FormField label="Registration Number">
            <Input
              data-testid="search-registration-number-input"
              value={registrationNumber}
              onChange={(event) => setRegistrationNumber(event.detail.value)}
              placeholder="Enter registration number"
              disabled={
                providerCompanyCode.trim().length != 0 ||
                recipientCompanyCode.trim().length != 0 ||
                approvalWorkflowStage.value !== 'None'
              }
              type="search"
              inputMode="search"
            />
          </FormField>
          <FormField label="Provider Company Code">
            <Autosuggest
              data-testid="search-provider-company-code-input"
              value={providerCompanyCode}
              options={createLabelValueList(companyCodes)}
              onFocus={() => getCompanyCodes()}
              loadingText="Loading company codes"
              statusType={loadingCompanyCodes ? 'loading' : fetchCompanyCodesErrorMessage ? 'error' : 'finished'}
              errorText={fetchCompanyCodesErrorMessage}
              onChange={({ detail }) => setProviderCompanyCode(detail.value)}
              enteredTextLabel={(value) => `Use: "${value}"`}
              disabled={registrationNumber.trim().length != 0 || approvalWorkflowStage.value !== 'None'}
              virtualScroll
              empty="No company codes found"
            />
          </FormField>
          <FormField label="Recipient Company Code">
            <Autosuggest
              data-testid="search-recipient-company-code-input"
              value={recipientCompanyCode}
              options={createLabelValueList(companyCodes)}
              onFocus={() => getCompanyCodes()}
              loadingText="Loading company codes"
              statusType={loadingCompanyCodes ? 'loading' : fetchCompanyCodesErrorMessage ? 'error' : 'finished'}
              errorText={fetchCompanyCodesErrorMessage}
              onChange={({ detail }) => setRecipientCompanyCode(detail.value)}
              enteredTextLabel={(value) => `Use: "${value}"`}
              disabled={registrationNumber.trim().length != 0 || approvalWorkflowStage.value !== 'None'}
              virtualScroll
              empty="No company codes found"
            />
          </FormField>
          <FormField label="Approval Workflow Stage">
            <Select
              data-testid="approval-workflow-stage-input"
              selectedOption={approvalWorkflowStage}
              onChange={({ detail: { selectedOption } }) => setApprovalWorkflowStage(selectedOption as SelectedOption)}
              options={[
                { label: 'None', value: 'None' },
                { label: stages.DRAFT, value: stages.DRAFT },
                { label: stages.IN_TAX_APPROVAL, value: stages.IN_TAX_APPROVAL },
                { label: stages.ACTIVE, value: stages.ACTIVE },
                { label: stages.AMENDED, value: stages.AMENDED },
                { label: stages.INACTIVE, value: stages.INACTIVE },
                { label: stages.TERMINATED, value: stages.TERMINATED },
                { label: stages.TAX_APPROVAL_REJECTED, value: stages.TAX_APPROVAL_REJECTED },
              ]}
              placeholder="Choose a stage"
              disabled={
                registrationNumber.trim().length !== 0 ||
                providerCompanyCode.trim().length !== 0 ||
                recipientCompanyCode.trim().length !== 0
              }
            ></Select>
          </FormField>
        </ColumnLayout>
      </Form>

      <br />
      <br />
      <div>
        {searchDone ? (
          <div>
            <Table
              data-testid="registration-records-table"
              {...collectionProps}
              loading={loadingRegistrationRecords}
              loadingText="Loading Registration Records"
              pagination={
                <Pagination
                  currentPageIndex={currentPageIndex}
                  onChange={({ detail }) => {
                    onPageChange(detail);
                  }}
                  openEnd={!isCompleteDataFetched}
                  pagesCount={pagesCount}
                />
              }
              columnDefinitions={[
                {
                  id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.REGISTRATION_NUMBER,
                  header: 'Registration Number',
                  cell: (registrationRecord) => (
                    <Link onClick={() => getRegistrationRecord(registrationRecord.registrationNumber!)}>
                      {registrationRecord.registrationNumber}
                    </Link>
                  ),
                  sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.REGISTRATION_NUMBER,
                },
                {
                  id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.APPROVAL_WORKFLOW_STAGE,
                  header: 'Approval Stage',
                  cell: (registrationRecord) => registrationRecord.approvalWorkflowStage,
                  sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.APPROVAL_WORKFLOW_STAGE,
                },
                {
                  id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.APPROVAL_LINK,
                  header: 'Approval Link',
                  cell: (registrationRecord) =>
                    registrationRecord.approvalId ? (
                      <Link external href={createApprovalLinkForStage(registrationRecord.approvalId)} variant="primary">
                        Click here
                      </Link>
                    ) : null,
                },
                {
                  id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.ATP_CALCULATION_FLAG,
                  header: 'ATP Calculation',
                  cell: (registrationRecord) => String(registrationRecord.atpCalculationFlag),
                  sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.ATP_CALCULATION_FLAG,
                },
                {
                  id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.TAX_PRODUCT_CATEGORY_NAME,
                  header: 'Tax Product Category Name',
                  cell: (registrationRecord) => registrationRecord.taxProductCategoryName,
                  sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.TAX_PRODUCT_CATEGORY_NAME,
                },
                {
                  id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.TAX_PRODUCT_NAME,
                  header: 'Tax Product Sub Category Name',
                  cell: (registrationRecord) => registrationRecord.taxProductName,
                  sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.TAX_PRODUCT_NAME,
                },
                {
                  id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.PAYMENT_TERMS,
                  header: 'Payment Terms',
                  cell: (registrationRecord) => registrationRecord.paymentTerms,
                  sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.PAYMENT_TERMS,
                },
                {
                  id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.LAST_UPDATED_DATE_TIME,
                  header: 'Last Updated Date Time',
                  cell: (registrationRecord) => formatDate(new Date(registrationRecord.lastUpdatedDateTime!)),
                  sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.LAST_UPDATED_DATE_TIME,
                },
              ]}
              items={items}
              resizableColumns
              visibleColumns={visibleColumns}
              empty={
                <Box textAlign="center" color="inherit">
                  {' '}
                  No Registration data found{' '}
                </Box>
              }
              header={
                <Header
                  actions={
                    <Button
                      iconName="download"
                      loading={downloadingRecords}
                      variant="link"
                      disabled={getRegistrationRecordsMap.size == 0}
                      onClick={() => {
                        getRegistrationRecords(providerCompanyCode, recipientCompanyCode, approvalWorkflowStage.label);
                      }}
                      data-testid="download-template-button"
                      data-class="download-template-button"
                    >
                      {downloadingRecords ? 'Downloading All Records...' : 'Download All Records'}
                    </Button>
                  }
                >
                  ICRS Registration Records
                </Header>
              }
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
