import { useState, useCallback } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';

export const useDiscardWorkflowRevision = (
  services: ServiceCollection,
  registrationRecordToDiscard: RegistrationRecord | null,
  userAlias: string,
) => {
  const [isDiscardLoading, setIsDiscardLoading] = useState(false);
  const [isDiscardSuccess, setIsDiscardSuccess] = useState(false);
  const [discardedRecord, setDiscardedRecord] = useState<RegistrationRecord | null>(null);
  const [discardRevisionError, setDiscardRevisionError] = useState<string | null>(null);

  const submitForDiscardWorkflowRevision = useCallback(async () => {
    if (registrationRecordToDiscard === null) {
      return;
    }
    setIsDiscardLoading(true);
    const { lastApprovedRecord, error } = await services.registrationDataService.discardWorkflowRevision(
      registrationRecordToDiscard!.baseRegistrationNumber!,
      registrationRecordToDiscard!.approvalWorkflowRev!,
      userAlias,
    );
    setIsDiscardLoading(false);
    if (error) {
      setDiscardRevisionError(error);
      setIsDiscardSuccess(false);
      return;
    }
    if (lastApprovedRecord) {
      setDiscardedRecord(lastApprovedRecord);
    }
    setIsDiscardSuccess(true);
  }, [services, registrationRecordToDiscard, userAlias]);

  return {
    isDiscardLoading,
    discardedRecord,
    isDiscardSuccess,
    discardRevisionError,
    submitForDiscardWorkflowRevision,
  };
};
