import React from 'react';
import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import CONSTANTS from 'src/utils/constants';
import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';
import ServiceCollection from 'src/services/ServiceCollection';

interface ShowApprovalWorkflowActionsProps {
  registrationRecord: RegistrationRecord;
  stage: string | undefined;
  isViewer: boolean;
  isAccountingUser: boolean;
  isDirectTaxUser: boolean;
  isApprovalSubmittedLoading: boolean;
  setEditModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  goToEditPage: () => void;
  setDiscardModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  submitForApproval: () => void;
  deactivate: () => void;
  setActivateModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setCancelModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  services: ServiceCollection;
}

export default function ShowApprovalWorkflowActions({
  registrationRecord,
  stage,
  isViewer,
  isAccountingUser,
  isDirectTaxUser,
  isApprovalSubmittedLoading,
  setEditModalVisible,
  goToEditPage,
  setDiscardModalVisible,
  submitForApproval,
  deactivate,
  setActivateModalVisible,
  setCancelModalVisible,
  services,
}: ShowApprovalWorkflowActionsProps) {
  if (isViewer || isDirectTaxUser || !registrationRecord.latestRev) {
    return null;
  }

  const validateRequiredFields = (record: RegistrationRecord): string[] => {
    const validations = [
      { name: 'Provider Company Code', valid: !!record.providerCompanyCode?.trim() },
      { name: 'Recipient Company Code', valid: !!record.recipientCompanyCode?.trim() },
      { name: 'Payment Terms', valid: !!record.paymentTerms?.trim() },
      { name: 'Currency', valid: !!record.currency?.trim() },
      { name: 'Effective From Date', valid: !!record.effectiveFromDate },
      { name: 'Description', valid: !!record.description?.trim() },
      { name: 'Accounting POC', valid: !!record.accountingPoc?.trim() },
      { name: 'Provider IC BS Accounts', valid: (record.providerIcBsAccounts?.length ?? 0) > 0 },
      { name: 'Recipient IC BS Accounts', valid: (record.recipientIcBsAccounts?.length ?? 0) > 0 },
      { name: 'Provider PL Accounts', valid: (record.providerPlAccounts?.length ?? 0) > 0 },
      { name: 'Recipient PL Accounts', valid: (record.recipientPlAccounts?.length ?? 0) > 0 },
      { name: 'Tax Product Category Name', valid: !!record.taxProductCategoryName?.trim() },
      { name: 'Tax Product Sub Category Name', valid: !!record.taxProductName?.trim() },
    ];

    return validations.filter((validation) => !validation.valid).map((validation) => validation.name);
  };

  const handleSubmitAttempt = () => {
    const missingFields = validateRequiredFields(registrationRecord);

    if (missingFields.length > 0) {
      services.messageService.showErrorBanner(`Missing required fields: ${missingFields.join(', ')}`);
      return;
    }

    submitForApproval();
  };

  const { DRAFT, IN_TAX_APPROVAL, ACTIVE, INACTIVE, TERMINATED, TAX_APPROVAL_REJECTED } =
    CONSTANTS.APPROVAL_WORKFLOW_STAGES;

  const renderEditButton = () =>
    stage !== INACTIVE &&
    stage !== TERMINATED && (
      <Button
        data-testid="go-to-edit-page-button"
        variant="normal"
        onClick={() =>
          stage === CONSTANTS.APPROVAL_WORKFLOW_STAGES.ACTIVE ? setEditModalVisible(true) : goToEditPage()
        }
      >
        Edit
      </Button>
    );

  const renderDiscardButton = () =>
    (stage === DRAFT || stage === IN_TAX_APPROVAL || stage == TAX_APPROVAL_REJECTED) && (
      <Button data-testid="discard-revision-button" variant="primary" onClick={() => setDiscardModalVisible(true)}>
        Discard Version
      </Button>
    );

  const renderSubmitForApprovalButton = () =>
    !isAccountingUser &&
    (stage === DRAFT || stage === TAX_APPROVAL_REJECTED) && (
      <Button
        data-testid="submit-for-approval-button"
        variant="primary"
        disabled={isApprovalSubmittedLoading}
        onClick={handleSubmitAttempt}
      >
        {isApprovalSubmittedLoading ? 'Submitting...' : 'Submit for Approval'}
      </Button>
    );

  const renderDeactivateButton = () =>
    stage === ACTIVE && (
      <Button data-testid="deactivate-button" variant="primary" onClick={deactivate}>
        Deactivate
      </Button>
    );

  const renderActivateButton = () =>
    stage === INACTIVE && (
      <Button data-testid="activate-button" variant="primary" onClick={() => setActivateModalVisible(true)}>
        Reactivate
      </Button>
    );

  const renderTerminateButton = () =>
    (stage === ACTIVE || stage === INACTIVE) && (
      <Button data-testid="cancel-button" variant="primary" onClick={() => setCancelModalVisible(true)}>
        Terminate Registration Record
      </Button>
    );

  return (
    <div data-class="actions">
      <SpaceBetween direction="horizontal" size="xs">
        {renderEditButton()}
        {renderDiscardButton()}
        {renderSubmitForApprovalButton()}
        {renderDeactivateButton()}
        {renderActivateButton()}
        {renderTerminateButton()}
      </SpaceBetween>
    </div>
  );
}
