import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';
import buildRegistrationRecordToUpdate from './utils';
import {
  ACCOUNTING_APPROVER_UNDEFINED,
  ACTIVE,
  TERMINATED,
  CANCELLED_BY_UNDEFINED,
  DEACTIVATED_BY_UNDEFINED,
  INACTIVE,
  REACTIVATED_BY_UNDEFINED,
  TAX_APPROVER_UNDEFINED,
} from './definitions';

export class UpdateRegistrationRecordManager {
  private userAlias: string;
  private registrationRecordToUpdate: RegistrationRecord;
  private updatedRegistrationRecordHandler: any;

  constructor(
    registrationRecordToUpdate: RegistrationRecord,
    userAlias: string,
    updatedRegistrationRecordHandler: any,
  ) {
    this.registrationRecordToUpdate = registrationRecordToUpdate;
    this.userAlias = userAlias;
    this.updatedRegistrationRecordHandler = updatedRegistrationRecordHandler;
  }

  buildUpdatedRegistrationRecord(
    status: string,
    accountingApprover: string | undefined,
    taxApprover: string | undefined,
    cancelledBy: string | undefined,
    deactivatedBy: string | undefined,
    reactivatedBy: string | undefined,
  ) {
    return buildRegistrationRecordToUpdate(
      this.registrationRecordToUpdate,
      status,
      accountingApprover,
      taxApprover,
      cancelledBy,
      deactivatedBy,
      reactivatedBy,
    );
  }

  deactivate() {
    const recordToUpdate = this.buildUpdatedRegistrationRecord(
      INACTIVE,
      ACCOUNTING_APPROVER_UNDEFINED,
      TAX_APPROVER_UNDEFINED,
      CANCELLED_BY_UNDEFINED,
      this.userAlias,
      REACTIVATED_BY_UNDEFINED,
    );
    this.updatedRegistrationRecordHandler(recordToUpdate);
  }

  activate() {
    const recordToUpdate = this.buildUpdatedRegistrationRecord(
      ACTIVE,
      ACCOUNTING_APPROVER_UNDEFINED,
      TAX_APPROVER_UNDEFINED,
      CANCELLED_BY_UNDEFINED,
      DEACTIVATED_BY_UNDEFINED,
      this.userAlias,
    );
    this.updatedRegistrationRecordHandler(recordToUpdate);
  }

  cancel() {
    const recordToUpdate = this.buildUpdatedRegistrationRecord(
      TERMINATED,
      ACCOUNTING_APPROVER_UNDEFINED,
      TAX_APPROVER_UNDEFINED,
      this.userAlias,
      DEACTIVATED_BY_UNDEFINED,
      REACTIVATED_BY_UNDEFINED,
    );
    this.updatedRegistrationRecordHandler(recordToUpdate);
  }
}
