import { useState, useCallback } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';

export const useDeactivateRegistrationRecord = (
  services: ServiceCollection,
  registrationRecord: RegistrationRecord,
  requestingUser: string,
) => {
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [deactivatedRecord, setDeactivatedRecord] = useState<RegistrationRecord | null>(null);
  const [deactivationError, setDeactivationError] = useState<string | null>(null);

  const submitDeactivation = useCallback(async () => {
    setIsDeactivating(true);
    const { deactivatedRecord, error } = await services.registrationDataService.deactivateRegistrationRecord(
      registrationRecord.registrationNumber!,
      requestingUser,
    );
    setIsDeactivating(false);

    if (error) {
      setDeactivationError(error);
      return;
    }
    if (deactivatedRecord) {
      setDeactivatedRecord(deactivatedRecord);
    }
  }, [services, registrationRecord, requestingUser]);

  return {
    isDeactivating,
    deactivatedRecord,
    deactivationError,
    submitDeactivation,
  };
};
