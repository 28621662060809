import { Button } from '@amzn/awsui-components-react';
import React from 'react';
import ServiceCollection from 'src/services/ServiceCollection';

export default function S3FileDownloaderButton(props: {
  presignedUrl: string | null;
  disabledCondition: any;
  buttonName: string;
  services: ServiceCollection;
}) {
  const downloadFile = async () => {
    try {
      if (!props.presignedUrl) {
        console.error('No presigned URL available');
        return;
      }

      // Open in a new tab
      window.open(props.presignedUrl, '_blank');

      // Alternatively, force download
      const response = await fetch(props.presignedUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      props.services.messageService.showErrorAutoDismissBanner(
        'Error while downloading the file, please contact technical team for further support',
      );
    }
  };

  return (
    <Button onClick={downloadFile} disabled={props.disabledCondition}>
      {' '}
      {props.buttonName}
    </Button>
  );
}
