import React, { useState, useCallback, useContext } from 'react';
import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';
import ServiceCollection from 'src/services/ServiceCollection';

export const useGetRegistrationRecordsByBulkUploadIdentifier = (
  services: ServiceCollection,
  bulkUploadIdentifier: string | undefined,
) => {
  const [registrationRecordsToDisplay, setRegistrationRecordsToDisplay] = useState<RegistrationRecord[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [getRegistrationRecordsReponseErrorMessage, setGetRegistrationRecordsReponseErrorMessage] = useState('');

  const getRegistrationRecords = useCallback(async () => {
    setIsLoading(true);
    // If data is already loaded from React context, just return.
    const { registrationRecords, error } =
      await services.registrationDataService.getRecordsByBulkUploadIdentifier(bulkUploadIdentifier);
    if (error) {
      setGetRegistrationRecordsReponseErrorMessage(error);
      setIsLoading(false);
      services.messageService.showErrorAutoDismissBanner(
        'Error while getting registration records, please contanct technical team for further support.',
      );
      return;
    }
    setRegistrationRecordsToDisplay(registrationRecords);
    setIsLoading(false);
  }, [services, bulkUploadIdentifier]);

  return {
    isLoading,
    registrationRecordsToDisplay,
    getRegistrationRecords,
    getRegistrationRecordsReponseErrorMessage,
  };
};
