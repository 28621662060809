import { UserRole } from '../track-bulk-registration-request/definitions';
import { SelectProps } from '@amzn/awsui-components-react';

/**
 * This function will be used to generate the S3 key for ever upload request
 * @param userAlias User Alias
 * @returns S3 Key
 */
export function generateS3Key(userAlias: string) {
  const currentTimeStamp = Date.now();
  return `ICRS_BULK_UPLOAD_REQUEST-${userAlias}-${currentTimeStamp}.xlsx`;
}

/**
 * This function will be used to get the user role based on the user type
 * for the Create Registeration page Super User will act as an accountingUser
 * @param isTaxUser Is a tax user
 * @param isAccountingUser Is an accounting user
 * @param isSuperUser Is a super user
 * @returns Role of the User
 */
export function getUserRole(isTaxUser: boolean, isAccountingUser: boolean, isSuperUser: boolean) {
  if (isTaxUser) {
    return UserRole.TAX_USER;
  } else if (isAccountingUser) {
    return UserRole.ACCOUNTING_USER;
  } else if (isSuperUser) {
    return UserRole.ACCOUNTING_USER;
  }
}

/**
 *
 * @param userAlias
 * @returns
 */
export function generateBulkUploadIdentifier(userAlias: string) {
  const currentTimeStamp = Date.now();
  return `${userAlias}-${currentTimeStamp}`;
}

/**
 * Function to create an object with a label and value.
 *
 * @param value - the value in the object
 * @param label - optional label in the object, defaults to the value if not provided
 * @returns object with label and value
 */
export const createLabelValueObject = (value: string, label?: string) => {
  return {
    label: label ?? value,
    value: value,
  };
};

/**
 * Function to create a label-value list from an array of strings.
 *
 * @param items - list of string items to generate label-value objects from
 * @param getLabel - optional function to get the label from each item (defaults to the value if not provided)
 * @returns - list of objects with label and value properties
 */
export const createLabelValueList = (items: string[], getLabel?: (item: string) => string) => {
  return items?.map((item) => createLabelValueObject(item, getLabel?.(item)));
};

/**
 * Extracts the country name from a jurisdiction string.
 *
 * The jurisdiction string is expected to be in the format:
 * `CODE [Country/Region]`, e.g., `USTX000 [United States/Texas]`.
 *
 * This function will extract and return the country name (the part before the `/`)
 * within the square brackets. If no match is found, it returns an empty string.
 *
 * @param jurisdiction - The jurisdiction string containing the country and region information.
 * @returns The country name extracted from the jurisdiction string, or an empty string if not found.
 */
export const extractCountryFromJurisdiction = (jurisdiction: string): string => {
  const match = jurisdiction.match(/\[(.*?)\]/);
  return match ? match[1].split('/')[0].trim() : '';
};
/**
 * Converts an array of SelectProps.Option objects to an array of strings.
 *
 * @param {SelectProps.Option[]} options - An array of select options containing value properties
 * @returns {string[]} An array of strings containing only the defined value properties from the options
 */
export const convertOptionsToStrings = (options: SelectProps.Option[]): string[] => {
  return options.map((option) => option.value).filter((value): value is string => value !== undefined);
};
