import { useState, useCallback } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';

export const useGetPastRevsionRecord = (services: ServiceCollection, registrationRecord: RegistrationRecord) => {
  const [isGetPastRevisionRecordLoading, setIsGetPastRevisionRecordLoading] = useState(false);
  const [pastRevisionRecord, setPastRevisionRecord] = useState<RegistrationRecord | null>(null);
  const [getPastRevisionRecordError, setPastRevisionRecordError] = useState<string | null>(null);

  const submitToGetPastRevisionRecord = useCallback(
    async (revisionNumber: string) => {
      setIsGetPastRevisionRecordLoading(true);
      const { revisionRecord, error } = await services.registrationDataService.getRevisionRecord(
        registrationRecord!.baseRegistrationNumber!,
        Number(revisionNumber),
      );
      setIsGetPastRevisionRecordLoading(false);
      if (error) {
        setPastRevisionRecordError(error);
        return;
      }
      if (revisionRecord) {
        setPastRevisionRecord(revisionRecord);
      }
    },
    [services, registrationRecord],
  );

  return {
    isGetPastRevisionRecordLoading,
    pastRevisionRecord,
    getPastRevisionRecordError,
    submitToGetPastRevisionRecord,
  };
};
