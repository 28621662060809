import apiService from 'src/services/ApiCallService';
import ErrorUtils from 'src/utils/ErrorUtils';

export default class BulkUploadService {
  /**
   * Fetches bulk upload template.
   * @returns A pre-signed URL to download the bulk upload template from S3, or error message on failure.
   */
  async getTemplate() {
    let downloadURL: string | undefined = undefined;
    let error: string | undefined = undefined;

    try {
      const response = await apiService.downloadBulkUploadTemplate();
      const json = await response.json();
      downloadURL = (json as any).downloadURL;
    } catch (ex) {
      error = ErrorUtils.getMessage(ex);
    }

    return {
      downloadURL,
      error,
    };
  }

  /**
   * Fetches bulk upload template.
   * @returns A pre-signed URL to download the bulk upload template from S3, or error message on failure.
   */
  async downloadRegistrationRecords(
    providerCompanyCode: string | undefined,
    recipientCompanyCode: string | undefined,
    approvalWorkflowStage: string | undefined,
  ) {
    let downloadURL: string | undefined = undefined;
    let error: string | undefined = undefined;

    try {
      const response = await apiService.downloadRegistrationRecords(
        providerCompanyCode,
        recipientCompanyCode,
        approvalWorkflowStage,
      );
      const json = await response.json();
      downloadURL = (json as any).downloadURL;
    } catch (ex) {
      error = ErrorUtils.getMessage(ex);
    }

    return {
      downloadURL,
      error,
    };
  }
}
