import { useState, useCallback } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';

export const useTerminateRegistrationRecord = (
  services: ServiceCollection,
  registrationRecord: RegistrationRecord,
  requestingUser: string,
) => {
  const [isTerminating, setIsTerminating] = useState(false);
  const [terminatedRecord, setTerminatedRecord] = useState<RegistrationRecord | null>(null);
  const [terminationError, setTerminationError] = useState<string | null>(null);

  const submitTermination = useCallback(async () => {
    setIsTerminating(true);
    const { terminatedRecord, error } = await services.registrationDataService.terminateRegistrationRecord(
      registrationRecord.registrationNumber!,
      requestingUser,
    );
    setIsTerminating(false);

    if (error) {
      setTerminationError(error);
      return;
    }
    if (terminatedRecord) {
      setTerminatedRecord(terminatedRecord);
    }
  }, [services, registrationRecord, requestingUser]);

  return {
    isTerminating,
    terminatedRecord,
    terminationError,
    submitTermination,
  };
};
