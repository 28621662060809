import { useState, useCallback } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';

export const useUpdateRegistrationRecord = (services: ServiceCollection) => {
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [updatedRegistrationRecord, setUpdatedRegistrationRecord] = useState<RegistrationRecord | null>(null);
  const [updateErrorMessage, setUpdateErrorMessage] = useState<string | null>(null);

  const submitForUpdate = async (registrationRecordToUpdate: RegistrationRecord) => {
    setIsUpdateLoading(true);
    const { updatedRegistrationRecord, error } =
      await services.registrationDataService.updateRegistrationRecord(registrationRecordToUpdate);

    setIsUpdateLoading(false);
    if (error) {
      setUpdateErrorMessage(error);
      return;
    }
    if (updatedRegistrationRecord) {
      setUpdatedRegistrationRecord(updatedRegistrationRecord);
    }
  };

  return {
    isUpdateLoading,
    updatedRegistrationRecord,
    updateErrorMessage,
    submitForUpdate,
  };
};
