import FramesService from 'src/services/FramesService';
import { Dispatch, SetStateAction } from 'react';
import { SelectProps } from '@amzn/awsui-components-react';

/**
 * Function to fetch accounts from the service and store them in React context.
 *
 * @param framesService
 * @param setAccounts
 * @param setLoadingAccounts
 * @param setFetchAccountsErrorMessage
 * @param companyCodesFilter
 */
export const fetchAccounts = async (
  framesService: FramesService,
  setAccounts: Dispatch<SetStateAction<string[]>>,
  setLoadingAccounts: Dispatch<SetStateAction<boolean>>,
  setFetchAccountsErrorMessage: Dispatch<SetStateAction<string>>,
  companyCodesFilter: string,
) => {
  setLoadingAccounts(true);
  const { accounts, error } = await framesService.getAccounts(companyCodesFilter);

  if (error) {
    setFetchAccountsErrorMessage(error);
    return;
  }

  setAccounts(accounts!);
  setLoadingAccounts(false);
};
