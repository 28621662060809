import { useState, useCallback } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';

export const useGetLatestRevsionRecord = (services: ServiceCollection, registrationRecord: RegistrationRecord) => {
  const [isGetRecordLoading, setIsGetRecordLoading] = useState(false);
  const [latestRevisionRecord, setLatestRevisionRecord] = useState<RegistrationRecord | null>(null);
  const [getLatestRevisionRecordError, setGetLatestRevisionRecordError] = useState<string | null>(null);

  const submitToGetLatestRevisionRecord = useCallback(async () => {
    setIsGetRecordLoading(true);
    const isLatestRev = true;
    const { revisionRecord, error } = await services.registrationDataService.getRevisionRecord(
      registrationRecord!.baseRegistrationNumber!,
      undefined,
      isLatestRev,
    );
    setIsGetRecordLoading(false);
    if (error) {
      setGetLatestRevisionRecordError(error);
      return;
    }
    if (revisionRecord) {
      setLatestRevisionRecord(revisionRecord);
    }
  }, [services, registrationRecord]);

  return {
    isGetRecordLoading,
    latestRevisionRecord,
    getLatestRevisionRecordError,
    submitToGetLatestRevisionRecord,
  };
};
