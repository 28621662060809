export interface GlobalState {
  bannerItems: Array<any>;
  originalUserRoles: string[];
  currentUserRoles: string[];
}

export const initialState: GlobalState = {
  bannerItems: [],
  originalUserRoles: [],
  currentUserRoles: [],
};
