import CONSTANTS from 'src/utils/constants';

export const IN_TAX_APPROVAL = CONSTANTS.APPROVAL_WORKFLOW_STAGES.IN_TAX_APPROVAL;
export const ACTIVE = CONSTANTS.APPROVAL_WORKFLOW_STAGES.ACTIVE;
export const INACTIVE = CONSTANTS.APPROVAL_WORKFLOW_STAGES.INACTIVE;
export const TERMINATED = CONSTANTS.APPROVAL_WORKFLOW_STAGES.TERMINATED;
export const ACCOUNTING_APPROVER_UNDEFINED = undefined;
export const TAX_APPROVER_UNDEFINED = undefined;
export const CANCELLED_BY_UNDEFINED = undefined;
export const DEACTIVATED_BY_UNDEFINED = undefined;
export const REACTIVATED_BY_UNDEFINED = undefined;
