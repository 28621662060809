export default class ICRSAction {
  type: string;
  payload: any;

  constructor(type: string) {
    this.type = type;
  }

  withPayload(payload: any): ICRSAction {
    this.payload = payload;
    return this;
  }
}
