import {
  Button,
  Container,
  Form,
  Header,
  KeyValuePairs,
  ProgressBar,
  ProgressBarProps,
  SpaceBetween,
  Spinner,
  StatusIndicator,
  StatusIndicatorProps,
} from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import WebSocketApi from 'src/services/websockets/WebsocketApi';
import CONSTANTS from 'src/utils/constants';
import {
  TrackBulkCreateRegistrationRequestProps,
  BulkRegistrationWebsocketResponse,
  LocationState,
  DEFAULT_COLLECTION_PREFERENCES,
  PAGE_SIZE_OPTIONS,
  VISIBLE_CONTENT_OPTIONS,
  RegistrationTableColumns,
  BulkApprovalWebsocketResponse,
} from './definitions';
import { useLocation } from 'react-router-dom';
import ClientSidePaginatedPolarisTable from 'src/shared/ClientSidePaginatedPolarisTable';
import { useGetRegistrationRecordsByBulkUploadIdentifier } from './hooks/useGetRegistrationRecordsByBulkUploadIdentifier';
import { getRegistrationRecordsTableFilteringProperties, prepareColumnDefinitions } from './TableUtils';
import S3FileDownloaderButton from 'src/shared/S3FileDownloaderButton';
import { set } from 'lodash';

export default function TrackBulkCreateRegistrationRequest(props: TrackBulkCreateRegistrationRequestProps) {
  const location = useLocation<LocationState>();
  const { services, userAlias, isIndirectTaxUser, isSuperUser } = props;

  const { bulkUploadIdentifier } = location.state || {}; // Destructure state safely
  const [error, setError] = useState<string | null>(null);
  const [isRequestStarted, setIsRequestStarted] = useState(false);
  const [doesErrorRowExists, setDoesErrorRowExists] = useState(false);
  const [errorFileUrl, setErrorFileUrl] = useState<string | null>(null);
  const [progressingStatus, setProgressingStatus] = useState<ProgressBarProps.Status>();
  const [progressingBulkSubmitApprovalStatus, setProgressingBulkSubmitApprovalStatus] =
    useState<StatusIndicatorProps.Type>();
  const [webSocketAction, setWebsocketAction] = useState<string>();
  const [resultText, setResultText] = useState<string>();
  const [bulkRegistrationWebsocketResponse, setBulkRegistrationWebsocketResponse] =
    useState<BulkRegistrationWebsocketResponse>();
  const [bulkApprovalWebsocketResponse, setBulkApprovalWebsocketResponse] = useState<BulkApprovalWebsocketResponse>();
  const [isSubmitApprovalButtonDisabled, setIsSubmitButtonApprovalDisabled] = useState<boolean>(true);
  const [isSubmitApprovalLoading, setIsSubmitApprovalLoading] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState(0);
  const [showRegistrationRecords, setShowRegistrationRecords] = useState(false);
  const columnDefinitionsRef = useRef<any[]>([]);
  const filteringPropertiesRef = useRef<any[]>([]);

  /**
   *
   * @param s3Key
   * @param userAlias
   */
  const startBulkApprovalRequest = async () => {
    setProgressingBulkSubmitApprovalStatus('pending');
    const payload = {
      action: CONSTANTS.WEBSOCKET_ROUTES.PROCESS_BULK_APPROVALS,
      data: {
        bulkUploadIdentifier: bulkUploadIdentifier,
        approverUserAlias: userAlias,
      },
    };
    services.icrsWebsocketService.processBulkApproval(payload);
    setIsSubmitApprovalLoading(true);
  };

  const { isLoading, registrationRecordsToDisplay, getRegistrationRecords } =
    useGetRegistrationRecordsByBulkUploadIdentifier(services, bulkUploadIdentifier);

  useEffect(() => {
    columnDefinitionsRef.current = prepareColumnDefinitions();
    filteringPropertiesRef.current = getRegistrationRecordsTableFilteringProperties();
    setupWebsocketEvents(
      services,
      setError,
      setBulkRegistrationWebsocketResponse,
      setBulkApprovalWebsocketResponse,
      setWebsocketAction,
      setErrorFileUrl,
    );
  }, []);

  useEffect(() => {
    if (bulkRegistrationWebsocketResponse) {
      const { status, message } = bulkRegistrationWebsocketResponse;
      if (status === 'STARTED') {
        setIsRequestStarted(true);
        setProgressValue(0);
        setResultText(`Request has been submitted, processing will get started in few seconds`);
      } else if (status === 'IN PROGRESS') {
        const progressValue = message.processedCount / message.totalDistinctRecordsCount;
        setIsRequestStarted(false);
        setProgressingStatus('in-progress');
        setProgressValue(progressValue * 100);
        setResultText(`${message.processedCount} out of ${message.totalDistinctRecordsCount} records completed`);
      } else if (status === 'SUCCEEDED') {
        if (message.numSuccessfulRecords !== 0) {
          setIsSubmitButtonApprovalDisabled(false);
        }
        setIsRequestStarted(false);
        setProgressingStatus('success');
        setResultText(
          `Processed ${message.totalDistinctRecordsCount} unique records: ${message.duplicateRecordsCount} were duplicates, ${message.numSuccessfulRecords} succeeded, and ${message.numFailedRecords} failed.`,
        );
        setShowRegistrationRecords(true);
        if (message.errorFileDownloadURL) {
          setDoesErrorRowExists(true);
          setErrorFileUrl(message.errorFileDownloadURL);
        }
        setProgressValue(100);
        getRegistrationRecords(); // Fetch registration records when succeeded
      } else if (status === 'FAILED') {
        setIsRequestStarted(false);
        setProgressingStatus('error');
        setResultText('Error While registering the records');
      }
    } else {
      setIsRequestStarted(true);
      setResultText(`Waiting for bulk registration request confirmation`);
    }
  }, [bulkRegistrationWebsocketResponse]);

  useEffect(() => {
    if (bulkApprovalWebsocketResponse) {
      const { status, message } = bulkApprovalWebsocketResponse;
      if (status === 'STARTED') {
        setIsSubmitApprovalLoading(true);
        setShowRegistrationRecords(false);
        setProgressingBulkSubmitApprovalStatus('loading');
        setResultText(`Request has been submitted, processing will get started in few seconds`);
      } else if (status === 'IN PROGRESS') {
        setProgressingBulkSubmitApprovalStatus('loading');
        setIsSubmitApprovalLoading(true);
        setResultText(`${message.numSuccessfulRecords} records submitted for approval`);
      } else if (status === 'SUCCEEDED') {
        setProgressingBulkSubmitApprovalStatus('success');
        setIsSubmitApprovalLoading(false);
        setIsSubmitButtonApprovalDisabled(true);
        setResultText(
          `Processed ${message.processedCount} records: ${message.numSuccessfulRecords} were succeeded, and ${message.numFailedRecords} failed.`,
        );
        setShowRegistrationRecords(true);
        if (message.errorFileDownloadURL) {
          setDoesErrorRowExists(true);
          setErrorFileUrl(message.errorFileDownloadURL);
        }
        getRegistrationRecords(); // Fetch registration records when succeeded
      } else if (status === 'FAILED') {
        setProgressingBulkSubmitApprovalStatus('error');
        setResultText('Error While registering the records');
      }
    } else {
      setProgressingBulkSubmitApprovalStatus('loading');
      setResultText(`Waiting for approval submit request confirmation`);
    }
  }, [bulkApprovalWebsocketResponse]);

  return (
    <div>
      <Form header={<Header variant="h1">Bulk Upload Registration </Header>}>
        <Container
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <S3FileDownloaderButton
                    disabledCondition={!doesErrorRowExists}
                    presignedUrl={errorFileUrl}
                    services={services}
                    buttonName="Download Error Records"
                  />
                  {isIndirectTaxUser === true ||
                    (isSuperUser === true && (
                      <Button
                        onClick={() => {
                          startBulkApprovalRequest();
                        }}
                        disabled={isSubmitApprovalButtonDisabled}
                        loading={isSubmitApprovalLoading}
                      >
                        Submit For Tax Approval
                      </Button>
                    ))}
                </SpaceBetween>
              }
            >
              Track Request
            </Header>
          }
        >
          <KeyValuePairs
            columns={2}
            items={[
              { label: 'Requested By', value: `${userAlias}` },
              {
                label: 'Progress State',
                id: 'progress-bar-id',
                value: (
                  <>
                    {error != null && <StatusIndicator type="error">{error}</StatusIndicator>}

                    {bulkApprovalWebsocketResponse == null && webSocketAction == null && (
                      <StatusIndicator type={'pending'}>
                        {'Your request has been submitted, pending to initiate the process'}
                      </StatusIndicator>
                    )}

                    {webSocketAction == CONSTANTS.WEBSOCKET_ROUTES.PROCESS_BULK_APPROVALS && error == null && (
                      <StatusIndicator type={progressingBulkSubmitApprovalStatus}>{resultText}</StatusIndicator>
                    )}

                    {webSocketAction == CONSTANTS.WEBSOCKET_ROUTES.PROCESS_BULK_APPROVALS &&
                      error == null &&
                      progressingBulkSubmitApprovalStatus == 'pending' && (
                        <StatusIndicator type={progressingBulkSubmitApprovalStatus}>
                          {'Submitting approval request'}
                        </StatusIndicator>
                      )}

                    {webSocketAction == CONSTANTS.WEBSOCKET_ROUTES.PROCESS_BULK_REGISTRATION &&
                      isRequestStarted === true &&
                      error == null && <StatusIndicator type="loading">{resultText}</StatusIndicator>}

                    {webSocketAction == CONSTANTS.WEBSOCKET_ROUTES.PROCESS_BULK_REGISTRATION &&
                      isRequestStarted == false &&
                      error == null && (
                        <ProgressBar
                          ariaLabelledby="progress-bar-id"
                          value={progressValue}
                          status={progressingStatus}
                          resultText={resultText}
                          variant="key-value"
                          additionalInfo="To view the failed ingestion records, please click the 'Download Error Records' button to download them."
                        />
                      )}
                  </>
                ),
              },
            ]}
          />
        </Container>
        <br />
        <br />
        {showRegistrationRecords && (
          <ClientSidePaginatedPolarisTable
            sortingColumn="registrationNumber"
            tableEmptyState={'No records to display'}
            headerTitle="Bulk Data Uploaded"
            defaultPreferences={DEFAULT_COLLECTION_PREFERENCES}
            preferencesStorageKey="registrationRecordsByBulkUploadIdentifierSotrageKey"
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            tableItems={registrationRecordsToDisplay}
            visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
            columnDefinitions={columnDefinitionsRef.current}
            resizableColumns={true}
            filteringEmptyText="No records found for the applied filter conditions"
            filteringLoadingText="Loading records for the filter conditions applied"
            filteringProperties={filteringPropertiesRef.current}
            loadingText={'Loading Registrations records successfully uploaded'}
            visibleColumns={RegistrationTableColumns}
            isLoading={isLoading}
          />
        )}
      </Form>
    </div>
  );
}

function setupWebsocketEvents(
  services: ServiceCollection,
  setError: any,
  setBulkRegistrationWebsocketResponse: any,
  setBulkApprovalWebsocketResponse: any,
  setWebsocketAction: any,
  setErrorFileUrl: any,
) {
  const listener = (data: any) => {
    const websocketMessage = JSON.parse(data);
    setWebsocketAction(websocketMessage.messageType);
    switch (websocketMessage.messageType) {
      case CONSTANTS.WEBSOCKET_ROUTES.PROCESS_BULK_REGISTRATION: {
        setBulkRegistrationWebsocketResponse(websocketMessage);
        break;
      }
      case CONSTANTS.WEBSOCKET_ROUTES.PROCESS_BULK_APPROVALS: {
        setBulkApprovalWebsocketResponse(websocketMessage);
        break;
      }
    }
  };

  const errorHandler = (data: any) => {
    setError('Unable to register the request, Please contact team for technical support');
    services.messageService.showErrorAutoDismissBanner(
      'There was a problem while registering the records. Please contact technical support.',
    );
  };

  services.icrsWebsocketService.addListener(listener);
  services.icrsWebsocketService.addKeyListener(WebSocketApi.ConnectionEvent.ON_ERROR, errorHandler);
}
