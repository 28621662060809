import React from 'react';
import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';

export default function getDescriptionToShowAtDraftStage(registrationRecord: RegistrationRecord) {
  const users = [];
  if (registrationRecord.lastUpdatedByAccountingUser) {
    users.push(`Acct User: ${registrationRecord.lastUpdatedByAccountingUser}`);
  }

  if (registrationRecord.lastUpdatedByTaxUser) {
    users.push(`Tax User: ${registrationRecord.lastUpdatedByTaxUser}`);
  }

  return (
    <span>
      Last Updated By
      {users.length > 0 && <br />}
      {users[0] && users[0]}
      {users[1] && (
        <>
          <br />
          {users[1]}
        </>
      )}
    </span>
  );
}
