import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';

export default function buildRegistrationRecordToUpdate(
  registrationRecord: RegistrationRecord,
  approvalWorkflowStage: string,
  accountingApprover: string | undefined,
  taxApprover: string | undefined,
  cancelledBy: string | undefined,
  deactivatedBy: string | undefined,
  reactivatedBy: string | undefined,
) {
  const registrationRecordToUpdate: RegistrationRecord = {
    baseRegistrationNumber: registrationRecord.baseRegistrationNumber,
    approvalWorkflowStage: approvalWorkflowStage,
    atpCalculationFlag: registrationRecord.atpCalculationFlag,
    currency: registrationRecord.currency,
    effectiveFromDate: registrationRecord.effectiveFromDate,
    effectiveToDate: registrationRecord.effectiveToDate,
    workbookName: registrationRecord.workbookName,
    paymentTerms: registrationRecord.paymentTerms,
    beat: registrationRecord.beat,
    description: registrationRecord.description,
    comments: registrationRecord.comments,
    providerCompanyCode: registrationRecord.providerCompanyCode,
    providerEntityName: registrationRecord.providerEntityName,
    providerJurisdiction: registrationRecord.providerJurisdiction,
    recipientCompanyCode: registrationRecord.recipientCompanyCode,
    recipientEntityName: registrationRecord.recipientEntityName,
    recipientJurisdiction: registrationRecord.recipientJurisdiction,
    providerTaxRegConsideration: registrationRecord.providerTaxRegConsideration,
    recipientTaxRegConsideration: registrationRecord.recipientTaxRegConsideration,
    taxProductId: registrationRecord.taxProductId,
    taxProductName: registrationRecord.taxProductName,
    taxProductCategoryId: registrationRecord.taxProductCategoryId,
    taxProductCategoryName: registrationRecord.taxProductCategoryName,
    exportOfServices: registrationRecord.exportOfServices,
    taxApprover: taxApprover ?? registrationRecord.taxApprover,
    cancelledBy: cancelledBy ?? registrationRecord.cancelledBy,
    deactivatedBy: deactivatedBy ?? registrationRecord.deactivatedBy,
    reactivatedBy: reactivatedBy ?? registrationRecord.reactivatedBy,
    createdBy: registrationRecord.createdBy,
    lastUpdatedByAccountingUser: registrationRecord.lastUpdatedByAccountingUser,
    lastUpdatedByTaxUser: registrationRecord.lastUpdatedByTaxUser,
  };

  return registrationRecordToUpdate;
}

/**
 * Function to create an object with a label and value.
 *
 * @param value - the value in the object
 * @param label - optional label in the object, defaults to the value if not provided
 * @returns object with label and value
 */
export const createLabelValueObject = (value: string, label?: string) => {
  return {
    label: label ?? value,
    value: value,
  };
};

/**
 * Function to create a label-value list from an array of strings.
 *
 * @param items - list of string items to generate label-value objects from
 * @param getLabel - optional function to get the label from each item (defaults to the value if not provided)
 * @returns - list of objects with label and value properties
 */
export const createLabelValueList = (items: string[], getLabel?: (item: string) => string) => {
  return items?.map((item) => createLabelValueObject(item, getLabel?.(item)));
};
