import './CustomAppLayout.scss';
import { Badge, Box, Button, Flashbar, SpaceBetween } from '@amzn/awsui-components-react';
import CONSTANTS from 'src/utils/constants';
import { UserProfile } from 'src/models/UserProfile';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { GlobalAppContext } from './App';
import { GlobalState } from 'src/services/global/GlobalState';
import RoleSwitcher from 'src/components/role-switcher/RoleSwitcher';

const photoUrl = 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=';
const imagePath = '/assets/images/';
function TopNav(props: { userProfile: UserProfile; isViewer: boolean }) {
  const { userProfile, isViewer } = props;

  // A user might have multiple roles(initially he/she was a viewer but later added to indirectTaxUser group).
  // In this case, display indirectTaxUser as role on the site.
  const role = isViewer
    ? CONSTANTS.ACCESS_ROLES.VIEWER
    : userProfile.roles.filter((role) => role !== CONSTANTS.ACCESS_ROLES.VIEWER)[0];
  const stage = CONSTANTS.ENVIRONMENT_VARIABLES.ICRS_UI_STAGE;

  return (
    <header className="topnav">
      <div className="topnav-1">
        <SpaceBetween direction="horizontal" size="xs">
          <img src={imagePath + 'amazon-icrs-logo.png'} />
          {stage !== 'prod' ? <Badge color="blue">{stage}</Badge> : null}
        </SpaceBetween>
      </div>
      <div className="topnav-3">
        {stage === 'prod' ? (
          <ul>
            <li title="Report a bug">
              <Button
                ariaLabel="Report a bug (opens new tab)"
                href="https://t.corp.amazon.com/create/templates/494c6727-dfdb-405a-b832-f9b318b415ba"
                iconAlign="right"
                iconName="external"
                target="_blank"
                variant="primary"
              >
                Report a bug
              </Button>
            </li>
          </ul>
        ) : null}
        <SpaceBetween direction="horizontal" size="xl">
          {userProfile.roles.includes(CONSTANTS.ACCESS_ROLES.ADMIN) && <RoleSwitcher />}
          <div>
            <img className="profile" src={photoUrl + userProfile.alias} />
          </div>
        </SpaceBetween>
        <Box
          data-testid="user-details"
          variant="span"
          color="inherit"
          margin={{ left: 's', right: 's' }}
        >{`${userProfile.givenName} ${userProfile.familyName} (${role})`}</Box>
      </div>
    </header>
  );
}

function MenuIcon(props: { src: string; href: string; className?: string; isViewer?: boolean }) {
  const { src, href, className, isViewer } = props;

  if (isViewer) {
    return null;
  }

  const highlightColor = 'orange500';
  const washoutColor = 'grey600';

  const [imageColor, setImageColor] = React.useState(washoutColor);
  const [refreshKey, setRefreshKey] = React.useState(0);

  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname == href) {
      setImageColor(highlightColor);
    } else {
      const pathParts = pathname.split('/');
      const hrefParts = href.split('/');
      if (pathParts.length > 1 && hrefParts.length > 1 && pathParts[1] == hrefParts[1]) {
        setImageColor(highlightColor);
      } else {
        setImageColor(washoutColor);
      }
    }
  }, [pathname]);

  const highlightImage = () => {
    setImageColor(highlightColor);
  };

  const washoutImage = () => {
    setImageColor(washoutColor);
  };

  const imageSrc = src + '-' + imageColor + '.svg';
  let onClickFn = () => setRefreshKey(refreshKey + 1);

  return (
    <a
      className={className}
      onMouseEnter={highlightImage}
      onMouseLeave={washoutImage}
      onClick={() => {
        onClickFn();
        history.push(href);
      }}
    >
      <img src={imageSrc} />
    </a>
  );
}

function LeftSideNav(props: { isViewer: boolean; isTaxUser: boolean }) {
  const { isViewer, isTaxUser } = props;
  return (
    <aside className="sidenav-left">
      <ul>
        <li title="Search Data">
          <MenuIcon className="searchIcon" href="/" src={imagePath + 'search-records-custom'} />
        </li>
        <li title="Create Data">
          <MenuIcon
            className="createIcon"
            href="/create"
            src={imagePath + 'create-record-custom'}
            isViewer={isViewer || isTaxUser}
          />
        </li>
      </ul>
    </aside>
  );
}

function PageContent(props: { content: any }) {
  const { content } = props;
  return <div className="content">{content}</div>;
}

function PageHeader(props: { globalState: GlobalState }) {
  const { globalState } = props;
  const { bannerItems } = globalState;

  return (
    <div className="custom-flashbar">
      <Flashbar items={bannerItems} />
    </div>
  );
}
export default function CustomAppLayout(props: { children: any; userProfile: UserProfile }) {
  const { children, userProfile } = props;
  const { isViewer, globalState } = React.useContext(GlobalAppContext);
  const isTaxUser =
    globalState.currentUserRoles.includes(CONSTANTS.ACCESS_ROLES.INDIRECT_TAX_USER) ||
    globalState.currentUserRoles.includes(CONSTANTS.ACCESS_ROLES.DIRECT_TAX_USER);

  return (
    <div className="page">
      <TopNav userProfile={userProfile} isViewer={isViewer} />
      <LeftSideNav isViewer={isViewer} isTaxUser={isTaxUser} />
      <PageHeader globalState={globalState} />
      <PageContent content={children} />
    </div>
  );
}
