import './CreateRecord.scss';
import React from 'react';
import {
  Button,
  ColumnLayout,
  DatePicker,
  ExpandableSection,
  Form,
  FormField,
  Header,
  Input,
  Multiselect,
  RadioGroup,
  Select,
  SelectProps,
  SpaceBetween,
  StatusIndicator,
  Textarea,
} from '@amzn/awsui-components-react';
import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';
import { useHistory } from 'react-router-dom';
import CONSTANTS from 'src/utils/constants';
import { customHelpPanel } from 'src/shared/CustomComponents';
import { getTaxProductCategoryNames, getTaxProductNames } from 'src/utils/MDMUtils';
import { useCompanyCodes } from './hooks/useCompanyCodes';
import { useDownloadBulkUploadTemplate } from './hooks/useDownloadBulkUploadTemplate';
import { useCurrencyCodes } from './hooks/useCurrencyCodes';
import { useCategorySubcategoriesData } from './hooks/useCategorySubcategoriesData';
import FileUploadModal from 'src/shared/FileUploadModal';
import {
  convertOptionsToStrings,
  createLabelValueList,
  createLabelValueObject,
  extractCountryFromJurisdiction,
  generateBulkUploadIdentifier,
  generateS3Key,
  getUserRole,
} from './utils';
import ServiceCollection from 'src/services/ServiceCollection';
import { useFramesAccounts } from 'src/shared/hooks/useFramesAccounts';

export interface CreateRecordProps {
  services: ServiceCollection;
  userAlias: string;
  isAccountingUser: boolean;
  isIndirectTaxUser: boolean;
  isDirectTaxUser: boolean;
  isSuperUser: boolean;
}

export default function CreateRecord(props: CreateRecordProps) {
  const { services, userAlias, isAccountingUser, isIndirectTaxUser, isSuperUser } = props;
  const history = useHistory();
  const s3KeyToRegisterRecord = generateS3Key(userAlias);
  const bulkUploadIdentifier = generateBulkUploadIdentifier(userAlias);

  // Registration Data fields
  const [atpCalculationFlag, setATPCalculationFlag] = React.useState('false');
  const [visibleBulkRegistrationModal, setVisibleBulkRegistrationModal] = React.useState(false);
  const [currency, setCurrency] = React.useState('');
  const [loading, setIsLoading] = React.useState(false);
  const [effectiveFromDate, setEffectiveFromDate] = React.useState('');
  const [effectiveToDate, setEffectiveToDate] = React.useState('');
  const [workbookName, setWorkbookName] = React.useState('');
  const [paymentTermsOption, setPaymentTermsOption] = React.useState<{ value: string; label: string }>(
    createLabelValueObject(CONSTANTS.PAYMENT_TERMS[0]),
  );
  const [description, setDescription] = React.useState('');
  const [comments, setComments] = React.useState('');
  const [providerCompanyCode, setProviderCompanyCode] = React.useState('');
  const [providerEntityName, setProviderEntityName] = React.useState('');
  const [providerJurisdiction, setProviderJurisdiction] = React.useState('');
  const [recipientCompanyCode, setRecipientCompanyCode] = React.useState('');
  const [recipientEntityName, setRecipientEntityName] = React.useState('');
  const [recipientJurisdiction, setRecipientJurisdiction] = React.useState('');
  const [providerTaxRegConsideration, setProviderTaxRegConsideration] = React.useState('false');
  const [recipientTaxRegConsideration, setRecipientTaxRegConsideration] = React.useState('false');
  const [taxProductCategoryId, setTaxProductCategoryId] = React.useState('');
  const [taxProductCategoryName, setTaxProductCategoryName] = React.useState('');
  const [taxProductId, setTaxProductId] = React.useState('');
  const [taxProductName, setTaxProductName] = React.useState('');
  const [exportOfServices, setExportOfServices] = React.useState('false');
  const [productNameToIdMapping, setProductNameToIdMapping] = React.useState(new Map());
  const [accountingPoc, setAccountingPoc] = React.useState('');
  const [providerIcBsAccounts, setProviderIcBsAccounts] = React.useState<SelectProps.Option[]>([]);
  const [recipientIcBsAccounts, setRecipientIcBsAccounts] = React.useState<SelectProps.Option[]>([]);
  const [providerPlAccounts, setProviderPlAccounts] = React.useState<SelectProps.Option[]>([]);
  const [recipientPlAccounts, setRecipientPlAccounts] = React.useState<SelectProps.Option[]>([]);

  /**
   *
   * @param s3Key
   * @param userAlias
   */
  const startBulkRegistrationRequest = async (s3Key: string, userAlias: string, bulkUploadIdentifier: string) => {
    const payload = {
      action: CONSTANTS.WEBSOCKET_ROUTES.PROCESS_BULK_REGISTRATION,
      data: {
        s3Key: s3Key,
        bulkUploadIdentifier: bulkUploadIdentifier,
        uploadUser: userAlias,
        userRole: getUserRole(isIndirectTaxUser, isAccountingUser, isSuperUser),
      },
    };
    services.icrsWebsocketService.processBulkRegistrations(payload);
  };

  function onTaxProductCategoryNameChange(taxProductCategoryName: string) {
    setTaxProductCategoryName(taxProductCategoryName);
    setTaxProductCategoryId(categoryNameToIdMapping.get(taxProductCategoryName) ?? '');
    setTaxProductName('');
    setTaxProductId('');
    if (categorySubcategoriesMapping.has(taxProductCategoryName)) {
      setProductNameToIdMapping(
        new Map(
          categorySubcategoriesMapping
            .get(taxProductCategoryName)!
            .map((taxProductSubcategory) => [taxProductSubcategory.taxProductName, taxProductSubcategory.taxProductId]),
        ),
      );
    } else {
      setProductNameToIdMapping(new Map<string, string>());
    }
  }

  function onTaxProductNameChange(taxProductName: string) {
    setTaxProductName(taxProductName);
    setTaxProductId(productNameToIdMapping.get(taxProductName) ?? '');
  }

  function onProviderCompanyCodeChange(companyCode: string) {
    setProviderCompanyCode(companyCode);
    setProviderEntityName('');
    setProviderJurisdiction('');
  }

  function onRecipientCompanyCodeChange(companyCode: string) {
    setRecipientCompanyCode(companyCode);
    setRecipientEntityName('');
    setRecipientJurisdiction('');
  }

  async function onCompanyCodeSelect(companyCode: string) {
    const { companyCodeData, error } = await services.aleDataService.getCompanyCodeData(companyCode);
    if (error) {
      services.messageService.showErrorAutoDismissBanner(error, CONSTANTS.CREATE_RECORD_PAGE_ERROR_DISPLAY_TIME);
      return undefined;
    }
    return companyCodeData;
  }
  async function onProviderCompanyCodeSelect(companyCode: string) {
    setProviderCompanyCode(companyCode);
    const companyCodeData = await onCompanyCodeSelect(companyCode);
    if (companyCodeData !== undefined) {
      setProviderEntityName(companyCodeData.entityName);
      setProviderJurisdiction(extractCountryFromJurisdiction(companyCodeData.jurisdiction));
    }
  }

  async function onRecipientCompanyCodeSelect(companyCode: string) {
    setRecipientCompanyCode(companyCode);
    const companyCodeData = await onCompanyCodeSelect(companyCode);
    if (companyCodeData !== undefined) {
      setRecipientEntityName(companyCodeData.entityName);
      setRecipientJurisdiction(extractCountryFromJurisdiction(companyCodeData.jurisdiction));
    }
  }

  const { companyCodes, fetchCompanyCodesErrorMessage, loadingCompanyCodes, getCompanyCodes } =
    useCompanyCodes(services);

  const { downloadingTemplate, getDownloadBulkUploadTemplate } = useDownloadBulkUploadTemplate(services);

  const { currencyCodes, loadingCurrencyCodes, fetchCurrencyCodesErrorMessage, getCurrencyCodes } =
    useCurrencyCodes(services);

  const {
    categoryNameToIdMapping,
    categorySubcategoriesMapping,
    fetchMDMResponseErrorMessage,
    loadingMDMData,
    getCategorySubcategoriesData,
  } = useCategorySubcategoriesData(services);

  const { framesAccounts, loadingFramesAccounts, fetchFramesAccountsErrorMessage, getFramesAccounts } =
    useFramesAccounts(services);

  async function createRegistrationRecord() {
    const registrationRecordToCreate = getRegistrationRecordToCreate();

    setIsLoading(true);
    const { createdRegistrationRecord, error } =
      await services.registrationDataService.createRegistrationRecord(registrationRecordToCreate);
    setIsLoading(false);

    if (error) {
      services.messageService.showErrorAutoDismissBanner(error, CONSTANTS.CREATE_RECORD_PAGE_ERROR_DISPLAY_TIME);
      return;
    }

    history.push({
      pathname: `/show/${createdRegistrationRecord?.registrationNumber?.replace('.', '*')}`,
      state: createdRegistrationRecord,
    });
  }

  function getRegistrationRecordToCreate() {
    const paymentTerms = paymentTermsOption.value;
    const registrationRecordToCreate: RegistrationRecord = {
      atpCalculationFlag: JSON.parse(atpCalculationFlag),
      paymentTerms,
      providerCompanyCode,
      providerEntityName,
      providerJurisdiction,
      recipientCompanyCode,
      recipientEntityName,
      recipientJurisdiction,
      accountingPoc,
      providerTaxRegConsideration: JSON.parse(providerTaxRegConsideration),
      recipientTaxRegConsideration: JSON.parse(recipientTaxRegConsideration),
      exportOfServices: JSON.parse(exportOfServices),
      providerIcBsAccounts: convertOptionsToStrings(providerIcBsAccounts),
      recipientIcBsAccounts: convertOptionsToStrings(recipientIcBsAccounts),
      providerPlAccounts: convertOptionsToStrings(providerPlAccounts),
      recipientPlAccounts: convertOptionsToStrings(recipientPlAccounts),
      createdBy: userAlias,
      ...(isAccountingUser || isSuperUser
        ? { lastUpdatedByAccountingUser: userAlias }
        : isIndirectTaxUser || isSuperUser
          ? { lastUpdatedByTaxUser: userAlias }
          : {}),
      ...(currency && { currency }),
      ...(effectiveFromDate && { effectiveFromDate }),
      ...(effectiveToDate && { effectiveToDate }),
      ...(workbookName && { workbookName }),
      ...(description && { description }),
      ...(comments && { comments }),
      ...(taxProductName && { taxProductName }),
      ...(taxProductId && { taxProductId }),
      ...(taxProductCategoryName && { taxProductCategoryName }),
      ...(taxProductCategoryId && { taxProductCategoryId }),
    };

    return registrationRecordToCreate;
  }

  return (
    <div>
      {loading ? (
        <StatusIndicator type="loading"> Loading page </StatusIndicator>
      ) : (
        <>
          <FileUploadModal
            services={services}
            visibleFileModal={visibleBulkRegistrationModal}
            onClose={() => setVisibleBulkRegistrationModal(false)}
            modalHeaderName="Bulk Upload"
            uploadButtonName="Upload"
            formDescription="Download bulk upload file template"
            formLabel="Bulk Upload file"
            s3Bucket={CONSTANTS.ENVIRONMENT_VARIABLES.ICRS_UPLOADS_BUCKET}
            s3Key={s3KeyToRegisterRecord}
            onSuccessRedirect={() => {
              startBulkRegistrationRequest(s3KeyToRegisterRecord, userAlias, bulkUploadIdentifier);
              history.push('/create/track', { bulkUploadIdentifier: bulkUploadIdentifier });
            }}
            acceptFileType=".xlsx "
            fileUploadConstraintText="Please upload file only in .xlsx format"
            contentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          <Form
            actions={
              <Button
                data-testid="save-registration-record-button"
                disabled={
                  recipientCompanyCode.trim().length == 0 ||
                  providerCompanyCode.trim().length == 0 ||
                  paymentTermsOption.value.trim().length == 0 ||
                  currency.trim().length == 0 ||
                  effectiveFromDate.trim().length == 0 ||
                  description.trim().length == 0 ||
                  providerIcBsAccounts.length == 0 ||
                  providerPlAccounts.length == 0 ||
                  recipientIcBsAccounts.length == 0 ||
                  recipientPlAccounts.length == 0 ||
                  accountingPoc.trim().length == 0
                }
                variant="primary"
                onClick={createRegistrationRecord}
              >
                Save
              </Button>
            }
            header={
              <Header
                variant="h1"
                actions={
                  <SpaceBetween size="xs" direction="horizontal">
                    <Button
                      iconName="download"
                      loading={downloadingTemplate}
                      variant="link"
                      onClick={getDownloadBulkUploadTemplate}
                      disabled={downloadingTemplate}
                      data-testid="download-template-button"
                      data-class="download-template-button"
                    >
                      {downloadingTemplate ? 'Downloading Template...' : 'Get Bulk Upload Template'}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => setVisibleBulkRegistrationModal(true)}
                      data-testid="bulk-registration-button"
                      data-class="bulk-registration-button"
                    >
                      Create Bulk Registration Request
                    </Button>
                  </SpaceBetween>
                }
              >
                Create Registration Record
              </Header>
            }
          >
            <div className="create-registration-record">
              {(isAccountingUser || isSuperUser) && (
                <ExpandableSection headerText="Registration Details" variant="container" defaultExpanded>
                  <ColumnLayout columns={2}>
                    <FormField label="ATP Calculation" info={customHelpPanel(CONSTANTS.FIELDS_INFO.ATP_CALCULATION)}>
                      <RadioGroup
                        onChange={({ detail }) => setATPCalculationFlag(detail.value)}
                        value={atpCalculationFlag}
                        items={[
                          { value: 'true', label: 'Yes' },
                          { value: 'false', label: 'No' },
                        ]}
                      />
                    </FormField>
                    <FormField
                      label="Entered Currency*"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.CURRENCY)}
                      constraintText={currency.trim().length == 0 ? 'required' : ''}
                    >
                      <Select
                        data-class="input-text"
                        data-testid="currency-input"
                        selectedOption={currency ? { label: currency, value: currency } : null}
                        options={createLabelValueList(currencyCodes)}
                        onFocus={() => getCurrencyCodes()}
                        loadingText="Loading currency codes"
                        statusType={
                          loadingCurrencyCodes ? 'loading' : fetchCurrencyCodesErrorMessage ? 'error' : 'finished'
                        }
                        errorText={fetchCurrencyCodesErrorMessage}
                        placeholder="Select a currency"
                        onChange={({ detail }) => {
                          setCurrency(detail.selectedOption.value ?? '');
                        }}
                        filteringType="auto"
                        empty="No currencies found"
                      />
                    </FormField>
                    <FormField
                      label="Provider Company Code*"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.PROVIDER_COMPANY_CODE)}
                      constraintText={providerCompanyCode.trim().length == 0 ? 'required' : ''}
                    >
                      <Select
                        data-class="input-text"
                        data-testid="provider-company-code-input"
                        selectedOption={
                          providerCompanyCode ? { label: providerCompanyCode, value: providerCompanyCode } : null
                        }
                        options={createLabelValueList(companyCodes)}
                        onFocus={() => getCompanyCodes()}
                        loadingText="Loading company codes"
                        statusType={
                          loadingCompanyCodes ? 'loading' : fetchCompanyCodesErrorMessage ? 'error' : 'finished'
                        }
                        errorText={fetchCompanyCodesErrorMessage}
                        onChange={({ detail }) => onProviderCompanyCodeSelect(detail.selectedOption?.value ?? '')}
                        filteringType="auto"
                        empty="No company codes found"
                      />
                    </FormField>
                    <FormField
                      label="Recipient Company Code*"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.RECIPIENT_COMPANY_CODE)}
                      constraintText={recipientCompanyCode.trim().length == 0 ? 'required' : ''}
                    >
                      <Select
                        data-class="input-text"
                        data-testid="recipient-company-code-input"
                        selectedOption={
                          recipientCompanyCode ? { label: recipientCompanyCode, value: recipientCompanyCode } : null
                        }
                        options={createLabelValueList(companyCodes)}
                        onFocus={() => getCompanyCodes()}
                        loadingText="Loading company codes"
                        statusType={
                          loadingCompanyCodes ? 'loading' : fetchCompanyCodesErrorMessage ? 'error' : 'finished'
                        }
                        errorText={fetchCompanyCodesErrorMessage}
                        onChange={({ detail }) => onRecipientCompanyCodeSelect(detail.selectedOption?.value ?? '')}
                        filteringType="auto"
                        empty="No company codes found"
                      />
                    </FormField>
                    <FormField label="Provider Entity Name">
                      <Input
                        data-class="input-text"
                        data-testid="provider-entity-name-input"
                        value={providerEntityName}
                        onChange={({ detail }) => setProviderEntityName(detail.value)}
                        readOnly={true}
                      />
                    </FormField>
                    <FormField label="Recipient Entity Name">
                      <Input
                        data-class="input-text"
                        data-testid="recipient-entity-name-input"
                        value={recipientEntityName}
                        onChange={({ detail }) => setRecipientEntityName(detail.value)}
                        readOnly={true}
                      />
                    </FormField>
                    <FormField label="Provider Jurisdiction">
                      <Input
                        data-class="input-text"
                        data-testid="provider-jurisdiction-input"
                        value={providerJurisdiction}
                        onChange={({ detail }) => setProviderJurisdiction(detail.value)}
                        readOnly={true}
                      />
                    </FormField>
                    <FormField label="Recipient Jurisdiction">
                      <Input
                        data-class="input-text"
                        data-testid="recipient-jurisdiction-input"
                        value={recipientJurisdiction}
                        onChange={({ detail }) => setRecipientJurisdiction(detail.value)}
                        readOnly={true}
                      />
                    </FormField>
                    <FormField
                      label="Effective Start Date*"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.EFFECTIVE_FROM_DATE)}
                      constraintText="Use YYYY/MM/DD format."
                    >
                      <DatePicker
                        data-testid="effective-from-date-input"
                        value={effectiveFromDate}
                        nextMonthAriaLabel="Next month"
                        placeholder="YYYY/MM/DD"
                        previousMonthAriaLabel="Previous month"
                        todayAriaLabel="Today"
                        onChange={({ detail }) => setEffectiveFromDate(detail.value)}
                      />
                    </FormField>
                    <FormField
                      label="Effective End Date"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.EFFECTIVE_TO_DATE)}
                      constraintText="Use YYYY/MM/DD format."
                    >
                      <DatePicker
                        data-testid="effective-to-date-input"
                        value={effectiveToDate}
                        nextMonthAriaLabel="Next month"
                        placeholder="YYYY/MM/DD"
                        previousMonthAriaLabel="Previous month"
                        todayAriaLabel="Today"
                        onChange={({ detail }) => setEffectiveToDate(detail.value)}
                      />
                    </FormField>
                    <FormField label="Workbook Name" info={customHelpPanel(CONSTANTS.FIELDS_INFO.WORKBOOK_NAME)}>
                      <Input
                        data-class="input-text"
                        data-testid="workbook-name-input"
                        value={workbookName}
                        onChange={({ detail }) => setWorkbookName(detail.value)}
                      />
                    </FormField>
                    <FormField
                      label="Payment Terms*"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.PAYMENT_TERMS)}
                      constraintText={paymentTermsOption.value.trim().length == 0 ? 'required' : ''}
                    >
                      <Select
                        data-class="input-text"
                        data-testid="payment-terms-input"
                        selectedOption={paymentTermsOption}
                        options={createLabelValueList(CONSTANTS.PAYMENT_TERMS)}
                        onChange={({ detail }) =>
                          setPaymentTermsOption(createLabelValueObject(detail.selectedOption?.value ?? ''))
                        }
                        filteringType="auto"
                        empty="No payment terms found"
                      />
                    </FormField>
                    <FormField
                      label="Description of Transaction*"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.DESCRIPTION)}
                    >
                      <Textarea
                        data-class="input-text"
                        data-testid="description-input"
                        value={description}
                        onChange={({ detail }) => setDescription(detail.value)}
                      />
                    </FormField>
                    <FormField label="Comments" info={customHelpPanel(CONSTANTS.FIELDS_INFO.COMMENTS)}>
                      <Textarea
                        data-class="input-text"
                        data-testid="comments-input"
                        value={comments}
                        onChange={({ detail }) => setComments(detail.value)}
                      />
                    </FormField>
                    <FormField
                      label="Provider IC BS Accounts*"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.PROVIDER_GL_ACCOUNTS)}
                    >
                      <Multiselect
                        data-class="input-text"
                        data-testid="provider-ic-bs-accounts-input"
                        selectedOptions={providerIcBsAccounts}
                        statusType={
                          loadingFramesAccounts ? 'loading' : fetchFramesAccountsErrorMessage ? 'error' : 'finished'
                        }
                        onChange={({ detail }) =>
                          setProviderIcBsAccounts([...detail.selectedOptions] as SelectProps.Option[])
                        }
                        onLoadItems={({ detail }) => {
                          getFramesAccounts(detail.filteringText);
                        }}
                        onFocus={() => getFramesAccounts()}
                        options={createLabelValueList(framesAccounts)}
                        loadingText="Loading FRAMES Accounts"
                        errorText={fetchFramesAccountsErrorMessage}
                        placeholder="Choose options"
                        filteringType="manual"
                        filteringPlaceholder={'Enter at least 2 characters to see options'}
                      />
                    </FormField>
                    <FormField
                      label="Recipient IC BS Accounts*"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.RECIPIENT_GL_ACCOUNTS)}
                    >
                      <Multiselect
                        data-class="input-text"
                        data-testid="recipient-ic-bs-accounts-input"
                        selectedOptions={recipientIcBsAccounts}
                        statusType={
                          loadingFramesAccounts ? 'loading' : fetchFramesAccountsErrorMessage ? 'error' : 'finished'
                        }
                        onChange={({ detail }) =>
                          setRecipientIcBsAccounts([...detail.selectedOptions] as SelectProps.Option[])
                        }
                        onLoadItems={({ detail }) => {
                          getFramesAccounts(detail.filteringText);
                        }}
                        onFocus={() => getFramesAccounts()}
                        options={createLabelValueList(framesAccounts)}
                        loadingText="Loading FRAMES Accounts"
                        errorText={fetchFramesAccountsErrorMessage}
                        placeholder="Choose options"
                        filteringType="manual"
                        filteringPlaceholder={'Enter at least 2 characters to see options'}
                      />
                    </FormField>
                    <FormField label="Provider P&L Accounts*">
                      <Multiselect
                        data-class="input-text"
                        data-testid="provider-pl-accounts-input"
                        selectedOptions={providerPlAccounts}
                        statusType={
                          loadingFramesAccounts ? 'loading' : fetchFramesAccountsErrorMessage ? 'error' : 'finished'
                        }
                        onChange={({ detail }) =>
                          setProviderPlAccounts([...detail.selectedOptions] as SelectProps.Option[])
                        }
                        onLoadItems={({ detail }) => {
                          getFramesAccounts(detail.filteringText);
                        }}
                        onFocus={() => getFramesAccounts()}
                        options={createLabelValueList(framesAccounts)}
                        loadingText="Loading FRAMES Accounts"
                        errorText={fetchFramesAccountsErrorMessage}
                        placeholder="Choose options"
                        filteringType="manual"
                        filteringPlaceholder={'Enter at least 2 characters to see options'}
                      />
                    </FormField>
                    <FormField label="Recipient P&L Accounts*">
                      <Multiselect
                        data-class="input-text"
                        data-testid="recipient-pl-accounts-input"
                        selectedOptions={recipientPlAccounts}
                        statusType={
                          loadingFramesAccounts ? 'loading' : fetchFramesAccountsErrorMessage ? 'error' : 'finished'
                        }
                        onChange={({ detail }) =>
                          setRecipientPlAccounts([...detail.selectedOptions] as SelectProps.Option[])
                        }
                        onLoadItems={({ detail }) => {
                          getFramesAccounts(detail.filteringText);
                        }}
                        onFocus={() => getFramesAccounts()}
                        options={createLabelValueList(framesAccounts)}
                        loadingText="Loading FRAMES Accounts"
                        errorText={fetchFramesAccountsErrorMessage}
                        placeholder="Choose options"
                        filteringType="manual"
                        filteringPlaceholder={'Enter at least 2 characters to see options'}
                        virtualScroll
                      />
                    </FormField>
                    <FormField label="Accounting Point of Contact Alias*">
                      <Input
                        data-class="input-text"
                        data-testid="accounting-poc-alias-input"
                        value={accountingPoc}
                        onChange={({ detail }) => setAccountingPoc(detail.value)}
                      />
                    </FormField>
                  </ColumnLayout>
                </ExpandableSection>
              )}
              {isSuperUser && (
                <ExpandableSection headerText="Tax Product Details" variant="container" defaultExpanded>
                  <ColumnLayout columns={2}>
                    <FormField label="Provider Tax Reg Consideration">
                      <RadioGroup
                        onChange={({ detail }) => setProviderTaxRegConsideration(detail.value)}
                        value={providerTaxRegConsideration}
                        items={[
                          { value: 'true', label: 'True' },
                          { value: 'false', label: 'False' },
                        ]}
                      />
                    </FormField>
                    <FormField label="Recipient Tax Reg Consideration">
                      <RadioGroup
                        onChange={({ detail }) => setRecipientTaxRegConsideration(detail.value)}
                        value={recipientTaxRegConsideration}
                        items={[
                          { value: 'true', label: 'True' },
                          { value: 'false', label: 'False' },
                        ]}
                      />
                    </FormField>
                    <FormField label="Tax Product Category Name*">
                      <Select
                        data-class="input-text"
                        data-testid="tax-product-category-name-input"
                        selectedOption={
                          taxProductCategoryName
                            ? { label: taxProductCategoryName, value: taxProductCategoryName }
                            : null
                        }
                        options={getTaxProductCategoryNames(categoryNameToIdMapping)}
                        onFocus={getCategorySubcategoriesData}
                        loadingText="Loading tax product category names"
                        statusType={loadingMDMData ? 'loading' : fetchMDMResponseErrorMessage ? 'error' : 'finished'}
                        errorText={fetchMDMResponseErrorMessage}
                        onChange={({ detail }) => onTaxProductCategoryNameChange(detail.selectedOption?.value ?? '')}
                        filteringType="auto"
                        empty="No tax product categories found"
                      />
                    </FormField>
                    <FormField label="Tax Product Category Id">
                      <Input
                        data-class="input-text"
                        data-testid="tax-product-category-id-input"
                        value={taxProductCategoryId}
                        onChange={({ detail }) => setTaxProductCategoryId(detail.value)}
                        readOnly={true}
                      />
                    </FormField>
                    <FormField label="Tax Product Sub Category Name*">
                      <Select
                        data-class="input-text"
                        data-testid="tax-product-name-input"
                        selectedOption={taxProductName ? { label: taxProductName, value: taxProductName } : null}
                        options={getTaxProductNames(productNameToIdMapping)}
                        onChange={({ detail }) => onTaxProductNameChange(detail.selectedOption?.value ?? '')}
                        filteringType="auto"
                        empty="No tax products found"
                      />
                    </FormField>
                    <FormField label="Tax Product Sub Category Id">
                      <Input
                        data-class="input-text"
                        data-testid="tax-product-id-input"
                        value={taxProductId}
                        onChange={({ detail }) => setTaxProductId(detail.value)}
                        readOnly={true}
                      />
                    </FormField>
                    <FormField
                      label="Export of Services"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.EXPORT_OF_SERVICES)}
                    >
                      <RadioGroup
                        onChange={({ detail }) => setExportOfServices(detail.value)}
                        value={exportOfServices}
                        items={[
                          { value: 'true', label: 'True' },
                          { value: 'false', label: 'False' },
                        ]}
                      />
                    </FormField>
                  </ColumnLayout>
                </ExpandableSection>
              )}
            </div>
          </Form>
        </>
      )}
    </div>
  );
}
