import { useState, useCallback, useContext } from 'react';
import { GlobalAppContext } from 'src/components/App';
import ServiceCollection from 'src/services/ServiceCollection';
import { fetchAccounts } from 'src/utils/FramesUtils';

export const useFramesAccounts = (services: ServiceCollection) => {
  const { framesAccounts, setFramesAccounts } = useContext(GlobalAppContext);
  const [loadingFramesAccounts, setLoadingFramesAccounts] = useState(false);
  const [fetchFramesAccountsErrorMessage, setFetchFramesAccountsErrorMessage] = useState('');

  const getFramesAccounts = useCallback(
    async (accountNumPrefix: string = '') => {
      // If frames accounts are already loaded from React context, just return.
      if (accountNumPrefix.length < 2) {
        return;
      }
      fetchAccounts(
        services.framesService,
        setFramesAccounts,
        setLoadingFramesAccounts,
        setFetchFramesAccountsErrorMessage,
        accountNumPrefix!,
      );
    },
    [framesAccounts],
  );

  return {
    framesAccounts,
    loadingFramesAccounts,
    fetchFramesAccountsErrorMessage,
    getFramesAccounts,
  };
};
