import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

export interface RegistrationRecord {
  registrationNumber?: string;
  baseRegistrationNumber?: string;
  approvalWorkflowStage?: string;
  approvalId?: string;
  approvalRequester?: string;
  approvalWorkflowRev?: number;
  latestRev?: boolean;
  lastApprovedRev?: boolean;
  atpCalculationFlag: boolean;
  currency?: string;
  effectiveFromDate?: string;
  effectiveToDate?: string;
  workbookName?: string;
  beat?: string;
  description?: string;
  comments?: string;
  paymentTerms?: string;
  providerCompanyCode: string;
  providerEntityName?: string;
  providerJurisdiction?: string;
  recipientCompanyCode: string;
  recipientEntityName?: string;
  recipientJurisdiction?: string;
  providerTaxRegConsideration: boolean;
  recipientTaxRegConsideration: boolean;
  taxProductId?: string;
  taxProductName?: string;
  taxProductCategoryId?: string;
  taxProductCategoryName?: string;
  exportOfServices: boolean;
  taxApprover?: string;
  cancelledBy?: string;
  deactivatedBy?: string;
  reactivatedBy?: string;
  createdBy: string;
  lastUpdatedByAccountingUser?: string;
  lastUpdatedByTaxUser?: string;
  createDateTime?: string;
  lastUpdatedDateTime?: string;
  providerIcBsAccounts?: string[];
  providerPlAccounts?: string[];
  recipientIcBsAccounts?: string[];
  recipientPlAccounts?: string[];
  accountingPoc?: string;
}

export function areRecordsEqual(
  firstRegistrationRecord: RegistrationRecord,
  secondRegistrationRecord: RegistrationRecord,
) {
  const fieldsToExclude = new Set<keyof RegistrationRecord>([
    'registrationNumber',
    'approvalWorkflowStage',
    'approvalWorkflowRev',
    'approvalId',
    'approvalRequester',
    'taxApprover',
    'latestRev',
    'lastApprovedRev',
    'lastUpdatedByAccountingUser',
    'lastUpdatedByTaxUser',
    'createDateTime',
    'lastUpdatedDateTime',
  ]);

  const normalizeAndFilter = (record: Record<string, any>): Record<string, any> => {
    return Object.entries(record).reduce(
      (normalized, [key, value]) => {
        if (fieldsToExclude.has(key as keyof RegistrationRecord)) {
          return normalized;
        }

        if (value === null || value === '' || value === undefined) {
          return normalized;
        } else if (Array.isArray(value)) {
          normalized[key] = value.slice().sort();
        } else if (typeof value === 'object') {
          normalized[key] = normalizeAndFilter(value);
        } else {
          normalized[key] = value;
        }

        return normalized;
      },
      {} as Record<string, any>,
    );
  };

  const oldRecordNormalized = normalizeAndFilter(firstRegistrationRecord);
  const newRecordNormalized = normalizeAndFilter(secondRegistrationRecord);

  return isEqual(oldRecordNormalized, newRecordNormalized);
}
