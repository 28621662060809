import React from 'react';

// Utility functions
const save = (storageKey: string, value: string) => {
  localStorage.setItem(storageKey, JSON.stringify(value));
};

const load = (storageKey: string) => {
  const value = localStorage.getItem(storageKey);
  return value ? JSON.parse(value) : null;
};

/**
 * For accessing local storage
 * @return username from local storage
 */
const useLocalStorage = {
  getUsernameFromLocalStorage(): string {
    return localStorage.getItem('alias') as string;
  },
  getStorageKeyFromLocalStorage(storageKey: string, defaultValue: any): [any, (newValue: any) => void] {
    const [value, setValue] = React.useState(() => load(storageKey) ?? defaultValue);

    function handleValueChange(newValue: any) {
      setValue(newValue);
      save(storageKey, newValue);
    }
    return [value, handleValueChange];
  },
};

export default useLocalStorage;
