import CONSTANTS from 'src/utils/constants';

/**
 * Creates a full approval link URL by concatenating the base approvals URL with the given approval ID.
 *
 * This function is used to generate clickable links to specific approval pages in the Amazon Approvals.
 *
 * @param approvalId - The unique identifier for the approval.
 * @returns A complete URL string to the approval page for the given approval ID.
 *
 */
export const createApprovalLinkForStage = (approvalId: string): string =>
  `${CONSTANTS.ENVIRONMENT_VARIABLES.APPROVALS_URL_PREFIX}${approvalId}`;
