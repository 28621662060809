import React, { useState, useCallback } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';

export const useDownloadRegistrationRecords = (services: ServiceCollection) => {
  const [downloadingRecords, setDownloadingRecords] = React.useState(false);
  const [downloadRegistrationRecordsErrorMessage, setDownloadRegistrationRecordsErrorMessage] = useState('');

  const getRegistrationRecords = useCallback(
    async (
      providerCompanyCode: string | undefined,
      recipientCompanyCode: string | undefined,
      approvalWorkflowStage: string | undefined,
    ) => {
      if (approvalWorkflowStage === 'None') {
        approvalWorkflowStage = undefined;
      }
      setDownloadingRecords(true);
      const { downloadURL, error } = await services.bulkUploadService.downloadRegistrationRecords(
        providerCompanyCode,
        recipientCompanyCode,
        approvalWorkflowStage,
      );
      setDownloadingRecords(false);
      if (error) {
        setDownloadRegistrationRecordsErrorMessage(error);
        setDownloadingRecords(false);
        return;
      }
      window.open(downloadURL);
    },
    [],
  );

  return {
    downloadingRecords,
    downloadDraftRegistrationRecordsErrorMessage: downloadRegistrationRecordsErrorMessage,
    getRegistrationRecords,
  };
};
