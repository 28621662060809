import apiService from 'src/services/ApiCallService';
import ErrorUtils from 'src/utils/ErrorUtils';
import { GetFramesAccountsResponse } from 'src/models/GetFramesAccountsResponse';

export default class FramesService {
  /**
   * Fetches accounts from FRAMES
   * @returns An object containing the accounts or error message if operation fails.
   */
  async getAccounts(accountNumPrefix: string) {
    let accounts: string[] = [];
    let error: string | undefined = undefined;
    try {
      const response = await apiService.getFramesAccounts(accountNumPrefix);
      const data: GetFramesAccountsResponse = (await response.json()) as GetFramesAccountsResponse;

      if (data.accounts) {
        accounts = [...accounts, ...data.accounts];
      }
    } catch (ex) {
      error = ErrorUtils.getMessage(ex);
    }

    return {
      accounts,
      error,
    };
  }
}
