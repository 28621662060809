import React from 'react';

import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';
import CONSTANTS from 'src/utils/constants';
import { Steps } from 'antd';
import { createApprovalLinkForStage } from 'src/utils/commonComponentUtils';
import { Link } from '@amzn/awsui-components-react';
import getDescriptionToShowAtDraftStage from './utils';

interface ApprovalWorkflowProps {
  registrationRecord: RegistrationRecord;
  isAccountingUser: boolean;
}

export default function ApprovalWorkflow(props: ApprovalWorkflowProps) {
  const { isAccountingUser, registrationRecord } = props;
  interface StepItem {
    title: string;
    description: string | React.ReactNode;
    status: 'process' | 'finish' | 'wait' | 'error' | undefined;
    disabled: boolean;
  }

  let currentStage: number;

  let stages = CONSTANTS.APPROVAL_WORKFLOW_STAGES;

  const stepItems: StepItem[] = [
    { title: stages.DRAFT, description: '', status: undefined, disabled: true },
    { title: stages.IN_TAX_APPROVAL, description: '', status: undefined, disabled: true },
    { title: stages.ACTIVE, description: '', status: undefined, disabled: true },
    { title: stages.AMENDED, description: '', status: undefined, disabled: true },
    { title: stages.INACTIVE, description: '', status: undefined, disabled: true },
    { title: stages.TERMINATED, description: '', status: undefined, disabled: true },
  ];

  switch (registrationRecord.approvalWorkflowStage) {
    case stages.DRAFT: {
      currentStage = 0;
      stepItems[0].description = getDescriptionToShowAtDraftStage(registrationRecord);
      break;
    }
    case stages.IN_TAX_APPROVAL: {
      currentStage = 1;
      stepItems[0].description = getDescriptionToShowAtDraftStage(registrationRecord);
      stepItems[1].description = isAccountingUser ? (
        <></>
      ) : (
        <span>
          Requested By: {registrationRecord.approvalRequester!}
          <br />
          <Link external href={createApprovalLinkForStage(registrationRecord.approvalId!)} variant="primary">
            View Approval
          </Link>
        </span>
      );
      break;
    }
    case stages.TAX_APPROVAL_REJECTED: {
      currentStage = 1;
      stepItems[1].description = isAccountingUser ? (
        <span> Rejected By: {registrationRecord.taxApprover!} </span>
      ) : (
        <span>
          Rejected By: {registrationRecord.taxApprover!}
          <br />
          <Link external href={createApprovalLinkForStage(registrationRecord.approvalId!)} variant="primary">
            View Approval
          </Link>
        </span>
      );
      stepItems[1].status = 'error';
      break;
    }
    case stages.ACTIVE: {
      currentStage = 2;
      stepItems[0].description = getDescriptionToShowAtDraftStage(registrationRecord);
      stepItems[1].description = 'Approved By: ' + registrationRecord.taxApprover!;
      if (registrationRecord.reactivatedBy) {
        stepItems[2].description = 'Reactivated By: ' + registrationRecord.reactivatedBy;
      }
      break;
    }
    case stages.AMENDED: {
      currentStage = 3;
      stepItems[0].description = getDescriptionToShowAtDraftStage(registrationRecord);
      stepItems[1].description = 'Approved By: ' + registrationRecord.taxApprover!;
      stepItems[2].status = 'finish';
      break;
    }
    case stages.INACTIVE: {
      currentStage = 4;
      stepItems[0].description = getDescriptionToShowAtDraftStage(registrationRecord);
      stepItems[1].description = 'Approved By: ' + registrationRecord.taxApprover!;
      stepItems[4].description = 'Deactivated By: ' + registrationRecord.deactivatedBy!;
      stepItems[2].status = 'wait';
      stepItems[3].status = 'wait';
      break;
    }
    case stages.TERMINATED: {
      currentStage = 5;
      stepItems[0].description = getDescriptionToShowAtDraftStage(registrationRecord);
      stepItems[1].description = registrationRecord.taxApprover
        ? 'Approved By: ' + registrationRecord.taxApprover!
        : '';
      stepItems[5].description = 'By: ' + registrationRecord.cancelledBy!;
      stepItems[3].status = 'wait';
      stepItems[4].status = 'wait';
      stepItems[1].status = registrationRecord.taxApprover ? 'finish' : 'wait';
      break;
    }
    default: {
      break;
    }
  }

  return (
    <Steps
      current={currentStage!}
      direction="horizontal"
      labelPlacement="horizontal"
      type="default"
      items={stepItems}
      size="default"
    ></Steps>
  );
}
