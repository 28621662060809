import RegistrationDataService from 'src/services/RegistrationDataService';
import MessageService from 'src/services/MessageService';
import MDMDataService from 'src/services/MDMDataService';
import ALEDataService from 'src/services/ALEDataService';
import CurrencyCodesService from 'src/services/CurrencyCodesService';
import FramesService from 'src/services/FramesService';
import BulkUploadService from './BulkUploadService';
import ICRSWebsocketApiClient from './websockets/ICRSWebsocketApiClient';
import ApprovalService from 'src/services/ApprovalService';

/**
 * Use this class to add services that you want to be available to a context
 */
export default class ServiceCollection {
  registrationDataService: RegistrationDataService;
  messageService: MessageService;
  mdmDataService: MDMDataService;
  aleDataService: ALEDataService;
  icrsWebsocketService: ICRSWebsocketApiClient;
  currencyCodesService: CurrencyCodesService;
  bulkUploadService: BulkUploadService;
  framesService: FramesService;
  approvalService: ApprovalService;

  constructor() {
    this.registrationDataService = new RegistrationDataService();
    this.messageService = new MessageService();
    this.mdmDataService = new MDMDataService();
    this.aleDataService = new ALEDataService();
    this.icrsWebsocketService = new ICRSWebsocketApiClient();
    this.currencyCodesService = new CurrencyCodesService();
    this.bulkUploadService = new BulkUploadService();
    this.framesService = new FramesService();
    this.approvalService = new ApprovalService();
  }
}
