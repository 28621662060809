import React from 'react';
import { Modal, Box, SpaceBetween, Button, StatusIndicator, Link } from '@amzn/awsui-components-react';
import { ApprovalResponse } from 'src/models/ApprovalResponse';
import Table from '@amzn/awsui-components-react/polaris/table';
import { createApprovalLinkForStage } from 'src/utils/commonComponentUtils';

interface ApprovalResultModalProps {
  approvalResponse: ApprovalResponse | undefined;
  onDismiss: () => void;
}

export default function ApprovalResultModal(props: ApprovalResultModalProps) {
  const { approvalResponse, onDismiss } = props;

  if (!approvalResponse) {
    return null;
  }

  const columnDefinitions = [
    {
      id: 'registrationNumber',
      header: 'Registration Number',
      cell: (item: any) => item.registrationNumber,
    },
    {
      id: 'approvalId',
      header: 'Approval Link',
      cell: (item: any) => {
        if (!item.isSuccess) {
          return '';
        }
        return (
          <Link external href={createApprovalLinkForStage(item.approvalId)} variant="primary">
            Click here
          </Link>
        );
      },
    },
    {
      id: 'isSuccess',
      header: 'Submitted',
      cell: (item: any) =>
        item.isSuccess ? (
          <StatusIndicator>Success</StatusIndicator>
        ) : (
          <StatusIndicator type="error">Error</StatusIndicator>
        ),
    },
    {
      id: 'errorReason',
      header: 'Error Reason',
      cell: (item: any) => item.errorReason || '',
    },
  ];

  return (
    <Modal
      onDismiss={onDismiss}
      visible={true}
      header="Approval Result"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={onDismiss}>Close</Button>
          </SpaceBetween>
        </Box>
      }
      data-testid="approval-response-modal"
    >
      <Table
        columnDefinitions={columnDefinitions}
        items={approvalResponse.approvalResults}
        sortingDisabled
        variant="embedded"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No results</b>
            <Box padding={{ bottom: 's' }} variant="p" color="inherit">
              No results found.
            </Box>
          </Box>
        }
        wrapLines={true}
      />
    </Modal>
  );
}
