import React, { useContext } from 'react';
import CONSTANTS from 'src/utils/constants';
import ICRSAction from 'src/models/ICRSAction';
import { GlobalAppContext } from 'src/components/App';
import { ButtonDropdown } from '@amzn/awsui-components-react';

const RoleSwitcher = () => {
  const { globalState, globalDispatch } = useContext(GlobalAppContext);
  const currentRole = globalState.currentUserRoles[0] || CONSTANTS.ACCESS_ROLES.VIEWER;

  // @ts-ignore
  const handleRoleChange = ({ detail }) => {
    const action = new ICRSAction('UPDATE_CURRENT_USER_ROLES').withPayload([detail.id]);
    globalDispatch(action);
  };

  return (
    <div className="header-role-switcher">
      <ButtonDropdown
        variant="normal"
        onItemClick={handleRoleChange}
        items={Object.values(CONSTANTS.ACCESS_ROLES).map((role) => ({
          id: role,
          text: role,
        }))}
      >
        {currentRole}
      </ButtonDropdown>
    </div>
  );
};

export default RoleSwitcher;
