import React, { useEffect } from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { Box, Button, FileUpload, FormField, SpaceBetween } from '@amzn/awsui-components-react';
import { useUploadFileToS3 } from '../services/UploadFileToS3';
import ServiceCollection from 'src/services/ServiceCollection';
import TrackBulkCreateRegistrationRequest from 'src/components/data-registration/track-bulk-registration-request/TrackBulkCreateRegistrationRequest';

export default function FileUploadModal(props: {
  services: ServiceCollection;
  visibleFileModal: boolean;
  onClose: any;
  modalHeaderName: string;
  uploadButtonName: string;
  formLabel: string;
  onSuccessRedirect: any;
  formDescription: string;
  fileUploadConstraintText: string;
  acceptFileType: string;
  s3Bucket: string;
  s3Key: string;
  contentType: string;
}) {
  const [requestFile, setRequestFile] = React.useState<File[]>([]);
  const [uploadButtonDisable, setUploadButtonDisable] = React.useState(true);
  const { isFileUploadingToS3, s3FileUploadProgress, success, s3UploadError, uploadFile } = useUploadFileToS3(
    props.s3Bucket,
    props.s3Key,
    requestFile[0],
    props.contentType,
  );

  const onSubmit = () => {
    uploadFile();
  };

  useEffect(() => {
    if (success) {
      props.onSuccessRedirect();
    }
  }, [success]);

  useEffect(() => {
    if (s3UploadError) {
      props.services.messageService.showErrorAutoDismissBanner(s3UploadError);
    }
  }, [s3UploadError]);
  return (
    <Modal
      onDismiss={props.onClose}
      visible={props.visibleFileModal}
      header={props.modalHeaderName}
      data-testid="file-upload-modal"
      data-class="file-upload-modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={props.onClose}
              data-testid="file-upload-cancel-button"
              data-class="file-upload-cancel-button"
            >
              {' '}
              Cancel{' '}
            </Button>
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={uploadButtonDisable}
              loading={isFileUploadingToS3}
              data-testid="file-upload-submit-button"
              data-class="file-upload-submit-button"
            >
              {' '}
              {props.uploadButtonName}{' '}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <FormField
        label={props.formLabel}
        description={props.formDescription}
        data-testid="file-upload-form-field"
        data-class="file-upload-form-field"
      >
        <FileUpload
          onChange={({ detail }) => {
            setRequestFile(detail.value);
            if (detail.value.length !== 0) {
              setUploadButtonDisable(false);
            } else {
              setUploadButtonDisable(true);
            }
          }}
          value={requestFile}
          accept={props.acceptFileType}
          i18nStrings={{
            uploadButtonText: () => 'Choose file',
            dropzoneText: () => 'Drop file to upload',
            removeFileAriaLabel: () => 'Remove file',
            limitShowFewer: 'Show fewer files',
            limitShowMore: 'Show more files',
            errorIconAriaLabel: 'Error',
            warningIconAriaLabel: 'Warning',
          }}
          data-testid="file-upload-container"
          data-class="file-upload-container"
          showFileLastModified
          showFileSize
          showFileThumbnail
          tokenLimit={3}
          constraintText={props.fileUploadConstraintText}
        />
      </FormField>
    </Modal>
  );
}
