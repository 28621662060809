import apiService from 'src/services/ApiCallService';
import ErrorUtils from 'src/utils/ErrorUtils';
import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';
import { SaveRegistrationRecordResponse } from 'src/models/data-registration/SaveRegistrationRecordResponse';
import { GetRegistrationRecordsResponse } from 'src/models/data-registration/GetRegistrationRecordsResponse';
import { DiscardWorkflowRevisionResponse } from 'src/models/data-registration/DiscardWorkflowRevisionResponse';
import { GetRegistrationRecordResponse } from 'src/models/data-registration/GetRegistrationRecordResponse';
import { GetRegistrationRecordsByBulkUploadIdentifierResponse } from 'src/models/data-registration/GetRegistrationRecordsByBulkUploadIdentifierResponse';
import { useState } from 'react';

export default class RegistrationDataService {
  /**
   * Retrieves the registration record based on the provided registration number.
   *
   * @param registrationNumber - The unique identifier for the registration record.
   * @returns An object containing the fetched registration record and any error encountered during the fetch operation.
   */
  async getRegistrationRecord(registrationNumber: string) {
    let fetchedRegistrationRecord: RegistrationRecord | undefined = undefined;
    let error: string | undefined = undefined;

    try {
      const response = await apiService.fetchRegistrationRecord(registrationNumber);
      const registrationRecordResponse: GetRegistrationRecordResponse = await response.json();
      fetchedRegistrationRecord = registrationRecordResponse.registrationRecord;
    } catch (ex) {
      error = ErrorUtils.getMessage(ex);
    }

    return {
      fetchedRegistrationRecord,
      error,
    };
  }

  /**
   * Creates a new registration record.
   * @param registrationRecordToCreate - The registration record to be created.
   * @returns An object containing the created registration record or an error message if the create operation fails.
   */
  async createRegistrationRecord(registrationRecordToCreate: RegistrationRecord) {
    let createdRegistrationRecord: RegistrationRecord | undefined = undefined;
    let error: string | undefined = undefined;

    try {
      const response = await apiService.createRegistrationRecord(registrationRecordToCreate);
      const registrationRecordResponse: SaveRegistrationRecordResponse = await response.json();
      createdRegistrationRecord = registrationRecordResponse.registrationRecord;
    } catch (ex) {
      error = ErrorUtils.getMessage(ex);
    }

    return {
      createdRegistrationRecord,
      error,
    };
  }

  /**
   * Updates an existing registration record.
   * @param registrationRecordToUpdate - The registration record to be updated.
   * @returns An object containing the updated registration record or an error message if the update operation fails.
   */
  async updateRegistrationRecord(registrationRecordToUpdate: RegistrationRecord) {
    let updatedRegistrationRecord: RegistrationRecord | undefined = undefined;
    let error: string | undefined = undefined;

    try {
      const response = await apiService.updateRegistrationRecord(registrationRecordToUpdate);
      const registrationRecordResponse: SaveRegistrationRecordResponse = await response.json();
      updatedRegistrationRecord = registrationRecordResponse.registrationRecord;
    } catch (ex) {
      error = ErrorUtils.getMessage(ex);
    }

    return {
      updatedRegistrationRecord,
      error,
    };
  }

  /**
   * Gets registration records by provider and recipient company codes.
   * @param providerCompanyCode - The code of the provider company.
   * @param recipientCompanyCode - The code of the recipient company.
   * @param previousPageToken - The token for the previous page, used for pagination.
   * @param pageSize - The number of results per page.
   * @returns An object containing the list of registration records, the next page token, and an error message if the get operation fails.
   */
  async getRecordsByCompanyCodes(
    providerCompanyCode: string,
    recipientCompanyCode: string,
    previousPageToken?: string,
    pageSize?: number,
  ) {
    let registrationRecords: RegistrationRecord[] | undefined = undefined;
    let nextPageToken: string | undefined = undefined;
    let error: string | undefined = undefined;

    try {
      const response = await apiService.getRecordsByCompanyCodes(
        providerCompanyCode,
        recipientCompanyCode,
        pageSize,
        previousPageToken,
      );
      const getRegistrationRecordsResponse: GetRegistrationRecordsResponse = await response.json();
      registrationRecords = getRegistrationRecordsResponse.registrationRecords;
      nextPageToken = getRegistrationRecordsResponse.nextToken;
    } catch (ex) {
      error = ErrorUtils.getMessage(ex);
    }

    return {
      registrationRecords,
      nextPageToken,
      error,
    };
  }

  /**
   * Gets registration records by approval workflow stage.
   * @param approvalWorkflowStage - The approval workflow stage to filter the records.
   * @param previousPageToken - The token for the previous page, used for pagination (optional).
   * @param pageSize - The number of results per page (optional).
   * @returns An object containing the list of registration records, the next page token, and an error message if the get operation fails.
   */
  async getRecordsByApprovalWorkflowStage(
    approvalWorkflowStage: string,
    previousPageToken?: string,
    pageSize?: number,
  ) {
    let registrationRecords: RegistrationRecord[] | undefined = undefined;
    let nextPageToken: string | undefined = undefined;
    let error: string | undefined = undefined;

    try {
      const response = await apiService.getRecordsByApprovalWorkflowStage(
        approvalWorkflowStage,
        pageSize,
        previousPageToken,
      );
      const getRegistrationRecordsResponse: GetRegistrationRecordsResponse = await response.json();
      registrationRecords = getRegistrationRecordsResponse.registrationRecords;
      nextPageToken = getRegistrationRecordsResponse.nextToken;
    } catch (ex) {
      error = ErrorUtils.getMessage(ex);
    }

    return {
      registrationRecords,
      nextPageToken,
      error,
    };
  }

  /**
   * Get all the registration records by bulk upload identifier
   * @param bulkUploadIdentifier  : Identifier value
   * @returns An object containing the list of all registration records
   */
  async getRecordsByBulkUploadIdentifier(bulkUploadIdentifier: string | undefined) {
    let registrationRecords: RegistrationRecord[] = new Array();
    let error: string | undefined = undefined;

    try {
      if (!bulkUploadIdentifier) {
        throw new Error('Bulk upload identifier is not provided');
      }
      const apiResponse = await apiService.getRecordsByBulkUploadIdentifier(bulkUploadIdentifier);
      const getRecordsByBulkUploadIdentifier: GetRegistrationRecordsByBulkUploadIdentifierResponse =
        await apiResponse.json();
      registrationRecords = getRecordsByBulkUploadIdentifier.registrationRecords;
    } catch (ex) {
      error = ErrorUtils.getMessage(ex);
    }

    return {
      registrationRecords,
      error,
    };
  }

  /**
   * Fetches revision record for a given registration number and approval workflow revision.
   * Either latestRev or approvalWorkflowRev can be provided, but not both can be missing.
   * @param baseRegistrationNumber - The base registration number to search for revisions.
   * @param approvalWorkflowRev - The specific approval workflow revision number (optional).
   * @param latestRev - A flag to indicate whether to fetch the latest revision (optional).
   * @returns An object containing the revision record or an error message if the fetch operation fails.
   */
  async getRevisionRecord(baseRegistrationNumber: string, approvalWorkflowRev?: number, latestRev?: boolean) {
    let revisionRecord: RegistrationRecord | undefined = undefined;
    let error: string | undefined = undefined;

    try {
      const response = await apiService.getWorkflowRevisionRecord(
        baseRegistrationNumber,
        approvalWorkflowRev,
        latestRev,
      );
      const getRegistrationRecordResponse: GetRegistrationRecordResponse = await response.json();
      revisionRecord = getRegistrationRecordResponse.registrationRecord;
    } catch (ex) {
      error = ErrorUtils.getMessage(ex);
    }

    return {
      revisionRecord,
      error,
    };
  }

  /**
   * Discards a workflow revision for a given registration number, approval workflow revision, and the user who discarded it.
   * @param baseRegistrationNumber - The base registration number.
   * @param approvalWorkflowRev - The approval workflow revision number to be discarded.
   * @param discardedBy - The user who discarded the workflow revision.
   * @returns An object containing the last approved data or an error message if the discard operation fails.
   */
  async discardWorkflowRevision(baseRegistrationNumber: string, approvalWorkflowRev: number, discardedBy: string) {
    let lastApprovedRecord: RegistrationRecord | undefined = undefined;
    let error: string | undefined = undefined;

    const payload: any = {
      baseRegistrationNumber,
      approvalWorkflowRev,
      discardedBy,
    };

    try {
      const response = await apiService.discardWorkflowRevision(payload);
      const discardWorkflowRevisionResponse: DiscardWorkflowRevisionResponse = await response.json();
      lastApprovedRecord = discardWorkflowRevisionResponse.registrationRecord;
    } catch (ex) {
      error = ErrorUtils.getMessage(ex);
    }

    return {
      lastApprovedRecord,
      error,
    };
  }

  /**
   * Deactivates a registration record
   * @param registrationNumber - The registration number to deactivate
   * @param requestingUser - The user initiating the deactivation
   * @returns An object containing the deactivated record or an error message
   */
  async deactivateRegistrationRecord(registrationNumber: string, requestingUser: string) {
    let deactivatedRecord: RegistrationRecord | undefined = undefined;
    let error: string | undefined = undefined;

    try {
      const response = await apiService.deactivateRegistrationRecord(registrationNumber, requestingUser);
      const data: GetRegistrationRecordResponse = await response.json();
      deactivatedRecord = data.registrationRecord;
    } catch (ex) {
      error = ErrorUtils.getMessage(ex);
    }

    return {
      deactivatedRecord,
      error,
    };
  }

  /**
   * Reactivates a registration record
   * @param registrationNumber - The registration number to reactivate
   * @param requestingUser - The user initiating the reactivation
   * @returns An object containing the reactivated record or an error message
   */
  async reactivateRegistrationRecord(registrationNumber: string, requestingUser: string) {
    let reactivatedRecord: RegistrationRecord | undefined = undefined;
    let error: string | undefined = undefined;

    try {
      const response = await apiService.reactivateRegistrationRecord(registrationNumber, requestingUser);
      const data: GetRegistrationRecordResponse = await response.json();
      reactivatedRecord = data.registrationRecord;
    } catch (ex) {
      error = ErrorUtils.getMessage(ex);
    }

    return {
      reactivatedRecord,
      error,
    };
  }

  /**
   * Terminates a registration record
   * @param registrationNumber - The registration number to terminate
   * @param requestingUser - The user initiating the termination
   * @returns An object containing the terminated record or an error message
   */
  async terminateRegistrationRecord(registrationNumber: string, requestingUser: string) {
    let terminatedRecord: RegistrationRecord | undefined = undefined;
    let error: string | undefined = undefined;

    try {
      const response = await apiService.terminateRegistrationRecord(registrationNumber, requestingUser);
      const data: GetRegistrationRecordResponse = await response.json();
      terminatedRecord = data.registrationRecord;
    } catch (ex) {
      error = ErrorUtils.getMessage(ex);
    }

    return {
      terminatedRecord,
      error,
    };
  }
}
