import CONSTANTS from 'src/utils/constants';
import WebSocketApi from './WebsocketApi';

export default class ICRSWebsocketApiClient extends WebSocketApi {
  ensureConnected(): void {
    super.ensureConnected(CONSTANTS.ENVIRONMENT_VARIABLES.WEBSOCKET_API_URL);
  }

  processBulkRegistrations(payload: any) {
    this.ensureConnected();
    this.sendMessage(CONSTANTS.WEBSOCKET_ROUTES.PROCESS_BULK_REGISTRATION, payload);
  }

  processBulkApproval(payload: any) {
    this.ensureConnected();
    this.sendMessage(CONSTANTS.WEBSOCKET_ROUTES.PROCESS_BULK_APPROVALS, payload);
  }
}
