import { useState, useCallback, useContext } from 'react';
import { GlobalAppContext } from 'src/components/App';
import ServiceCollection from 'src/services/ServiceCollection';
import { fetchCurrencyCodes } from 'src/utils/CurrencyCodesUtils';

export const useCurrencyCodes = (services: ServiceCollection) => {
  const { currencyCodes, setCurrencyCodes } = useContext(GlobalAppContext);
  const [loadingCurrencyCodes, setLoadingCurrencyCodes] = useState(false);
  const [fetchCurrencyCodesErrorMessage, setFetchCurrencyCodesErrorMessage] = useState('');

  const getCurrencyCodes = useCallback(async () => {
    // If currency codes are already loaded from React context, just return.
    if (currencyCodes?.length != 0) {
      return;
    }
    fetchCurrencyCodes(
      services.currencyCodesService,
      setCurrencyCodes,
      setLoadingCurrencyCodes,
      setFetchCurrencyCodesErrorMessage,
    );
  }, [currencyCodes]);

  return {
    currencyCodes,
    loadingCurrencyCodes,
    fetchCurrencyCodesErrorMessage,
    getCurrencyCodes,
  };
};
