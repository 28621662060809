import { useState, useCallback } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';

export const useGetRegistrationRecord = (services: ServiceCollection) => {
  const [isGetRegistrationRecordLoading, setIsGetRegistrationRecordLoading] = useState(false);
  const [registrationRecordDetails, setRegistrationRecordDetails] = useState<RegistrationRecord | null>(null);
  const [getRegistrationRecordError, setGetRegistrationRecordError] = useState<string | null>(null);

  const submitGetRegistrationRecord = useCallback(
    async (registrationNumber: string) => {
      setIsGetRegistrationRecordLoading(true);
      const { fetchedRegistrationRecord, error } =
        await services.registrationDataService.getRegistrationRecord(registrationNumber);
      setIsGetRegistrationRecordLoading(false);
      if (error) {
        setGetRegistrationRecordError(error);
        return;
      }
      if (fetchedRegistrationRecord) {
        setRegistrationRecordDetails(fetchedRegistrationRecord);
      }
    },
    [services],
  );

  return {
    isGetRegistrationRecordLoading,
    registrationRecordDetails,
    getRegistrationRecordError,
    submitGetRegistrationRecord,
  };
};
