export class UserProfile {
  alias: string;
  givenName: string;
  familyName: string;
  roles: string[];

  constructor(alias: string, givenName: string, familyName: string, role: string[]) {
    this.alias = alias;
    this.givenName = givenName;
    this.familyName = familyName;
    this.roles = role;
  }
}
