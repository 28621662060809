import React, { useEffect, useState } from 'react';
import {
  Button,
  ColumnLayout,
  ExpandableSection,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Textarea,
  Modal,
  Box,
  Select,
  Grid,
  FlashbarProps,
  Link,
  Flashbar,
  Multiselect,
} from '@amzn/awsui-components-react';
import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';
import { useHistory, useParams } from 'react-router-dom';
import { GlobalAppContext } from 'src/components/App';
import { formatDate } from 'src/utils/DateUtils';
import { customHelpPanel } from 'src/shared/CustomComponents';
import CONSTANTS from 'src/utils/constants';
import './ShowRecord.scss';
import ApprovalWorkflow from 'src/components/approval-workflow/ApprovalWorkflow';
import ShowApprovalWorkflowActions from 'src/components/ShowApprovalWorkflowActions';
import ServiceCollection from 'src/services/ServiceCollection';
import ApprovalResultModal from 'src/components/ApprovalResultModal';
import { useCreateApproval } from 'src/components/data-registration/show-registration-record/hooks/useCreateApproval';
import { useUpdateRegistrationRecord } from './hooks/useUpdateRegistrationRecord';
import { UpdateRegistrationRecordManager } from './UpdateRegistrationRecordManager';
import { useDiscardWorkflowRevision } from './hooks/useDiscardWorkflowRevision';
import { useGetPastRevsionRecord } from './hooks/useGetPastRevisionData';
import { useGetLatestRevsionRecord } from './hooks/useGetLatestRevsionRecord';
import { useGetRegistrationRecord } from './hooks/useGetRegistrationRecord';
import { createLabelValueList } from 'src/components/data-registration/show-registration-record/utils';
import apiCallService from 'src/services/ApiCallService';
import { useTerminateRegistrationRecord } from 'src/components/data-registration/show-registration-record/hooks/useTerminateRegistrationRecord';
import { useDeactivateRegistrationRecord } from 'src/components/data-registration/show-registration-record/hooks/useDeactivateRegistrationRecord';
import { useReactivateRegistrationRecord } from 'src/components/data-registration/show-registration-record/hooks/useReactivateRegistrationRecord';

interface ShowRecordProps {
  services: ServiceCollection;
  userAlias: string;
  isAccountingUser: boolean;
  isIndirectTaxUser: boolean;
  isSuperUser: boolean;
  isDirectTaxUser: boolean;
}

interface SelectedOption {
  label: string;
  value: string;
}

export default function ShowRecord(props: ShowRecordProps) {
  const { services, userAlias, isAccountingUser, isIndirectTaxUser, isSuperUser, isDirectTaxUser } = props;
  const { isViewer } = React.useContext(GlobalAppContext);
  const history = useHistory();
  const { encodedRegistrationNumber } = useParams<{ encodedRegistrationNumber: string }>();
  const [registrationNumber, setRegistrationNumber] = useState<string>(encodedRegistrationNumber.replace('*', '.'));
  const [registrationRecord, setRegistrationRecord] = useState<RegistrationRecord>();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [activateModalVisible, setActivateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [discardModalVisible, setDiscardModalVisible] = useState(false);
  const [approvalWorkflowStage, setApprovalWorkflowStage] = useState('');
  const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [selectedOption, setSelectedOption] = useState<{ label: string; value: string } | null>(null);
  const [allRevisions, setAllRevisions] = useState<SelectedOption[]>([]);
  const [selectedRevision, setSelectedRevision] = useState<string>('');
  const {
    isGetRegistrationRecordLoading,
    registrationRecordDetails,
    getRegistrationRecordError,
    submitGetRegistrationRecord,
  } = useGetRegistrationRecord(services);
  const { isUpdateLoading, updatedRegistrationRecord, updateErrorMessage, submitForUpdate } =
    useUpdateRegistrationRecord(services);
  const updateRegistrationRecordManager = new UpdateRegistrationRecordManager(
    registrationRecord!,
    userAlias,
    submitForUpdate,
  );
  const {
    isDiscardLoading,
    discardedRecord,
    isDiscardSuccess,
    discardRevisionError,
    submitForDiscardWorkflowRevision,
  } = useDiscardWorkflowRevision(services, registrationRecord!, userAlias);
  const {
    isGetPastRevisionRecordLoading,
    pastRevisionRecord,
    getPastRevisionRecordError,
    submitToGetPastRevisionRecord,
  } = useGetPastRevsionRecord(services, registrationRecord!);
  const { isGetRecordLoading, latestRevisionRecord, getLatestRevisionRecordError, submitToGetLatestRevisionRecord } =
    useGetLatestRevsionRecord(services, registrationRecord!);
  const {
    isApprovalSubmittedLoading,
    approvalResponse,
    approvalErrorMessage,
    resetApprovalResponse,
    submitForApproval,
  } = useCreateApproval(services, registrationRecord ? [registrationRecord.registrationNumber!] : [], userAlias);

  const { isDeactivating, deactivatedRecord, deactivationError, submitDeactivation } = useDeactivateRegistrationRecord(
    services,
    registrationRecord!,
    userAlias,
  );

  const { isReactivating, reactivatedRecord, reactivationError, submitReactivation } = useReactivateRegistrationRecord(
    services,
    registrationRecord!,
    userAlias,
  );

  const { isTerminating, terminatedRecord, terminationError, submitTermination } = useTerminateRegistrationRecord(
    services,
    registrationRecord!,
    userAlias,
  );

  useEffect(() => {
    if (terminatedRecord) {
      setRegistrationRecord(terminatedRecord);
      setCancelModalVisible(false);
    }
  }, [terminatedRecord]);

  useEffect(() => {
    if (deactivatedRecord) {
      setRegistrationRecord(deactivatedRecord);
    }
  }, [deactivatedRecord]);

  useEffect(() => {
    if (reactivatedRecord) {
      setRegistrationRecord(reactivatedRecord);
    }
  }, [reactivatedRecord]);

  function goToEditPage() {
    history.push({
      pathname: '/edit',
      state: registrationRecord,
    });
  }

  useEffect(() => {
    if (registrationNumber) {
      let updatedRegistrationNumber = registrationNumber.replace('*', '.');
      submitGetRegistrationRecord(updatedRegistrationNumber);
    }
  }, [registrationNumber]);

  useEffect(() => {
    if (registrationRecordDetails) {
      setRegistrationRecord(registrationRecordDetails);
    }
  }, [registrationRecordDetails]);

  useEffect(() => {
    if (updatedRegistrationRecord) {
      setRegistrationRecord(updatedRegistrationRecord);
    }
  }, [updatedRegistrationRecord]);

  useEffect(() => {
    if (latestRevisionRecord) {
      setRegistrationRecord(latestRevisionRecord);
      setRegistrationNumber(latestRevisionRecord.registrationNumber!.replace('.', '*'));
      history.push({
        pathname: `/show/${latestRevisionRecord.registrationNumber?.replace('.', '*')}`,
        state: latestRevisionRecord,
      });
    }
  }, [latestRevisionRecord]);

  useEffect(() => {
    if (pastRevisionRecord) {
      // setDiscardModalVisible(false);
      setRegistrationRecord(pastRevisionRecord);
      setRegistrationNumber(pastRevisionRecord.registrationNumber!.replace('.', '*'));
      history.push({
        pathname: `/show/${pastRevisionRecord.registrationNumber?.replace('.', '*')}`,
        state: pastRevisionRecord,
      });
    }
  }, [pastRevisionRecord]);

  useEffect(() => {
    if (discardedRecord && discardedRecord.registrationNumber) {
      setSelectedOption({
        label: 'Revision ' + discardedRecord!.approvalWorkflowRev! + ' (Current)',
        value: discardedRecord!.approvalWorkflowRev!.toString(),
      });
      setDiscardModalVisible(false);
      setRegistrationRecord(discardedRecord);
      return;
    } else if (isDiscardSuccess) {
      history.push({
        pathname: '/',
      });
    }
  }, [discardedRecord, isDiscardSuccess]);

  useEffect(() => {
    if (updateErrorMessage) {
      services.messageService.showErrorAutoDismissBanner(updateErrorMessage);
    } else if (discardRevisionError) {
      services.messageService.showErrorAutoDismissBanner(discardRevisionError);
    } else if (getPastRevisionRecordError) {
      services.messageService.showErrorAutoDismissBanner(getPastRevisionRecordError);
    } else if (getLatestRevisionRecordError) {
      services.messageService.showErrorAutoDismissBanner(getLatestRevisionRecordError);
    } else if (approvalErrorMessage) {
      services.messageService.showErrorAutoDismissBanner(approvalErrorMessage);
    } else if (getRegistrationRecordError) {
      services.messageService.showErrorAutoDismissBanner(getRegistrationRecordError);
    }
  }, [
    updateErrorMessage,
    discardRevisionError,
    getPastRevisionRecordError,
    getLatestRevisionRecordError,
    approvalErrorMessage,
    getRegistrationRecordError,
  ]);

  useEffect(() => {
    if (registrationRecord == undefined) {
      return;
    } else if (registrationRecord.latestRev) {
      setApprovalWorkflowStage(registrationRecord.approvalWorkflowStage!);
      const workflowRevCount = registrationRecord.approvalWorkflowRev!;
      const revisions = [];

      for (let workflowRev = workflowRevCount; workflowRev >= 0; workflowRev--) {
        const isCurrentRev = workflowRev === workflowRevCount;
        const label = `Revision ${workflowRev}${isCurrentRev ? ' (Current)' : ''}`;

        revisions.push({ label, value: workflowRev.toString() });

        if (isCurrentRev) {
          setSelectedOption({ label, value: workflowRev.toString() });
        }
      }
      setAllRevisions(revisions);
    } else {
      setApprovalWorkflowStage(registrationRecord.approvalWorkflowStage!);
      setItems([
        {
          type: 'info',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setItems([]),
          content: (
            <>
              You are not viewing the latest revision.{' '}
              <Link
                data-testid="go-to-latest-rev-link"
                color="inverted"
                onFollow={() => submitToGetLatestRevisionRecord()}
              >
                Go to the latest revision
              </Link>
              .
            </>
          ),
          id: 'message_1',
        },
      ]);
    }
  }, [registrationRecord]);

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div>
      {registrationRecord !== undefined ? (
        <div>
          <Modal
            onDismiss={() => setDiscardModalVisible(false)}
            visible={discardModalVisible}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={submitForDiscardWorkflowRevision}
                    data-testid="discard-modal-yes-button"
                    loading={isDiscardLoading}
                  >
                    Yes
                  </Button>
                  <Button variant="link" onClick={() => setDiscardModalVisible(false)}>
                    No
                  </Button>
                </SpaceBetween>
              </Box>
            }
            header="Discard Current Revision"
            data-testid="discard-modal"
          >
            Discarding this current revision will result in revert back to last approved revision if present. This
            action cannot be undone. Are you sure you want to proceed?
          </Modal>
          <Modal
            onDismiss={() => setCancelModalVisible(false)}
            visible={cancelModalVisible}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={() => {
                      submitTermination();
                      setCancelModalVisible(false);
                    }}
                    data-testid="cancel-modal-yes-button"
                    loading={isTerminating}
                  >
                    {isTerminating ? 'Terminating...' : 'Yes'}
                  </Button>
                  <Button variant="link" onClick={() => setCancelModalVisible(false)}>
                    No
                  </Button>
                </SpaceBetween>
              </Box>
            }
            header="Terminate Registration Record"
            data-testid="cancel-modal"
          >
            Terminating this record cannot be undone. Are you sure you want to proceed?
          </Modal>
          <Modal
            onDismiss={() => setActivateModalVisible(false)}
            visible={activateModalVisible}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={() => {
                      submitReactivation();
                      setActivateModalVisible(false);
                    }}
                    data-testid="activate-modal-yes-button"
                    loading={isReactivating}
                  >
                    Yes
                  </Button>
                  <Button variant="link" onClick={() => setActivateModalVisible(false)}>
                    No
                  </Button>
                </SpaceBetween>
              </Box>
            }
            header="Activate Registration Record"
            data-testid="activate-modal"
          >
            Activating this record will move it back into the Active stage. Are you sure you want to proceed?
          </Modal>
          <Modal
            onDismiss={() => setEditModalVisible(false)}
            visible={editModalVisible}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={() => {
                      setEditModalVisible(false);
                      goToEditPage();
                    }}
                    data-testid="edit-modal-yes-button"
                  >
                    Yes
                  </Button>
                  <Button variant="link" onClick={() => setEditModalVisible(false)}>
                    No
                  </Button>
                </SpaceBetween>
              </Box>
            }
            header="Edit Registration Record"
            data-testid="edit-modal"
          >
            Editing this record will move it back to draft. Are you sure you want to do proceed?
          </Modal>
          <SpaceBetween size="xl">
            {registrationRecord.latestRev! ? (
              <Grid gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}>
                <div></div>
                <FormField label="Select Past Revision">
                  <Select
                    data-testid="select-past-rev-data"
                    selectedOption={selectedOption!}
                    options={allRevisions}
                    onChange={({ detail: { selectedOption } }) => {
                      if (
                        selectedOption.value !== undefined &&
                        Number(selectedOption.value) !== registrationRecord.approvalWorkflowRev
                      ) {
                        setSelectedRevision(selectedOption.value);
                        submitToGetPastRevisionRecord(selectedOption.value);
                      }
                    }}
                  />
                </FormField>
              </Grid>
            ) : (
              <Flashbar data-testid="go-to-latest-rev" items={items} />
            )}
            <ApprovalWorkflow isAccountingUser={isAccountingUser} registrationRecord={registrationRecord} />
            <Form
              header={
                <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
                  <Header variant="h1">Registration Record</Header>
                  <div data-class="actions">
                    <ShowApprovalWorkflowActions
                      registrationRecord={registrationRecord}
                      stage={approvalWorkflowStage}
                      isViewer={isViewer}
                      isAccountingUser={isAccountingUser}
                      isDirectTaxUser={isDirectTaxUser}
                      setEditModalVisible={setEditModalVisible}
                      goToEditPage={goToEditPage}
                      setDiscardModalVisible={setDiscardModalVisible}
                      submitForApproval={submitForApproval}
                      deactivate={() => submitDeactivation()}
                      setActivateModalVisible={setActivateModalVisible}
                      setCancelModalVisible={setCancelModalVisible}
                      isApprovalSubmittedLoading={isApprovalSubmittedLoading}
                      services={services}
                    />
                  </div>
                </Grid>
              }
            >
              <div className="display-registration-record" style={{ backgroundColor: 'whitesmoke' }}>
                <ExpandableSection headerText="Registration Details" variant="container" defaultExpanded>
                  <ColumnLayout columns={2}>
                    <FormField label="Registration Number">
                      <Input value={registrationRecord.registrationNumber!} disabled />
                    </FormField>
                    <FormField />
                    <FormField label="ATP Calculation" info={customHelpPanel(CONSTANTS.FIELDS_INFO.ATP_CALCULATION)}>
                      <Input value={String(registrationRecord.atpCalculationFlag)} disabled />
                    </FormField>
                    <FormField label="Entered Currency" info={customHelpPanel(CONSTANTS.FIELDS_INFO.CURRENCY)}>
                      <Input value={registrationRecord.currency ?? ''} disabled />
                    </FormField>
                    <FormField
                      label="Provider Company Code"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.PROVIDER_COMPANY_CODE)}
                    >
                      <Input value={registrationRecord.providerCompanyCode} disabled />
                    </FormField>
                    <FormField
                      label="Recipient Company Code"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.RECIPIENT_COMPANY_CODE)}
                    >
                      <Input value={registrationRecord.recipientCompanyCode} disabled />
                    </FormField>
                    <FormField label="Provider Entity Name">
                      <Input value={registrationRecord.providerEntityName ?? ''} disabled />
                    </FormField>
                    <FormField label="Recipient Entity Name">
                      <Input value={registrationRecord.recipientEntityName ?? ''} disabled />
                    </FormField>
                    <FormField label="Provider Jurisdiction">
                      <Input value={registrationRecord.providerJurisdiction ?? ''} disabled />
                    </FormField>
                    <FormField label="Recipient Jurisdiction">
                      <Input value={registrationRecord.recipientJurisdiction ?? ''} disabled />
                    </FormField>
                    <FormField
                      label="Effective Start Date"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.EFFECTIVE_FROM_DATE)}
                    >
                      <Input
                        value={
                          registrationRecord.effectiveFromDate
                            ? formatDate(new Date(registrationRecord.effectiveFromDate))
                            : ''
                        }
                        disabled
                      />
                    </FormField>
                    <FormField
                      label="Effective End Date"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.EFFECTIVE_TO_DATE)}
                    >
                      <Input
                        value={
                          registrationRecord.effectiveToDate
                            ? formatDate(new Date(registrationRecord.effectiveToDate))
                            : ''
                        }
                        disabled
                      />
                    </FormField>
                    <FormField label="Workbook Name" info={customHelpPanel(CONSTANTS.FIELDS_INFO.WORKBOOK_NAME)}>
                      <Input value={registrationRecord.workbookName ?? ''} disabled />
                    </FormField>
                    <FormField label="Payment Terms" info={customHelpPanel(CONSTANTS.FIELDS_INFO.PAYMENT_TERMS)}>
                      <Input value={registrationRecord.paymentTerms ?? ''} disabled />
                    </FormField>
                    <FormField label="BEAT Indicator" info={customHelpPanel(CONSTANTS.FIELDS_INFO.BEAT)}>
                      <Input value={registrationRecord.beat === 'INBD' ? 'Yes' : 'No'} disabled />
                    </FormField>
                    <FormField />
                    <FormField
                      label="Description of Transaction"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.DESCRIPTION)}
                    >
                      <Textarea value={registrationRecord.description ?? ''} disabled />
                    </FormField>
                    <FormField label="Comments" info={customHelpPanel(CONSTANTS.FIELDS_INFO.COMMENTS)}>
                      <Textarea value={registrationRecord.comments ?? ''} disabled />
                    </FormField>
                    <FormField
                      label="Provider IC BS Accounts"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.PROVIDER_GL_ACCOUNTS)}
                    >
                      <Multiselect
                        selectedOptions={createLabelValueList(registrationRecord.providerIcBsAccounts ?? [])}
                        disabled
                      />
                    </FormField>
                    <FormField
                      label="Recipient IC BS Accounts"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.RECIPIENT_GL_ACCOUNTS)}
                    >
                      <Multiselect
                        selectedOptions={createLabelValueList(registrationRecord.recipientIcBsAccounts ?? [])}
                        disabled
                      />
                    </FormField>

                    <FormField label="Provider PL Accounts">
                      <Multiselect
                        selectedOptions={createLabelValueList(registrationRecord.providerPlAccounts ?? [])}
                        disabled
                      />
                    </FormField>

                    <FormField label="Recipient PL Accounts">
                      <Multiselect
                        selectedOptions={createLabelValueList(registrationRecord.recipientPlAccounts ?? [])}
                        disabled
                      />
                    </FormField>
                    <FormField label="Accounting POC Alias">
                      <Input value={registrationRecord.accountingPoc ?? ''} disabled />
                    </FormField>
                  </ColumnLayout>
                </ExpandableSection>
                <ExpandableSection headerText="Registration Record Contributors" variant="container" defaultExpanded>
                  <ColumnLayout columns={2}>
                    <FormField label="Created By">
                      <Input value={registrationRecord.createdBy} disabled />
                    </FormField>
                    <FormField />
                    <FormField label="Last Updated By Accounting User">
                      <Input value={registrationRecord.lastUpdatedByAccountingUser ?? ''} disabled />
                    </FormField>
                    <FormField label="Last Updated By Tax User">
                      <Input value={registrationRecord.lastUpdatedByTaxUser ?? ''} disabled />
                    </FormField>
                    <FormField label="Created Date Time">
                      <Input value={formatDate(new Date(registrationRecord.createDateTime!))} disabled />
                    </FormField>
                    <FormField label="Last Updated Date Time">
                      <Input value={formatDate(new Date(registrationRecord.lastUpdatedDateTime!))} disabled />
                    </FormField>
                  </ColumnLayout>
                </ExpandableSection>
                <ExpandableSection headerText="Tax Product Details" variant="container" defaultExpanded>
                  <ColumnLayout columns={2}>
                    <FormField label="Provider Tax Reg Consideration">
                      <Input value={String(registrationRecord.providerTaxRegConsideration)} disabled />
                    </FormField>
                    <FormField label="Recipient Tax Reg Consideration">
                      <Input value={String(registrationRecord.recipientTaxRegConsideration)} disabled />
                    </FormField>
                    <FormField label="Tax Product Category Name">
                      <Input value={registrationRecord.taxProductCategoryName ?? ''} disabled />
                    </FormField>
                    <FormField label="Tax Product Category Id">
                      <Input value={registrationRecord.taxProductCategoryId ?? ''} disabled />
                    </FormField>
                    <FormField label="Tax Product Sub Category Name">
                      <Input value={registrationRecord.taxProductName ?? ''} disabled />
                    </FormField>
                    <FormField label="Tax Product Sub Category Id">
                      <Input value={registrationRecord.taxProductId ?? ''} disabled />
                    </FormField>
                    <FormField
                      label="Export of Services"
                      info={customHelpPanel(CONSTANTS.FIELDS_INFO.EXPORT_OF_SERVICES)}
                    >
                      <Input value={String(registrationRecord.exportOfServices)} disabled />
                    </FormField>
                  </ColumnLayout>
                </ExpandableSection>
              </div>
            </Form>{' '}
            :<></>
          </SpaceBetween>
          {approvalResponse && <ApprovalResultModal approvalResponse={approvalResponse} onDismiss={refreshPage} />}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
