import ServiceCollection from 'src/services/ServiceCollection';

export interface BulkRegistrationWebsocketResponse {
  connectionId: string;
  message: BulkRegistrationResponse;
  messageType: string;
  status: string;
}

export interface BulkApprovalWebsocketResponse {
  connectionId: string;
  message: BulkApprovalResponse;
  messageType: string;
  status: string;
}

export interface BulkRegistrationResponse {
  processedCount: number;
  totalDistinctRecordsCount: number;
  message: string;
  numSuccessfulRecords: number;
  numFailedRecords: number;
  duplicateRecordsCount: number;
  errorFileDownloadURL: string;
}

export interface BulkApprovalResponse {
  processedCount: number;
  message: string;
  numSuccessfulRecords: number;
  numFailedRecords: number;
  errorFileDownloadURL: string;
}

export interface TrackBulkCreateRegistrationRequestProps {
  services: ServiceCollection;
  userAlias: string;
  isAccountingUser: boolean;
  isIndirectTaxUser: boolean;
  isDirectTaxUser: boolean;
  isSuperUser: boolean;
}

export interface LocationState {
  bulkUploadIdentifier?: string; // Optional if it might not be present
}

export enum UserRole {
  ACCOUNTING_USER = 'accountingUser',
  TAX_USER = 'taxUser',
  SUPER_USER = 'admin',
}

export const DEFAULT_PAGE_SIZE = 100;

export const RegistrationTableColumns = [
  'registrationNumber',
  'baseRegistrationNumber',
  'approvalWorkflowStage',
  'approvalWorkflowRev',
  'latestRev',
  'lastApprovedRev',
  'atpCalculationFlag',
  'currency',
  'effectiveFromDate',
  'effectiveToDate',
  'workbookName',
  'beat',
  'description',
  'comments',
  'paymentTerms',
  'providerCompanyCode',
  'providerEntityName',
  'providerJurisdiction',
  'recipientCompanyCode',
  'recipientEntityName',
  'recipientJurisdiction',
  'providerTaxRegConsideration',
  'recipientTaxRegConsideration',
  'accountingOwner',
  'taxOwner',
  'taxProductCategoryName',
  'taxProductCategoryId',
  'taxProductName',
  'taxProductId',
  'exportOfServices',
  'accountingApprover',
  'taxApprover',
  'cancelledBy',
  'deactivatedBy',
  'reactivatedBy',
  'createdBy',
  'lastUpdatedByAccountingUser',
  'lastUpdatedByTaxUser',
  'createDateTime',
  'lastUpdatedDateTime',
  'providerIcBsAccounts',
  'providerPlAccounts',
  'recipientIcBsAccounts',
  'recipientPlAccounts',
  'accountingPoc',
  'approvalRequester',
];

export const FILTERING_OPERATORS_FOR_STRING = [':', '!:', '=', '!='];

export const DEFAULT_COLLECTION_PREFERENCES = {
  pageSize: DEFAULT_PAGE_SIZE,
  visibleContent: RegistrationTableColumns,
  wrapLines: true,
  stickyColumns: { first: 0, last: 0 },
};

export const PAGE_SIZE_OPTIONS = [
  { value: 100, label: '100 registration records' },
  { value: 300, label: '300 registration records' },
  { value: 500, label: '500 registration records' },
];

export const VISIBLE_CONTENT_OPTIONS = RegistrationTableColumns.map((column) => ({
  id: column,
  label: column,
}));
