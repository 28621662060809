import React from 'react';
import Table from '@amzn/awsui-components-react/polaris/table';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import { useCollection } from '@amzn/awsui-collection-hooks';
import PropertyFilter from '@amzn/awsui-components-react/polaris/property-filter';
import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { CollectionPreferences, CollectionPreferencesProps, Header } from '@amzn/awsui-components-react';
import useLocalStorage from 'src/services/UseLocalStorage';

export const PAGINATION_LABELS = {
  nextPageLabel: 'Next page',
  previousPageLabel: 'Previous page',
  pageLabel: (pageNumber: any) => `Page ${pageNumber}`,
};

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  filteringAriaLabel: 'your choice',
  dismissAriaLabel: 'Dismiss',

  filteringPlaceholder: 'Search',
  groupValuesText: 'Values',
  groupPropertiesText: 'Properties',
  operatorsText: 'Operators',

  operationAndText: 'and',
  operationOrText: 'or',

  operatorLessText: 'Less than',
  operatorLessOrEqualText: 'Less than or equal',
  operatorGreaterText: 'Greater than',
  operatorGreaterOrEqualText: 'Greater than or equal',
  operatorContainsText: 'Contains',
  operatorDoesNotContainText: 'Does not contain',
  operatorEqualsText: 'Equals',
  operatorDoesNotEqualText: 'Does not equal',

  editTokenHeader: 'Edit filter',
  propertyText: 'Property',
  operatorText: 'Operator',
  valueText: 'Value',
  cancelActionText: 'Cancel',
  applyActionText: 'Apply',
  allPropertiesLabel: 'All properties',

  tokenLimitShowMore: 'Show more',
  tokenLimitShowFewer: 'Show fewer',
  clearFiltersText: 'Clear filters',
};

/**
 * Function to return text to display on UI for the filtered items
 * @param count
 * @returns
 */
export const getFilterCounterText = (count: Number | undefined) => {
  if (count) {
    return `${count} ${count === 1 ? 'match' : 'matches'}`;
  }
};

export const getSortingColumnObject = (
  sortingColumn: string,
  columnDefinitions: TableProps.ColumnDefinition<any>[],
) => {
  let sortColumnDefinition = {};
  columnDefinitions.forEach((columnDefinition) => {
    if (columnDefinition.id === sortingColumn) {
      sortColumnDefinition = columnDefinition;
    }
  });
  return sortColumnDefinition;
};

export const getTablePreferences = (
  title: string,
  disabled: boolean,
  pageSizeOptions: any[],
  visibleContentOptions: any[],
  preferences: CollectionPreferencesProps.Preferences,
  setPreferences: Function,
) => {
  return (
    <CollectionPreferences
      title={title}
      confirmLabel="Confirm"
      cancelLabel="Cancel"
      disabled={disabled}
      preferences={preferences}
      onConfirm={({ detail }) => setPreferences(detail)}
      pageSizePreference={{
        title: 'Page Size',
        options: pageSizeOptions,
      }}
      wrapLinesPreference={{
        label: 'Wrap Lines',
        description: 'Select to see all the text and wrap the lines',
      }}
      contentDisplayPreference={{
        title: 'Column Preferences',
        options: [...visibleContentOptions],
      }}
      stripedRowsPreference={{
        label: 'Striped Rows',
        description: 'Select to add alternating shaded rows',
      }}
      stickyColumnsPreference={{
        firstColumns: {
          title: 'Stick first column(s)',
          description: 'Keep the first column(s) visible while horizontally scrolling the table content.',
          options: [
            { label: 'None', value: 0 },
            { label: 'First column', value: 1 },
            { label: 'First two columns', value: 2 },
          ],
        },
        lastColumns: {
          title: 'Stick last column',
          description: 'Keep the last column(s) visible while horizontally scrolling the table content.',
          options: [
            { label: 'None', value: 0 },
            { label: 'Last column', value: 1 },
            { label: 'Last two columns', value: 2 },
          ],
        },
      }}
    />
  );
};

export default function ClientSidePaginatedPolarisTable(props: {
  setSelectedItems?: any;
  selectedItems?: any;
  defaultPreferences?: any;
  preferencesStorageKey: string;
  headerTitle: string;
  filteringEmptyText: string;
  filteringLoadingText: string;
  pageSizeOptions?: any;
  visibleContentOptions?: any;
  ariaLabels?: TableProps.AriaLabels<any>;
  columnDefinitions: TableProps.ColumnDefinition<any>[];
  sortingColumn: string;
  tableItems: any[];
  loadingText?: string;
  selectionType?: TableProps.SelectionType;
  filteringProperties?: PropertyFilterProperty[];
  resizableColumns: boolean;
  trackBy?: TableProps.TrackBy<string>;
  visibleColumns?: string[];
  tableEmptyState?: React.ReactNode;
  header?: React.ReactNode;
  isLoading?: boolean;
}) {
  const [preferences, setPreferences] = useLocalStorage.getStorageKeyFromLocalStorage(
    props.preferencesStorageKey,
    props.defaultPreferences,
  );

  const { items, allPageItems, paginationProps, collectionProps, propertyFilterProps, filteredItemsCount } =
    useCollection(props.tableItems, {
      propertyFiltering: {
        filteringProperties: props.filteringProperties ?? [],
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {
        defaultState: {
          sortingColumn: getSortingColumnObject(props.sortingColumn, props.columnDefinitions),
          isDescending: true,
        },
      },
    });

  return (
    <Table
      {...collectionProps}
      columnDefinitions={props.columnDefinitions}
      items={items}
      header={
        <Header>
          {props.headerTitle} ({filteredItemsCount})
        </Header>
      }
      loadingText={props.loadingText}
      selectionType={props.selectionType}
      resizableColumns={props.resizableColumns}
      trackBy={props.trackBy}
      wrapLines={preferences.wrapLines}
      visibleColumns={preferences.visibleColumns}
      columnDisplay={preferences.contentDisplay}
      stripedRows={preferences.stripedRows}
      stickyColumns={preferences.stickyColumns}
      empty={props.tableEmptyState}
      filter={
        props.filteringProperties && (
          <PropertyFilter
            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
            {...propertyFilterProps}
            countText={getFilterCounterText(filteredItemsCount)}
            filteringEmpty={props.filteringEmptyText}
            filteringLoadingText={props.filteringLoadingText}
          />
        )
      }
      pagination={<Pagination {...paginationProps} ariaLabels={PAGINATION_LABELS} />}
      preferences={getTablePreferences(
        'Preferences',
        false,
        props.pageSizeOptions,
        props.visibleContentOptions,
        preferences,
        setPreferences,
      )}
      loading={props.isLoading}
    />
  );
}
