import React, { useContext } from 'react';
import {
  Button,
  ColumnLayout,
  DatePicker,
  ExpandableSection,
  Form,
  FormField,
  Header,
  Input,
  Multiselect,
  RadioGroup,
  Select,
  SelectProps,
  StatusIndicator,
  Textarea,
} from '@amzn/awsui-components-react';
import { areRecordsEqual, RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';
import ServiceCollection from 'src/services/ServiceCollection';
import { useHistory, useLocation } from 'react-router-dom';
import { GlobalAppContext } from 'src/components/App';
import CONSTANTS from 'src/utils/constants';
import { formatDate } from 'src/utils/DateUtils';
import { customHelpPanel } from 'src/shared/CustomComponents';
import { getCategoryDataFromMDM, getTaxProductCategoryNames, getTaxProductNames } from 'src/utils/MDMUtils';
import { fetchCurrencyCodes } from 'src/utils/CurrencyCodesUtils';
import { useFramesAccounts } from 'src/shared/hooks/useFramesAccounts';
import {
  convertOptionsToStrings,
  createLabelValueList,
} from 'src/components/data-registration/edit-registration-records/utils';
import { createLabelValueObject } from 'src/components/data-registration/create-registration-records/utils';

interface EditRecordProps {
  services: ServiceCollection;
  userAlias: string;
  isAccountingUser: boolean;
  isIndirectTaxUser: boolean;
  isDirectTaxUser: boolean;
  isSuperUser: boolean;
}

export default function EditRecord(props: EditRecordProps) {
  const {
    isViewer,
    categoryNameToIdMapping,
    setCategoryNameToIdMapping,
    categorySubcategoriesMapping,
    setCategorySubcategoriesMapping,
    currencyCodes,
    setCurrencyCodes,
  } = useContext(GlobalAppContext);
  const history = useHistory();

  // If user is viewer, redirect to home page
  if (isViewer) {
    history.push({
      pathname: '/',
    });
    return <></>;
  }

  const { services, userAlias, isAccountingUser, isIndirectTaxUser, isSuperUser } = props;
  const location = useLocation<any>();
  const currentRegistrationRecord = location.state as RegistrationRecord;
  const [loading, setIsLoading] = React.useState(false);

  // Registration Data fields
  const [atpCalculationFlag, setATPCalculationFlag] = React.useState(
    String(currentRegistrationRecord.atpCalculationFlag),
  );
  const [currency, setCurrency] = React.useState(currentRegistrationRecord.currency ?? '');
  const [effectiveFromDate, setEffectiveFromDate] = React.useState(currentRegistrationRecord.effectiveFromDate ?? '');
  const [effectiveToDate, setEffectiveToDate] = React.useState(currentRegistrationRecord.effectiveToDate ?? '');
  const [workbookName, setWorkbookName] = React.useState(currentRegistrationRecord.workbookName ?? '');
  const [paymentTerms, setPaymentTerms] = React.useState(currentRegistrationRecord.paymentTerms ?? '');
  const [beat] = React.useState(currentRegistrationRecord.beat!);
  const [description, setDescription] = React.useState(currentRegistrationRecord.description ?? '');
  const [comments, setComments] = React.useState(currentRegistrationRecord.comments ?? '');
  const [providerEntityName, setProviderEntityName] = React.useState(
    currentRegistrationRecord.providerEntityName ?? '',
  );
  const [recipientEntityName, setRecipientEntityName] = React.useState(
    currentRegistrationRecord.recipientEntityName ?? '',
  );
  const [providerJurisdiction, setProviderJurisdiction] = React.useState(
    currentRegistrationRecord.providerJurisdiction ?? '',
  );
  const [recipientJurisdiction, setRecipientJurisdiction] = React.useState(
    currentRegistrationRecord.recipientJurisdiction ?? '',
  );
  const [providerTaxRegConsideration, setProviderTaxRegConsideration] = React.useState(
    String(currentRegistrationRecord.providerTaxRegConsideration),
  );
  const [recipientTaxRegConsideration, setRecipientTaxRegConsideration] = React.useState(
    String(currentRegistrationRecord.recipientTaxRegConsideration),
  );
  const [taxProductId, setTaxProductId] = React.useState(currentRegistrationRecord.taxProductId ?? '');
  const [taxProductName, setTaxProductName] = React.useState(currentRegistrationRecord.taxProductName ?? '');
  const [taxProductCategoryId, setTaxProductCategoryId] = React.useState(
    currentRegistrationRecord.taxProductCategoryId ?? '',
  );
  const [taxProductCategoryName, setTaxProductCategoryName] = React.useState(
    currentRegistrationRecord.taxProductCategoryName ?? '',
  );
  const [exportOfServices, setExportOfServices] = React.useState(String(currentRegistrationRecord.exportOfServices));
  const [productNameToIdMapping, setProductNameToIdMapping] = React.useState(new Map());
  const [loadingMDMData, setLoadingMDMData] = React.useState(false);
  const [mdmResponseErrorMessage, setMDMResponseErrorMessage] = React.useState('');
  const [loadingCurrencyCodes, setLoadingCurrencyCodes] = React.useState(false);
  const [fetchCurrencyCodesErrorMessage, setFetchCurrencyCodesErrorMessage] = React.useState('');
  const [accountingPoc, setAccountingPoc] = React.useState(currentRegistrationRecord.accountingPoc ?? '');
  const [providerIcBsAccounts, setProviderIcBsAccounts] = React.useState<SelectProps.Option[]>(
    createLabelValueList(currentRegistrationRecord.providerIcBsAccounts ?? []),
  );
  const [recipientIcBsAccounts, setRecipientIcBsAccounts] = React.useState<SelectProps.Option[]>(
    createLabelValueList(currentRegistrationRecord.recipientIcBsAccounts ?? []),
  );
  const [providerPlAccounts, setProviderPlAccounts] = React.useState<SelectProps.Option[]>(
    createLabelValueList(currentRegistrationRecord.providerPlAccounts ?? []),
  );
  const [recipientPlAccounts, setRecipientPlAccounts] = React.useState<SelectProps.Option[]>(
    createLabelValueList(currentRegistrationRecord.recipientPlAccounts ?? []),
  );

  async function fetchCategorySubcategoriesData() {
    // If data is already loaded from React context, just return.
    if (categoryNameToIdMapping.size != 0 && categorySubcategoriesMapping.size != 0) {
      return;
    }
    getCategoryDataFromMDM(
      services.mdmDataService,
      setCategoryNameToIdMapping,
      setCategorySubcategoriesMapping,
      setLoadingMDMData,
      setMDMResponseErrorMessage,
    );
  }

  function getCurrencyCodes() {
    // If currency codes are already loaded from React context, just return.
    if (currencyCodes.length != 0) {
      return;
    }
    fetchCurrencyCodes(
      services.currencyCodesService,
      setCurrencyCodes,
      setLoadingCurrencyCodes,
      setFetchCurrencyCodesErrorMessage,
    );
  }

  const { framesAccounts, loadingFramesAccounts, fetchFramesAccountsErrorMessage, getFramesAccounts } =
    useFramesAccounts(services);

  function onTaxProductCategoryNameChange(taxProductCategoryName: string) {
    setTaxProductCategoryName(taxProductCategoryName);
    setTaxProductCategoryId(categoryNameToIdMapping.get(taxProductCategoryName) ?? '');
    setTaxProductName('');
    setTaxProductId('');
    if (categorySubcategoriesMapping.has(taxProductCategoryName)) {
      setProductNameToIdMapping(
        new Map(
          categorySubcategoriesMapping
            .get(taxProductCategoryName)!
            .map((taxProductSubcategory) => [taxProductSubcategory.taxProductName, taxProductSubcategory.taxProductId]),
        ),
      );
    } else {
      setProductNameToIdMapping(new Map<string, string>());
    }
  }

  function onTaxProductNameChange(taxProductName: string) {
    setTaxProductName(taxProductName);
    setTaxProductId(productNameToIdMapping.get(taxProductName) ?? '');
  }

  async function updateRegistrationRecord() {
    const registrationRecordToUpdate = getRegistrationRecordToUpdate(CONSTANTS.APPROVAL_WORKFLOW_STAGES.DRAFT);

    if (areRecordsEqual(currentRegistrationRecord, registrationRecordToUpdate)) {
      services.messageService.showErrorAutoDismissBanner(CONSTANTS.EDIT_PAGE_NO_CHANGE_ERROR_MESSAGE);
      return;
    }

    setIsLoading(true);

    if (
      currentRegistrationRecord.approvalWorkflowStage != CONSTANTS.APPROVAL_WORKFLOW_STAGES.DRAFT &&
      currentRegistrationRecord.approvalWorkflowStage != CONSTANTS.APPROVAL_WORKFLOW_STAGES.IN_TAX_APPROVAL
    ) {
      currentRegistrationRecord.approvalWorkflowStage = CONSTANTS.APPROVAL_WORKFLOW_STAGES.AMENDED;
      await services.registrationDataService.updateRegistrationRecord(currentRegistrationRecord);
    }

    const { updatedRegistrationRecord: newRegistationRecord, error: newRecordError } =
      await services.registrationDataService.updateRegistrationRecord(registrationRecordToUpdate);

    setIsLoading(false);

    if (newRecordError) {
      services.messageService.showErrorAutoDismissBanner(newRecordError);
      return;
    }

    history.push({
      pathname: `/show/${newRegistationRecord?.registrationNumber?.replace('.', '*')}`,
      state: newRegistationRecord,
    });
  }

  function getRegistrationRecordToUpdate(approvalWorkflowStage: string) {
    const registrationRecordToUpdate: RegistrationRecord = {
      registrationNumber: currentRegistrationRecord.registrationNumber,
      baseRegistrationNumber: currentRegistrationRecord.baseRegistrationNumber,
      approvalWorkflowStage: approvalWorkflowStage,
      atpCalculationFlag: JSON.parse(atpCalculationFlag),
      providerCompanyCode: currentRegistrationRecord.providerCompanyCode,
      providerEntityName: currentRegistrationRecord.providerEntityName,
      providerJurisdiction: currentRegistrationRecord.providerJurisdiction,
      recipientCompanyCode: currentRegistrationRecord.recipientCompanyCode,
      recipientEntityName: currentRegistrationRecord.recipientEntityName,
      recipientJurisdiction: currentRegistrationRecord.recipientJurisdiction,
      providerTaxRegConsideration: JSON.parse(providerTaxRegConsideration),
      recipientTaxRegConsideration: JSON.parse(recipientTaxRegConsideration),
      exportOfServices: JSON.parse(exportOfServices),
      lastUpdatedByAccountingUser:
        isAccountingUser || isSuperUser ? userAlias : currentRegistrationRecord.lastUpdatedByAccountingUser,
      lastUpdatedByTaxUser:
        isIndirectTaxUser || isSuperUser ? userAlias : currentRegistrationRecord.lastUpdatedByTaxUser,
      createdBy: currentRegistrationRecord.createdBy,
      paymentTerms: paymentTerms ?? currentRegistrationRecord.paymentTerms,
      currency: currency ?? currentRegistrationRecord.currency,
      effectiveFromDate: effectiveFromDate ?? currentRegistrationRecord.effectiveFromDate,
      effectiveToDate: effectiveToDate ?? currentRegistrationRecord.effectiveToDate,
      workbookName: workbookName ?? currentRegistrationRecord.workbookName,
      beat: beat,
      description: description ?? currentRegistrationRecord.description,
      comments: comments ?? currentRegistrationRecord.comments,
      taxProductName: taxProductName ?? currentRegistrationRecord.taxProductName,
      taxProductId: taxProductId ?? currentRegistrationRecord.taxProductId,
      taxProductCategoryName: taxProductCategoryName ?? currentRegistrationRecord.taxProductCategoryName,
      taxProductCategoryId: taxProductCategoryId ?? currentRegistrationRecord.taxProductCategoryId,
      accountingPoc: accountingPoc ?? currentRegistrationRecord.accountingPoc,
      providerIcBsAccounts: convertOptionsToStrings(providerIcBsAccounts),
      recipientIcBsAccounts: convertOptionsToStrings(recipientIcBsAccounts),
      providerPlAccounts: convertOptionsToStrings(providerPlAccounts),
      recipientPlAccounts: convertOptionsToStrings(recipientPlAccounts),
    };

    return registrationRecordToUpdate;
  }

  return (
    <div>
      {loading ? (
        <StatusIndicator type="loading"> Loading page </StatusIndicator>
      ) : (
        <Form
          actions={
            <Button
              variant="primary"
              data-testid="save-registration-record-button"
              disabled={
                currentRegistrationRecord.recipientCompanyCode.trim().length == 0 ||
                currentRegistrationRecord.providerCompanyCode.trim().length == 0 ||
                paymentTerms.trim().length == 0 ||
                currency.trim().length == 0 ||
                effectiveFromDate.trim().length == 0 ||
                description.trim().length == 0 ||
                providerIcBsAccounts.length == 0 ||
                providerPlAccounts.length == 0 ||
                recipientIcBsAccounts.length == 0 ||
                recipientPlAccounts.length == 0 ||
                accountingPoc.trim().length == 0
              }
              onClick={updateRegistrationRecord}
            >
              Save
            </Button>
          }
          header={<Header variant="h1">Update Registration Record</Header>}
        >
          <div className="edit-registration-record">
            <ExpandableSection headerText="Registration Details" variant="container" defaultExpanded>
              <ColumnLayout columns={2}>
                <FormField label="ATP Calculation" info={customHelpPanel(CONSTANTS.FIELDS_INFO.ATP_CALCULATION)}>
                  <RadioGroup
                    onChange={({ detail }) => setATPCalculationFlag(detail.value)}
                    value={atpCalculationFlag}
                    items={[
                      { value: 'true', label: 'Yes' },
                      { value: 'false', label: 'No' },
                    ]}
                  />
                </FormField>
                <FormField
                  label="Entered Currency*"
                  info={customHelpPanel(CONSTANTS.FIELDS_INFO.CURRENCY)}
                  constraintText={currency.trim().length == 0 ? 'required' : ''}
                >
                  <Select
                    data-class="input-text"
                    data-testid="currency-input"
                    selectedOption={currency ? { label: currency, value: currency } : null}
                    options={createLabelValueList(currencyCodes)}
                    onFocus={() => getCurrencyCodes()}
                    loadingText="Loading currency codes"
                    statusType={
                      loadingCurrencyCodes ? 'loading' : fetchCurrencyCodesErrorMessage ? 'error' : 'finished'
                    }
                    errorText={fetchCurrencyCodesErrorMessage}
                    placeholder="Select a currency"
                    onChange={({ detail }) => {
                      setCurrency(detail.selectedOption.value ?? '');
                    }}
                    filteringType="auto"
                    empty="No currencies found"
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField
                  label="Provider Company Code*"
                  info={customHelpPanel(CONSTANTS.FIELDS_INFO.PROVIDER_COMPANY_CODE)}
                >
                  <Input
                    data-class="input-text"
                    data-testid="provider-company-code-input"
                    value={currentRegistrationRecord.providerCompanyCode}
                    disabled
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField
                  label="Recipient Company Code*"
                  info={customHelpPanel(CONSTANTS.FIELDS_INFO.RECIPIENT_COMPANY_CODE)}
                >
                  <Input
                    data-class="input-text"
                    data-testid="recipient-company-code-input"
                    value={currentRegistrationRecord.recipientCompanyCode}
                    disabled
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField label="Provider Entity Name">
                  <Input
                    data-class="input-text"
                    data-testid="provider-entity-name-input"
                    value={providerEntityName}
                    onChange={({ detail }) => setProviderEntityName(detail.value)}
                    disabled
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField label="Recipient Entity Name">
                  <Input
                    data-class="input-text"
                    data-testid="recipient-entity-name-input"
                    value={recipientEntityName}
                    onChange={({ detail }) => setRecipientEntityName(detail.value)}
                    disabled
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField label="Provider Jurisdiction">
                  <Input
                    data-class="input-text"
                    data-testid="provider-jurisdiction-input"
                    value={providerJurisdiction}
                    onChange={({ detail }) => setProviderJurisdiction(detail.value)}
                    disabled
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField label="Recipient Jurisdiction">
                  <Input
                    data-class="input-text"
                    data-testid="recipient-jurisdiction-input"
                    value={recipientJurisdiction}
                    onChange={({ detail }) => setRecipientJurisdiction(detail.value)}
                    disabled
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField
                  label="Effective Start Date*"
                  info={customHelpPanel(CONSTANTS.FIELDS_INFO.EFFECTIVE_FROM_DATE)}
                  constraintText="Use YYYY/MM/DD format."
                >
                  <DatePicker
                    data-testid="effective-from-date-input"
                    value={effectiveFromDate}
                    nextMonthAriaLabel="Next month"
                    placeholder="YYYY/MM/DD"
                    previousMonthAriaLabel="Previous month"
                    todayAriaLabel="Today"
                    onChange={({ detail }) => setEffectiveFromDate(detail.value)}
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField
                  label="Effective End Date"
                  info={customHelpPanel(CONSTANTS.FIELDS_INFO.EFFECTIVE_TO_DATE)}
                  constraintText="Use YYYY/MM/DD format."
                >
                  <DatePicker
                    data-testid="effective-to-date-input"
                    value={effectiveToDate}
                    nextMonthAriaLabel="Next month"
                    placeholder="YYYY/MM/DD"
                    previousMonthAriaLabel="Previous month"
                    todayAriaLabel="Today"
                    onChange={({ detail }) => setEffectiveToDate(detail.value)}
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField label="Workbook Name" info={customHelpPanel(CONSTANTS.FIELDS_INFO.WORKBOOK_NAME)}>
                  <Input
                    data-class="input-text"
                    data-testid="workbook-name-input"
                    value={workbookName}
                    onChange={({ detail }) => setWorkbookName(detail.value)}
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField
                  label="Payment Terms*"
                  info={customHelpPanel(CONSTANTS.FIELDS_INFO.PAYMENT_TERMS)}
                  constraintText={paymentTerms.trim().length == 0 ? 'required' : ''}
                >
                  <Select
                    data-class="input-text"
                    data-testid="payment-terms-input"
                    selectedOption={
                      paymentTerms
                        ? { value: paymentTerms, label: paymentTerms }
                        : createLabelValueObject(CONSTANTS.PAYMENT_TERMS[0])
                    }
                    options={createLabelValueList(CONSTANTS.PAYMENT_TERMS)}
                    onChange={({ detail }) => setPaymentTerms(detail.selectedOption.value || '')}
                    placeholder="Select payment terms"
                    filteringType="auto"
                    empty="No payment terms found"
                    disabled={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField label="BEAT Indicator" info={customHelpPanel(CONSTANTS.FIELDS_INFO.BEAT)}>
                  <Input data-class="input-text" value={beat === 'INBD' ? 'Yes' : 'No'} disabled />
                </FormField>
                <FormField
                  label="Description of Transaction*"
                  info={customHelpPanel(CONSTANTS.FIELDS_INFO.DESCRIPTION)}
                >
                  <Textarea
                    data-class="input-text"
                    data-testid="description-input"
                    value={description}
                    onChange={({ detail }) => setDescription(detail.value)}
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField label="Comments" info={customHelpPanel(CONSTANTS.FIELDS_INFO.COMMENTS)}>
                  <Textarea
                    data-class="input-text"
                    data-testid="comments-input"
                    value={comments}
                    onChange={({ detail }) => setComments(detail.value)}
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField
                  label="Provider IC BS Accounts*"
                  info={customHelpPanel(CONSTANTS.FIELDS_INFO.PROVIDER_GL_ACCOUNTS)}
                >
                  <Multiselect
                    data-class="input-text"
                    data-testid="provider-ic-bs-accounts-input"
                    statusType={
                      loadingFramesAccounts ? 'loading' : fetchFramesAccountsErrorMessage ? 'error' : 'finished'
                    }
                    selectedOptions={providerIcBsAccounts}
                    onChange={({ detail }) =>
                      setProviderIcBsAccounts([...detail.selectedOptions] as SelectProps.Option[])
                    }
                    onLoadItems={({ detail }) => {
                      getFramesAccounts(detail.filteringText);
                    }}
                    onFocus={() => getFramesAccounts()}
                    options={createLabelValueList(framesAccounts)}
                    loadingText="Loading IC BS Accounts"
                    errorText={fetchFramesAccountsErrorMessage}
                    placeholder="Choose options"
                    filteringType="manual"
                    filteringPlaceholder={'Enter at least 2 characters to see options'}
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField
                  label="Recipient IC BS Accounts*"
                  info={customHelpPanel(CONSTANTS.FIELDS_INFO.RECIPIENT_GL_ACCOUNTS)}
                >
                  <Multiselect
                    data-class="input-text"
                    data-testid="recipient-ic-bs-accounts-input"
                    selectedOptions={recipientIcBsAccounts}
                    statusType={
                      loadingFramesAccounts ? 'loading' : fetchFramesAccountsErrorMessage ? 'error' : 'finished'
                    }
                    onChange={({ detail }) =>
                      setRecipientIcBsAccounts([...detail.selectedOptions] as SelectProps.Option[])
                    }
                    onLoadItems={({ detail }) => {
                      getFramesAccounts(detail.filteringText);
                    }}
                    onFocus={() => getFramesAccounts()}
                    options={createLabelValueList(framesAccounts)}
                    loadingText="Loading FRAMES Accounts"
                    errorText={fetchFramesAccountsErrorMessage}
                    placeholder="Choose options"
                    filteringType="manual"
                    filteringPlaceholder={'Enter at least 2 characters to see options'}
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField label="Provider P&L Accounts*">
                  <Multiselect
                    data-class="input-text"
                    data-testid="provider-pl-accounts-input"
                    selectedOptions={providerPlAccounts}
                    statusType={
                      loadingFramesAccounts ? 'loading' : fetchFramesAccountsErrorMessage ? 'error' : 'finished'
                    }
                    onChange={({ detail }) =>
                      setProviderPlAccounts([...detail.selectedOptions] as SelectProps.Option[])
                    }
                    onLoadItems={({ detail }) => {
                      getFramesAccounts(detail.filteringText);
                    }}
                    onFocus={() => getFramesAccounts()}
                    options={createLabelValueList(framesAccounts)}
                    loadingText="Loading FRAMES Accounts"
                    errorText={fetchFramesAccountsErrorMessage}
                    placeholder="Choose options"
                    filteringType="manual"
                    filteringPlaceholder={'Enter at least 2 characters to see options'}
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField label="Recipient P&L Accounts*">
                  <Multiselect
                    data-class="input-text"
                    data-testid="recipient-pl-accounts-input"
                    selectedOptions={recipientPlAccounts}
                    statusType={
                      loadingFramesAccounts ? 'loading' : fetchFramesAccountsErrorMessage ? 'error' : 'finished'
                    }
                    onChange={({ detail }) =>
                      setRecipientPlAccounts([...detail.selectedOptions] as SelectProps.Option[])
                    }
                    onLoadItems={({ detail }) => {
                      getFramesAccounts(detail.filteringText);
                    }}
                    onFocus={() => getFramesAccounts()}
                    options={createLabelValueList(framesAccounts)}
                    loadingText="Loading FRAMES Accounts"
                    errorText={fetchFramesAccountsErrorMessage}
                    placeholder="Choose options"
                    filteringType="manual"
                    filteringPlaceholder={'Enter at least 2 characters to see options'}
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
                <FormField label="Accounting Point of Contact Alias*">
                  <Input
                    data-class="input-text"
                    data-testid="accounting-poc-alias-input"
                    value={accountingPoc}
                    onChange={({ detail }) => setAccountingPoc(detail.value)}
                    readOnly={!isAccountingUser && !isSuperUser}
                  />
                </FormField>
              </ColumnLayout>
            </ExpandableSection>
            <ExpandableSection headerText="Registration Data Contributors" variant="container" defaultExpanded>
              <ColumnLayout columns={2}>
                <FormField label="Created By">
                  <Input data-class="input-text" value={currentRegistrationRecord.createdBy} disabled />
                </FormField>
                <FormField label="Created Date Time">
                  <Input
                    data-class="input-text"
                    value={formatDate(new Date(currentRegistrationRecord.createDateTime!))}
                    disabled
                  />
                </FormField>
              </ColumnLayout>
            </ExpandableSection>
            <ExpandableSection headerText="Tax Product Details" variant="container" defaultExpanded>
              <ColumnLayout columns={2}>
                <FormField label="Provider Tax Reg Consideration">
                  <RadioGroup
                    onChange={({ detail }) => setProviderTaxRegConsideration(detail.value)}
                    value={providerTaxRegConsideration}
                    items={[
                      { value: 'true', label: 'True' },
                      { value: 'false', label: 'False' },
                    ]}
                    readOnly={!isIndirectTaxUser && !isSuperUser}
                  />
                </FormField>
                <FormField label="Recipient Tax Reg Consideration">
                  <RadioGroup
                    onChange={({ detail }) => setRecipientTaxRegConsideration(detail.value)}
                    value={recipientTaxRegConsideration}
                    items={[
                      { value: 'true', label: 'True' },
                      { value: 'false', label: 'False' },
                    ]}
                    readOnly={!isIndirectTaxUser && !isSuperUser}
                  />
                </FormField>
                <FormField label="Tax Product Category Name*">
                  <Select
                    data-class="input-text"
                    data-testid="tax-product-category-name-input"
                    selectedOption={
                      taxProductCategoryName ? { label: taxProductCategoryName, value: taxProductCategoryName } : null
                    }
                    options={getTaxProductCategoryNames(categoryNameToIdMapping)}
                    onFocus={() => fetchCategorySubcategoriesData()}
                    loadingText="Loading tax product category names"
                    statusType={loadingMDMData ? 'loading' : mdmResponseErrorMessage ? 'error' : 'finished'}
                    errorText={mdmResponseErrorMessage}
                    onChange={({ detail }) => onTaxProductCategoryNameChange(detail.selectedOption?.value ?? '')}
                    filteringType="auto"
                    empty="No tax product categories found"
                    readOnly={!isIndirectTaxUser && !isSuperUser}
                  />
                </FormField>
                <FormField label="Tax Product Category Id">
                  <Input
                    data-class="input-text"
                    data-testid="tax-product-category-id-input"
                    value={taxProductCategoryId}
                    onChange={({ detail }) => setTaxProductCategoryId(detail.value)}
                    disabled
                  />
                </FormField>
                <FormField label="Tax Product Sub Category Name*">
                  <Select
                    data-class="input-text"
                    data-testid="tax-product-name-input"
                    selectedOption={taxProductName ? { label: taxProductName, value: taxProductName } : null}
                    options={getTaxProductNames(productNameToIdMapping)}
                    onChange={({ detail }) => onTaxProductNameChange(detail.selectedOption?.value ?? '')}
                    filteringType="auto"
                    empty="No tax products found"
                    readOnly={!isIndirectTaxUser && !isSuperUser}
                  />
                </FormField>
                <FormField label="Tax Product Sub Category Id">
                  <Input
                    data-class="input-text"
                    data-testid="tax-product-id-input"
                    value={taxProductId}
                    onChange={({ detail }) => setTaxProductId(detail.value)}
                    disabled
                  />
                </FormField>
                <FormField label="Export of Services" info={customHelpPanel(CONSTANTS.FIELDS_INFO.EXPORT_OF_SERVICES)}>
                  <RadioGroup
                    onChange={({ detail }) => setExportOfServices(detail.value)}
                    value={exportOfServices}
                    items={[
                      { value: 'true', label: 'True' },
                      { value: 'false', label: 'False' },
                    ]}
                    readOnly={!isIndirectTaxUser && !isSuperUser}
                  />
                </FormField>
              </ColumnLayout>
            </ExpandableSection>
          </div>
        </Form>
      )}
    </div>
  );
}
