import React from 'react';
import { nanoid } from 'nanoid';
import ICRSAction from 'src/models/ICRSAction';

export default class MessageService {
  bannerDispatcher?: React.Dispatch<ICRSAction>;
  addBannerMessageAction?: ICRSAction;
  clearBannerMessageAction?: ICRSAction;

  /**
   * Sets up the banner dispatcher and actions for adding and clearing banner messages.
   * @param dispatcher - The dispatcher for banner actions.
   * @param addMessageAction - The action to add a banner message.
   * @param clearMessageAction - The action to clear a banner message.
   */
  public setupForBanner(
    dispatcher: React.Dispatch<ICRSAction>,
    addMessageAction: ICRSAction,
    clearMessageAction: ICRSAction,
  ) {
    this.addBannerMessageAction = addMessageAction;
    this.clearBannerMessageAction = clearMessageAction;
    this.bannerDispatcher = dispatcher;
  }

  /**
   * Displays a banner message.
   * @param message - The content of the banner message.
   * @param type - The type of the banner message ('success', 'warning', 'info', 'error').
   * @returns The ID of the banner message.
   */
  public showBannerMessage(message: string, type: 'success' | 'warning' | 'info' | 'error') {
    if (this.bannerDispatcher == null || this.addBannerMessageAction == null || this.clearBannerMessageAction == null) {
      console.error(
        'Dispatcher and addMessageAction has not been set. Invoke setupForBanner method before invoking show method.',
      );
      return;
    }
    const id = nanoid();
    const $bannerDispatcher = this.bannerDispatcher;
    const $clearAction = this.clearBannerMessageAction;
    const dismissAction = () => $bannerDispatcher($clearAction.withPayload(id));
    this.bannerDispatcher(
      this.addBannerMessageAction.withPayload({
        id: id,
        content: message,
        type: type,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: dismissAction,
      }),
    );
    return id;
  }

  /**
   * Allows showing an error message in top banner
   * @param message The message
   */
  public showErrorBanner(message: string) {
    return this.showBannerMessage(message, 'error');
  }

  /**
   * Allows showing an error banner message for a period of time
   * @param message The message
   * @param duration The duration in milliseconds (default is 3000)
   */
  public showErrorAutoDismissBanner(message: string, duration: number = 3000) {
    const messageId = this.showErrorBanner(message);
    setTimeout(
      (dispatch: React.Dispatch<ICRSAction>, action: ICRSAction) => {
        dispatch(action.withPayload(messageId));
      },
      duration,
      this.bannerDispatcher,
      this.clearBannerMessageAction,
    );
  }
}
