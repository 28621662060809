import { UserRole } from '../track-bulk-registration-request/definitions';

/**
 * This function will be used to get the user role based on the user type
 * for the Search Registeration page Super User will act as an taxUser
 * @param isIndirectTaxUser Is a indirect tax user
 * @param isDirectTaxUser Is a direct tax user
 * @param isAccountingUser Is an accounting user
 * @param isSuperUser Is a super user
 * @returns Role of the User
 */
export function getUserRole(
  isIndirectTaxUser: boolean,
  isDirectTaxUser: boolean,
  isAccountingUser: boolean,
  isSuperUser: boolean,
) {
  if (isIndirectTaxUser) {
    return UserRole.TAX_USER;
  } else if (isDirectTaxUser) {
    return UserRole.TAX_USER;
  } else if (isAccountingUser) {
    return UserRole.ACCOUNTING_USER;
  } else if (isSuperUser) {
    return UserRole.TAX_USER;
  }
}
