const CONSTANTS = Object.freeze({
  GENERIC_ERROR_MESSAGE: 'There was a problem with your request. Please try again later or contact Technical support.',
  EDIT_PAGE_NO_CHANGE_ERROR_MESSAGE: 'No changes are made to the existing data.',
  ENVIRONMENT_VARIABLES: {
    ICRS_UI_STAGE: '{{ICRS_UI_STAGE}}',
    AWS_REGION: '{{AWS_REGION}}',
    IDENTITY_POOL_ID: '{{IDENTITY_POOL_ID}}',
    USER_POOL_ID: '{{USER_POOL_ID}}',
    USER_POOL_APP_CLIENT_ID: '{{USER_POOL_APP_CLIENT_ID}}',
    USER_POOL_SUB_DOMAIN: '{{USER_POOL_SUB_DOMAIN}}',
    REDIRECT_URI_SIGNIN: '{{REDIRECT_URI_SIGNIN}}',
    REDIRECT_URI_SIGNOUT: '{{REDIRECT_URI_SIGNOUT}}',
    WEBSOCKET_API_URL: '{{WEBSOCKET_API_URL}}',
    ICRS_API_GATEWAY_ARN: '{{ICRS_API_GATEWAY_ARN}}',
    ICRS_UPLOADS_BUCKET: '{{ICRS_UPLOADS_BUCKET}}',
    APPROVALS_URL_PREFIX: '{{APPROVALS_URL_PREFIX}}',
  },
  WEBSOCKET_ROUTES: {
    PROCESS_BULK_REGISTRATION: 'processBulkRegistrations',
    PROCESS_BULK_APPROVALS: 'processBulkApprovals',
  },
  ACCESS_ROLES: {
    ADMIN: 'admin',
    DIRECT_TAX_USER: 'directTaxUser',
    INDIRECT_TAX_USER: 'indirectTaxUser',
    ACCOUNTING_USER: 'accountingUser',
    VIEWER: 'viewer',
  },
  COGNITO_ROLE_ATTRIBUTE: 'custom:Role',
  GET_REGISTRATION_RECORDS_COLUMNS: {
    REGISTRATION_NUMBER: 'registrationNumber',
    APPROVAL_WORKFLOW_STAGE: 'approvalWorkflowStage',
    ATP_CALCULATION_FLAG: 'atpCalculationFlag',
    TAX_PRODUCT_CATEGORY_NAME: 'taxProductCategoryName',
    TAX_PRODUCT_NAME: 'taxProductName',
    LAST_UPDATED_DATE_TIME: 'lastUpdatedDateTime',
    PAYMENT_TERMS: 'paymentTerms',
    APPROVAL_LINK: 'approvalLink',
  },
  CREATE_RECORD_PAGE_ERROR_DISPLAY_TIME: 8000,
  FIELDS_INFO: {
    EXPORT_OF_SERVICES: 'Are the Export of Services Conditions Met',
    COMMENTS: 'Any additional details about the transaction can be explained here',
    PROVIDER_COMPANY_CODE: 'Please select company code of the entity providing goods or services',
    RECIPIENT_COMPANY_CODE: 'Please select company code of the entity receiving goods or services',
    BEAT: 'Base Erosion and Anti-abuse Tax (BEAT) payment eligible',
    WORKBOOK_NAME: 'Excel file name if any associated with it',
    PAYMENT_TERMS: 'Payment due timeframe after the invoice is issued',
    ATP_CALCULATION:
      'If transaction has to be booked into Amazon Transfer Pricing (ATP) tool, then select "Yes" if not, select "No"',
    CURRENCY: 'Currency in which the transaction is booked',
    EFFECTIVE_FROM_DATE: 'Date from which transaction will be started to book entry',
    EFFECTIVE_TO_DATE: 'Date on which the transaction has to be stopped booking an entry, if applicable',
    DESCRIPTION:
      'This field is used to update the nature of transaction and any other details that can help in explaining why the transaction is booked',
    PROVIDER_GL_ACCOUNTS: 'Choose all P&L Accounts and IC BS Accounts to be used in the accounting entry',
    RECIPIENT_GL_ACCOUNTS: 'Choose all P&L Accounts and IC BS Accounts to be used in the accounting entry',
  },
  APPROVAL_WORKFLOW_STAGES: {
    DRAFT: 'Draft',
    IN_TAX_APPROVAL: 'In Tax Approval',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    AMENDED: 'Amended',
    TERMINATED: 'Terminated',
    TAX_APPROVAL_REJECTED: 'Tax Approval Rejected',
  },
  PAYMENT_TERMS: ['Net 90', 'Net 60', 'Net 30'],
});

export default CONSTANTS;
