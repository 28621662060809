import { useState } from 'react';
import AWS from 'aws-sdk';

export const useUploadFileToS3 = (s3Bucket: string, s3Key: string, file: File, contentType: string) => {
  const [isFileUploadingToS3, setIsS3FileUploadingToS3] = useState(false);
  const [s3FileUploadProgress, setS3FileUploadProgress] = useState(0);
  const [s3UploadError, setS3UploadError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const s3 = new AWS.S3();

  const uploadFile = async () => {
    const params = {
      Bucket: s3Bucket,
      Key: s3Key,
      Body: file,
      ContentType: contentType,
    };

    try {
      setIsS3FileUploadingToS3(true);
      setS3UploadError(null);
      setS3FileUploadProgress(0);
      setSuccess(false);

      await s3
        .upload(params)
        .on('httpUploadProgress', (event) => {
          if (event.total) {
            const percentage = Math.round((event.loaded / event.total) * 100);
            setS3FileUploadProgress(percentage);
          }
        })
        .promise();

      setIsS3FileUploadingToS3(false);
      setSuccess(true);
    } catch (err: any) {
      setIsS3FileUploadingToS3(false);
      setS3UploadError(err.message || 'Upload failed');
    }
  };

  return { isFileUploadingToS3, s3FileUploadProgress, success, s3UploadError, uploadFile };
};
