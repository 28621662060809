import React, { useState, useCallback } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';

export const useDownloadBulkUploadTemplate = (services: ServiceCollection) => {
  const [downloadingTemplate, setDownloadingTemplate] = React.useState(false);
  const [downloadBulkUploadTemplateErrorMessage, setDownloadBulkUploadTemplateErrorMessage] = useState('');

  const getDownloadBulkUploadTemplate = useCallback(async () => {
    setDownloadingTemplate(true);
    const { downloadURL, error } = await services.bulkUploadService.getTemplate();
    setDownloadingTemplate(false);
    if (error) {
      services.messageService.showErrorAutoDismissBanner(error);
      setDownloadBulkUploadTemplateErrorMessage(error);
      return;
    }
    window.open(downloadURL);
  }, []);

  return {
    downloadingTemplate,
    downloadBulkUploadTemplateErrorMessage,
    getDownloadBulkUploadTemplate,
  };
};
