import AWS from 'aws-sdk';
import { Signer } from 'aws-amplify';

interface WebSocketListener {
  key: string;
  handler: (data: any, args?: any[]) => void;
  extraArgs?: any[];
}

/**
 * Base class for interactions with a WebSocket server
 */
export default class WebSocketApi {
  private readonly internalConnectionCloseCode = 3456;
  public static ConnectionEvent = {
    ON_OPEN: 'ON_OPEN',
    ON_CLOSE: 'ON_CLOSE',
    ON_MESSAGE_SENT: 'ON_MESSAGE_SENT',
    ON_MESSAGE_RECEIVED: 'ON_MESSAGE_RECEIVED',
    ON_ERROR: 'ON_ERROR',
  };
  /**
   * The actual WebSocket client
   * https://developer.mozilla.org/en-US/docs/Web/API/WebSocket
   */
  private socket?: WebSocket;

  /**
   * Simple flag to allow reconnecting
   */
  private connected: boolean;

  /**
   * Flag to know when a connection attempt is ongoing to avoid multiple connection attempts
   */
  private isConnecting: boolean;

  /**
   * Array to store server message listeners
   */
  private listeners: WebSocketListener[];

  /**
   * Utility array to store messages that couldn't be sent because the socket was not connected
   */
  private pendingMessages: string[];

  constructor() {
    this.socket = undefined;
    this.listeners = [];
    this.pendingMessages = [];
    this.connected = false;
    this.isConnecting = false;
  }

  connect(url: string) {
    if (this.socket) {
      this.socket.close(this.internalConnectionCloseCode);
      return;
    }
    this.isConnecting = true;
    const credentials: any = (AWS.config.credentials as any)?.data?.Credentials || {};
    const accessInfo = {
      access_key: credentials.AccessKeyId,
      secret_key: credentials.SecretKey,
      session_token: credentials.SessionToken,
    };
    const signedUrl = Signer.signUrl(url, accessInfo);
    this.socket = new WebSocket(signedUrl);
    const self = this;

    this.socket.onopen = function () {
      self.connected = true;
      self.isConnecting = false;
      // Send any pending messages upon successful connection
      self.pendingMessages.forEach((m) => self._sendMessage(m));
      self.pendingMessages = [];
      self.listeners.forEach((l) => {
        if (l.key == WebSocketApi.ConnectionEvent.ON_OPEN) {
          // Notify listerners
          l.handler(new Date().toISOString(), l.extraArgs);
        }
      });
    };
    this.socket.onerror = function (ev: Event) {
      self.listeners.forEach((l) => {
        if (l.key === WebSocketApi.ConnectionEvent.ON_ERROR) {
          l.handler(ev, l.extraArgs);
          self.connected = false;
          self.isConnecting = false;
          self.socket = undefined;
        }
      });
    };

    // Attach onMessage listener
    this.socket.onmessage = function (e: MessageEvent) {
      self.listeners.forEach((l) => {
        if (l.key === '' || l.key === e.data.key || l.key == WebSocketApi.ConnectionEvent.ON_MESSAGE_RECEIVED) {
          // Notify listerners
          l.handler(e.data, l.extraArgs);
        }
      });
    };

    this.socket.onclose = function (ev: CloseEvent) {
      self.connected = false;
      self.isConnecting = false;
      if (ev.code == self.internalConnectionCloseCode) {
        //This event should be ignored
        return;
      }
      self.listeners.forEach((l) => {
        if (l.key == WebSocketApi.ConnectionEvent.ON_CLOSE) {
          // Notify listerners
          l.handler(new Date().toISOString(), l.extraArgs);
        }
      });
    };
  }

  /**
   * Checks if client is conencted
   * @returns True if connected
   */
  isConnected() {
    return this.connected;
  }

  /**
   * Makes sure the client is connected
   * @param url The Web Socket API URL
   */
  ensureConnected(url: string) {
    if (this.socket != null && (this.isConnecting || this.isConnected())) {
      return;
    }
    this.connect(url);
  }

  /**
   * Adds a listener associated to an event key
   * @param eventKey The server side event key to listen to
   * @param handler The function that get executed when the server send a message matching the eventKey
   * @param extraArgs Optional arguments to be passed when invoking the handler
   */
  addKeyListener(eventKey: string, handler: (data: any, extraArgs?: any[]) => void, extraArgs?: any[]) {
    this.listeners.push({ key: eventKey, handler, extraArgs });
  }

  /**
   * Adds a listener with no event key filter
   * @param handler The function that get executed when the server send a message
   * @param extraArgs Optional arguments to be passed when invoking the handler
   */
  addListener(handler: (data: any, extraArgs?: any[]) => void, extraArgs?: any[]) {
    this.listeners.push({ key: '', handler, extraArgs });
  }

  /**
   * Sends a message to the server, if the connection is not open it adds the message to a temporary list
   * @param action The server side action name for this message
   * @param message The message payload
   */
  sendMessage(action: string, message: any) {
    message.action = action;
    if (!this.connected || this.socket?.readyState === WebSocket.CONNECTING) {
      this.pendingMessages.push(message);
      console.log('Message could not be sent. Added as pending message: ', message);
      return;
    }
    this._sendMessage(message);
  }

  /**
   * Removes a listener from the list
   * @param eventKey The event key associated to the listener
   * @param handler The added function used to find the right listener
   */
  removeListener(eventKey: string, handler: (args: any) => void) {
    const foundIndex = this.listeners.findIndex((x) => x.key === eventKey && x.handler == handler);
    this.listeners.splice(foundIndex, 1);
  }

  /**
   * Called when the client explicitly want to disconnect from the server
   * Note: Listeners are cleared, so if re-connected no listener will be triggered
   */
  disconnect() {
    this.listeners = [];
    this.connected = false;
  }

  private _sendMessage(message: any) {
    this.socket?.send(JSON.stringify(message));
    this.listeners.forEach((l) => {
      if (l.key == WebSocketApi.ConnectionEvent.ON_MESSAGE_SENT) {
        // Notify listerners
        l.handler(message, l.extraArgs);
      }
    });
  }
}
