import React from 'react';
import { FILTERING_OPERATORS_FOR_STRING, RegistrationTableColumns } from './definitions';
import CONSTANTS from 'src/utils/constants';
import { formatDate } from 'src/utils/DateUtils';
import { Link } from '@amzn/awsui-components-react';

const convertCamelCaseToTitleCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

function getRegistrationRecordPageNavUrl(registrationNumber: string) {
  let encodedRegistrationNumber = registrationNumber.replace('.', '*');
  return `/show/${encodeURIComponent(encodedRegistrationNumber)}`;
}

const getCustomPropertiesForColumnDefinitions = (column: string) => {
  let cell;
  switch (column) {
    case CONSTANTS.GET_REGISTRATION_RECORDS_COLUMNS.REGISTRATION_NUMBER:
      cell = (e: any) => (
        <Link external href={getRegistrationRecordPageNavUrl(e[column])}>
          {' '}
          {e[column]}
        </Link>
      );
      break;
    case CONSTANTS.GET_REGISTRATION_RECORDS_COLUMNS.ATP_CALCULATION_FLAG:
      cell = (e: any) => String(e[column]);
      break;
    case CONSTANTS.GET_REGISTRATION_RECORDS_COLUMNS.LAST_UPDATED_DATE_TIME:
      cell = (e: any) => formatDate(new Date(e[column]!));
      break;
    default:
      cell = (e: any) => e[column] ?? '';
  }
  return {
    cell,
  };
};

export const prepareColumnDefinitions = () => {
  const columnDefinitions: any[] = [];

  RegistrationTableColumns.forEach((column: string) => {
    columnDefinitions.push({
      id: column,
      header: convertCamelCaseToTitleCase(column),
      sortingField: column,
      width: 300,
      ...getCustomPropertiesForColumnDefinitions(column),
    });
  });
  return columnDefinitions;
};

const getCustomPropertiesForColumnFiltering = (column: string) => {
  let properties = {
    groupValuesLabel: column,
    operators: FILTERING_OPERATORS_FOR_STRING,
  };
  return properties;
};

export const getRegistrationRecordsTableFilteringProperties = () => {
  const columnFilteringProperties: any[] = [];

  RegistrationTableColumns.forEach((column: string) => {
    columnFilteringProperties.push({
      propertyLabel: column,
      key: column,
      ...getCustomPropertiesForColumnFiltering(column),
    });
  });
  return columnFilteringProperties;
};
