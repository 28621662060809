import React, { useState, useCallback, useContext } from 'react';
import { GlobalAppContext } from 'src/components/App';
import ServiceCollection from 'src/services/ServiceCollection';
import { getCategoryDataFromMDM } from 'src/utils/MDMUtils';

export const useCategorySubcategoriesData = (services: ServiceCollection) => {
  const {
    categoryNameToIdMapping,
    setCategoryNameToIdMapping,
    categorySubcategoriesMapping,
    setCategorySubcategoriesMapping,
  } = useContext(GlobalAppContext);
  const [fetchMDMResponseErrorMessage, setMDMResponseErrorMessage] = useState('');
  const [loadingMDMData, setLoadingMDMData] = React.useState(false);

  const getCategorySubcategoriesData = useCallback(async () => {
    // If data is already loaded from React context, just return.
    if (categoryNameToIdMapping.size != 0 && categorySubcategoriesMapping.size != 0) {
      return;
    }
    getCategoryDataFromMDM(
      services.mdmDataService,
      setCategoryNameToIdMapping,
      setCategorySubcategoriesMapping,
      setLoadingMDMData,
      setMDMResponseErrorMessage,
    );
  }, [categoryNameToIdMapping, categorySubcategoriesMapping]);

  return {
    categoryNameToIdMapping,
    categorySubcategoriesMapping,
    fetchMDMResponseErrorMessage,
    loadingMDMData,
    getCategorySubcategoriesData,
  };
};
