import apiService from 'src/services/ApiCallService';
import ErrorUtils from 'src/utils/ErrorUtils';
import { ApprovalResponse } from 'src/models/ApprovalResponse';

export default class ApprovalService {
  async createApproval(registrationNumbers: string[], approvalRequester: string) {
    let approvalResponse: ApprovalResponse | null = null;
    let error: string | undefined = undefined;
    const payload = {
      registrationNumbers: registrationNumbers,
      approvalRequester,
    };
    try {
      const response = await apiService.createApproval(payload);
      approvalResponse = (await response.json()) as ApprovalResponse;
    } catch (ex) {
      error = ErrorUtils.getMessage(ex);
    }

    return {
      approvalResponse,
      error,
    };
  }
}
