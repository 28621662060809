import { GlobalState } from './GlobalState';
import ICRSAction from 'src/models/ICRSAction';

/**
 * List here the actions supported by this reducer
 */
export const GLOBAL_ACTIONS = Object.freeze({
  ADD_BANNER_MESSAGE: new ICRSAction('ADD_BANNER_MESSAGE'),
  REMOVE_BANNER_MESSAGE: new ICRSAction('REMOVE_BANNER_MESSAGE'),
  UPDATE_CURRENT_USER_ROLES: 'UPDATE_CURRENT_USER_ROLES',
});

/**
 * This function is responsible for updating the state based on action type
 * @param state The current global state
 * @param action The current dispatched action
 */
export function globalReducer(state: GlobalState, action: ICRSAction): GlobalState {
  switch (action.type) {
    case GLOBAL_ACTIONS.ADD_BANNER_MESSAGE.type: {
      const { bannerItems } = state;
      bannerItems.push(action.payload);
      return {
        ...state,
        bannerItems: [...bannerItems],
      };
    }
    case GLOBAL_ACTIONS.REMOVE_BANNER_MESSAGE.type: {
      return {
        ...state,
        bannerItems: [],
      };
      if (action.payload == null) {
        return {
          ...state,
          bannerItems: [],
        };
      }
      const { bannerItems } = state;
      return {
        ...state,
        bannerItems: [...bannerItems.filter((x: { id: any }) => x.id !== action.payload)],
      };
    }
    case GLOBAL_ACTIONS.UPDATE_CURRENT_USER_ROLES:
      return {
        ...state,
        currentUserRoles: action.payload,
      };
    default:
      return state;
  }
}
