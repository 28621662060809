import { useState, useCallback } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import { ApprovalResponse } from 'src/models/ApprovalResponse';

export const useCreateApproval = (services: ServiceCollection, registrationNumbers: string[], userAlias: string) => {
  const [isApprovalSubmittedLoading, setIsApprovalSubmittedLoading] = useState(false);
  const [approvalResponse, setApprovalResponse] = useState<ApprovalResponse | null>(null);
  const [approvalErrorMessage, setApprovalErrorMessage] = useState<string | null>(null);

  const submitForApproval = useCallback(async () => {
    if (registrationNumbers.length === 0) {
      return;
    }
    setIsApprovalSubmittedLoading(true);
    setApprovalErrorMessage(null);
    const { approvalResponse, error } = await services.approvalService.createApproval(registrationNumbers, userAlias);
    setIsApprovalSubmittedLoading(false);
    if (error) {
      setApprovalErrorMessage(error);
      return;
    }
    setApprovalResponse(approvalResponse);
  }, [registrationNumbers, userAlias]);

  const resetApprovalResponse = useCallback(() => {
    if (approvalErrorMessage == null) {
      window.location.reload();
    }
  }, []);

  return {
    isApprovalSubmittedLoading,
    approvalResponse,
    approvalErrorMessage,
    resetApprovalResponse,
    submitForApproval,
  };
};
