import { useState, useCallback } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import { RegistrationRecord } from 'src/models/data-registration/RegistrationRecord';

export const useReactivateRegistrationRecord = (
  services: ServiceCollection,
  registrationRecord: RegistrationRecord,
  requestingUser: string,
) => {
  const [isReactivating, setIsReactivating] = useState(false);
  const [reactivatedRecord, setReactivatedRecord] = useState<RegistrationRecord | null>(null);
  const [reactivationError, setReactivationError] = useState<string | null>(null);

  const submitReactivation = useCallback(async () => {
    setIsReactivating(true);
    const { reactivatedRecord, error } = await services.registrationDataService.reactivateRegistrationRecord(
      registrationRecord.registrationNumber!,
      requestingUser,
    );
    setIsReactivating(false);

    if (error) {
      setReactivationError(error);
      return;
    }
    if (reactivatedRecord) {
      setReactivatedRecord(reactivatedRecord);
    }
  }, [services, registrationRecord, requestingUser]);

  return {
    isReactivating,
    reactivatedRecord,
    reactivationError,
    submitReactivation,
  };
};
