import { useState, useCallback, useContext } from 'react';
import { GlobalAppContext } from 'src/components/App';
import ServiceCollection from 'src/services/ServiceCollection';
import { getCompanyCodesFromALE } from 'src/utils/ALEUtils';

export const useCompanyCodes = (services: ServiceCollection) => {
  const { companyCodes, setCompanyCodes } = useContext(GlobalAppContext);
  const [loadingCompanyCodes, setLoadingCompanyCodes] = useState(false);
  const [fetchCompanyCodesErrorMessage, setFetchCompanyCodesErrorMessage] = useState('');

  const getCompanyCodes = useCallback(async () => {
    // If company codes are already loaded from React context, just return.
    if (companyCodes.length != 0) {
      return;
    }
    getCompanyCodesFromALE(
      services.aleDataService,
      setCompanyCodes,
      setLoadingCompanyCodes,
      setFetchCompanyCodesErrorMessage,
    );
  }, [companyCodes]);

  return {
    companyCodes,
    loadingCompanyCodes,
    fetchCompanyCodesErrorMessage,
    getCompanyCodes,
  };
};
