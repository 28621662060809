import { Dispatch, SetStateAction } from 'react';
import ALEDataService from 'src/services/ALEDataService';

/**
 * Function to fetch company codes from the ALE service and store them in React context.
 *
 * @param aleDataService - service instance used to fetch company codes from ALE.
 * @param setCompanyCodes - React state setter to update the list of company codes in context.
 * @param setLoadingCompanyCodes - React state setter to toggle the loading state while fetching company codes.
 * @param setFetchCompanyCodesErrorMessage - React state setter to update error messages in case of a fetch failure.
 */
export const getCompanyCodesFromALE = async (
  aleDataService: ALEDataService,
  setCompanyCodes: Dispatch<SetStateAction<string[]>>,
  setLoadingCompanyCodes: Dispatch<SetStateAction<boolean>>,
  setFetchCompanyCodesErrorMessage: Dispatch<SetStateAction<string>>,
) => {
  setLoadingCompanyCodes(true);
  const { companyCodes, error } = await aleDataService.getCompanyCodes();

  if (error) {
    setFetchCompanyCodesErrorMessage(error);
    return;
  }

  // Load company codes into React context
  setCompanyCodes(companyCodes!);
  setLoadingCompanyCodes(false);
};
